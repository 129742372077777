import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { constants, getAuthItem, isUserAuthenticated } from '../../utils/authUtils';
import { differenceInMilliseconds } from 'date-fns';
import { RootState } from '../../redux/Store';
import { fetchAccessTokenUsingRefreshToken } from '../../redux/auth/AuthSlice';

// Gets new Access Token Before token expires
const GetNewAccessToken = () => {
  const dispatch = useDispatch();
  const { accessTokenStatus, userDetails } = useSelector((state: RootState) => ({
    accessTokenStatus: state.authData.accessTokenStatus,
    userDetails: state.authData.userDetails,
  }));

  const [timerId, setTimerId] = useState<any>();

  const isAuthenticated = isUserAuthenticated();

  const getTime = () => {
    const endDate = new Date(getAuthItem(constants.EXPIRY_TIME) || '');
    const currentDate = new Date();
    return differenceInMilliseconds(endDate, currentDate) - 60 * 1000;
  };

  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    if (isAuthenticated) {
      const time: number = getTime();
      if (time > 0) {
        const newTimerId = setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(fetchAccessTokenUsingRefreshToken());
        }, time);
        setTimerId(newTimerId);
      }
    }
  }, [accessTokenStatus, userDetails]);

  return <></>;
};

export default GetNewAccessToken;
