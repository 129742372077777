import React from 'react';
import { Link, MenuItem } from '@mui/material';
import { ILink } from '../../types';
import { NavLink, useLocation } from 'react-router-dom';
import { StyledNavSubMenu } from './NavStyles';

interface INavMenuLinkProps {
  link: ILink;
}

const NavMenuLink = ({ link }: INavMenuLinkProps) => {
  const activeClassName = 'link link-active';
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenSubmenu = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSubmenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        aria-controls={open ? 'nav-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenSubmenu}
        className={
          pathname?.includes(link?.link)
            ? activeClassName + ' link dropdown-link'
            : ' link dropdown-link'
        }
      >
        {link?.label}
      </Link>
      <StyledNavSubMenu
        id='nav-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseSubmenu}
        MenuListProps={{
          'aria-labelledby': 'nav-button',
        }}
      >
        {(link?.children || []).map((childLink, j) => {
          return (
            <NavLink
              className={({ isActive }) => (isActive ? activeClassName : 'link')}
              to={childLink.link}
              onClick={handleCloseSubmenu}
              key={j}
            >
              <MenuItem> {childLink?.label} </MenuItem>
            </NavLink>
          );
        })}
      </StyledNavSubMenu>
    </>
  );
};
export default NavMenuLink;
