import React from 'react';
import { Typography } from '@mui/material';

const ApplicationDown = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: 60 }}>
      <Typography variant='h1' color='primary'>
        Sorry, we&apos;re down for scheduled maintenance.
      </Typography>
    </div>
  );
};
export default ApplicationDown;
