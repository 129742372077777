import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../routes/Routes';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { OrderOrInvoiceTypes } from '../../../redux/overview/OverviewModels';
import { downloadPaidInvoice, downloadUnPaidInvoice } from '../../../redux/overview/OverviewSlice';
import LaunchIcon from '@mui/icons-material/Launch';
import { openLink } from '../../../utils/common';

const InvoiceOrOrderFooter = ({
  isPaid = true,
  number,
  type,
}: {
  isPaid?: boolean;
  number: string;
  type: OrderOrInvoiceTypes;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const isInvoice = type === OrderOrInvoiceTypes.Invoice;
  const [downloadInfo, setDownloadInfo] = useState({ status: 'idle', error: null });

  const handleDownload = () => {
    if (isPaid) {
      setDownloadInfo({ ...downloadInfo, status: 'loading', error: null });
      downloadPaidInvoice({ invoiceNumber: number })
        .then((result) => {
          setDownloadInfo({ ...downloadInfo, status: 'success' });
          openLink(result?.data?.data);
        })
        .catch((e) => {
          setDownloadInfo({ ...downloadInfo, status: 'failed', error: e.error });
        });
    } else {
      setDownloadInfo({ ...downloadInfo, status: 'loading', error: null });
      downloadUnPaidInvoice({ invoiceNumber: number })
        .then((result) => {
          setDownloadInfo({ ...downloadInfo, status: 'success' });
          openLink(result?.data?.data);
        })
        .catch((e) => {
          setDownloadInfo({ ...downloadInfo, status: 'failed', error: e.error });
        });
    }
  };

  const isLoading = downloadInfo.status === 'loading';

  return (
    <div>
      <Box
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent='space-between'
        alignItems={isMobile ? 'flex-start' : 'center'}
        mt={theme.spacing(12)}
        mb={theme.spacing(12)}
        gap={theme.spacing(8)}
      >
        <Box display='flex' gap={theme.spacing(8)}>
          {isInvoice && (
            <>
              <Button onClick={handleDownload}>
                {isLoading ? (
                  <>
                    <CircularProgress
                      sx={{
                        width: '20px !important',
                        height: '20px !important',
                        mr: 5,
                      }}
                    />
                  </>
                ) : (
                  <LaunchIcon />
                )}
                <Typography fontWeight={500} variant='h6' ml={theme.spacing(4)}>
                  View
                </Typography>
              </Button>
            </>
          )}
        </Box>
        <Typography variant='underline' color={grey[500]} component={Link} to={PATHS.HELP}>
          Need help?
        </Typography>
      </Box>
      {downloadInfo?.error && (
        <Box mb={10}>
          <Typography color='error'>{downloadInfo?.error}</Typography>
        </Box>
      )}
    </div>
  );
};

export default InvoiceOrOrderFooter;
