import React from 'react';
import { Container, useTheme } from '@mui/material';

interface IProps {
  children: React.ReactNode;
  className?: string;
  noSpacing?: boolean;
}

const PageContentContainer = ({ children, className = '', noSpacing }: IProps) => {
  const theme = useTheme();
  return (
    <>
      <Container
        className={className}
        sx={{
          minHeight: '50vh',
          maxWidth: 936,
          width: '100%',
          padding: noSpacing ? 0 : theme.spacing(10),
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default PageContentContainer;
