import React, { useState } from 'react';
import { Box, Card, InputAdornment, Switch, Typography, useTheme } from '@mui/material';
import {
  InvoiceAndOrdersViewType,
  ISelectedInvoiceOrOrderItem,
  PaymentFlowType,
} from '../../../redux/overview/OverviewModels';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { formatAmount, formatDate } from '../../../utils/common';
import SpectrumTextField from '../../../components/Spectrum/TextField/SpectrumTextField';

interface IInvoiceAndOrderCardProps {
  paymentFlowType: PaymentFlowType;
  viewType?: InvoiceAndOrdersViewType;
  hideHeader?: boolean;
  hideFooter?: boolean;
  selectedAdvertiserInvoiceOrOrder: ISelectedInvoiceOrOrderItem;
  children?: React.ReactNode;
  handleUpdate: (item: ISelectedInvoiceOrOrderItem) => void;
}

const InvoiceAndOrderCard = ({
  viewType,
  paymentFlowType,
  selectedAdvertiserInvoiceOrOrder,
  children,
  handleUpdate,
  hideHeader = false,
  hideFooter = false,
}: IInvoiceAndOrderCardProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const label = paymentFlowType === PaymentFlowType.Invoices ? 'invoices' : 'orders';
  const numberOfItemsToShow = 3;
  const remainingItems = selectedAdvertiserInvoiceOrOrder?.items?.length - numberOfItemsToShow;
  const [collapseStatus, setCollapseStatus] = useState(false);

  const handleCollapse = () => {
    setCollapseStatus(!collapseStatus);
  };

  const handlePayFull = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdate({ ...selectedAdvertiserInvoiceOrOrder, isCustomAmount: !event.target.checked });
  };

  const handleCustomAmountChange = (j: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ? Number(event.target.value) : '';
    const updatedItem = JSON.parse(JSON.stringify(selectedAdvertiserInvoiceOrOrder));
    const modItem = { ...selectedAdvertiserInvoiceOrOrder.items[j], customAmount: value };
    updatedItem.items.splice(j, 1, modItem);
    handleUpdate(updatedItem);
  };
  const handleCustomAmountBlur = (j: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
      ? Number(event.target.value)
      : selectedAdvertiserInvoiceOrOrder.items[j].amount;
    const updatedItem = JSON.parse(JSON.stringify(selectedAdvertiserInvoiceOrOrder));
    const modItem = { ...selectedAdvertiserInvoiceOrOrder.items[j], customAmount: value };
    updatedItem.items.splice(j, 1, modItem);
    handleUpdate(updatedItem);
  };

  const isPaymentAmount = viewType === InvoiceAndOrdersViewType.PAYMENT_AMOUNT;
  const isCustomAmount = selectedAdvertiserInvoiceOrOrder.isCustomAmount;
  const prefix = paymentFlowType === PaymentFlowType.Invoices ? 'Inv #' : 'Ord #';

  const total = (selectedAdvertiserInvoiceOrOrder.items || []).reduce((total, item) => {
    return isCustomAmount ? (item?.customAmount || 0) + total : item?.amount + total;
  }, 0);

  return (
    <Card className='item' sx={{ borderRadius: 0, border: `1px solid ${grey[400]}` }}>
      {!hideHeader && (
        <Box display='flex' justifyContent='space-between' mb={6}>
          <Typography variant={isPaymentAmount ? 'h3' : 'h6'}>
            {selectedAdvertiserInvoiceOrOrder?.advertiserOrAgencyName}
          </Typography>
          {isPaymentAmount && (
            <Box display='flex' alignItems='center'>
              <Typography variant='h6' mr={4}>
                Pay in full:
              </Typography>
              <Switch checked={!isCustomAmount} onChange={handlePayFull} />
            </Box>
          )}
        </Box>
      )}
      {(selectedAdvertiserInvoiceOrOrder.items || [])
        .slice(
          0,
          collapseStatus ? selectedAdvertiserInvoiceOrOrder.items.length : numberOfItemsToShow,
        )
        .map((item, j) => {
          return (
            <Box key={j} className='sub-item'>
              <Box className='item-details' mb={2}>
                <Typography variant='h6'>{prefix + ' ' + item?.number}</Typography>
                <>
                  {isPaymentAmount ? (
                    <Typography variant='body2'> {formatAmount(item?.amount)}</Typography>
                  ) : isCustomAmount ? (
                    <Typography variant='body2'>
                      {' '}
                      {formatAmount(item?.customAmount || 0)}
                    </Typography>
                  ) : (
                    <Typography variant='body2'> {formatAmount(item?.amount)}</Typography>
                  )}
                </>
              </Box>
              {!isPaymentAmount && isCustomAmount && (
                <Typography variant='subtitle2' color='grey' sx={{ fontSize: 12 }}>
                  Total Due {formatAmount(item?.amount)}
                </Typography>
              )}
              <Typography variant='subtitle2' color='grey' sx={{ fontSize: 12 }}>
                Due {formatDate(item?.dueDate)}
              </Typography>
              {isCustomAmount && isPaymentAmount && (
                <Box mt={4}>
                  <SpectrumTextField
                    type='number'
                    value={item?.customAmount}
                    placeholder='Enter Custom Amount'
                    size='small'
                    helperText='Minimum amount of $1 required'
                    onChange={handleCustomAmountChange(j)}
                    onBlur={handleCustomAmountBlur(j)}
                    autoFocus={!j}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                  ></SpectrumTextField>
                </Box>
              )}
            </Box>
          );
        })}
      {remainingItems > 0 && (
        <Typography
          className='more-items'
          variant='underline'
          color='primary'
          onClick={handleCollapse}
        >
          {collapseStatus ? `View less ${label}` : `+${remainingItems} more ${label}`}
        </Typography>
      )}
      {children}
      {!hideFooter && (
        <Box className='item-details total'>
          <Typography variant='h6'>{isPaymentAmount ? 'Payment Due' : 'Total'}</Typography>
          <Typography variant='h6'>{formatAmount(total)}</Typography>
        </Box>
      )}
    </Card>
  );
};
export default InvoiceAndOrderCard;
