import React from 'react';
import { Box, useTheme } from '@mui/material';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import ReviewRequestSteps from './ReviewRequestSteps';
import UnderReview from './UnderReview';

const ReviewRequest = ({ isUnderReview }: { isUnderReview: boolean }) => {
  const theme = useTheme();
  return (
    <PageContentContainer>
      <Box marginY={theme.spacing(40)}>
        {isUnderReview ? <UnderReview /> : <ReviewRequestSteps />}
      </Box>
    </PageContentContainer>
  );
};
export default ReviewRequest;
