import React, { useEffect } from 'react';
import { Alert, Box, Divider, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { groupByKey } from '../../../utils/common';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchInvoiceAndOrdersList,
  updateFilters,
} from '../../../redux/InvoicesAndOrders/InvoicesAndOrderSlice';
import { ComponentOverlayLoader } from '../../../components/Loaders/Loader';
import { IInvoiceAndOrderListItem } from '../../../redux/InvoicesAndOrders/InvoicesAndOrderModels';
import SpectrumPagination from '../../../components/Pagination/SpectrumPagination';
import InvoiceAndOrderListItem from './InvoiceAndOrderListItem';

const InvoiceAndOrdersList = () => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const { invoiceAndOrdersList, invoiceAndOrdersListStatus, invoiceAndOrdersListError, filters } =
    useSelector((state: RootState) => state.invoiceAndOrdersData);

  const data = invoiceAndOrdersList?.accountSharingDetail || [];

  const groupedByItems: { [x: string]: IInvoiceAndOrderListItem[] } = groupByKey(
    data,
    'advertiserNameStart',
  );

  useEffect(() => {
    dispatch(fetchInvoiceAndOrdersList(filters));
  }, [filters]);

  const handleChangeItemsPerPage = (items: number) => {
    dispatch(updateFilters({ ...filters, start: 0, length: items }));
  };
  const handlePageChange = (pageNo: number) => {
    dispatch(updateFilters({ ...filters, start: pageNo * filters.length }));
  };

  const isLoading = invoiceAndOrdersListStatus === 'loading';

  return (
    <div>
      {invoiceAndOrdersListError && (
        <Alert severity='error' variant='outlined'>
          <Typography variant='paragraph2' component='span' color='error'>
            {invoiceAndOrdersListError}
          </Typography>
        </Alert>
      )}
      {isLoading && <ComponentOverlayLoader />}
      {data?.length > 0 || isLoading ? (
        <>
          {Object.keys(groupedByItems).map((groupBy) => {
            return (
              <Box mt={2} mb={2} key={groupBy}>
                <Typography variant='h6' mb={2}>
                  {groupBy}
                </Typography>
                <Divider orientation='horizontal' sx={{ borderColor: black[500] }}></Divider>
                {(groupedByItems[groupBy] || []).map((item, index) => (
                  <InvoiceAndOrderListItem item={item} key={index} />
                ))}
              </Box>
            );
          })}
          {data?.length > 0 && (
            <table style={{ width: '100%' }}>
              <SpectrumPagination
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleChangeItemsPerPage}
                currentPage={filters.start / filters.length}
                itemsPerPage={filters?.length}
                totalItems={invoiceAndOrdersList?.totalRecords}
                labelRowsPerPage='Items Per Page'
              />
            </table>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 100,
          }}
        >
          <Typography variant='body2' color={grey[500]}>
            No Advertisers Found
          </Typography>
        </Box>
      )}
    </div>
  );
};
export default InvoiceAndOrdersList;
