import { ColorPartial } from '@mui/material/styles/createPalette';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import ScrollBox from '../../components/ScrollBox/ScrollBox';

export default function TermsAndCondition() {
  const theme = useTheme();
  const grey = theme.palette.grey as ColorPartial;
  const primary = theme.palette.primary as ColorPartial;
  return (
    <Box
      padding={theme.spacing(8)}
      sx={{
        background: grey[100],
        border: `${theme.spacing(0.5)} solid ${theme.palette.grey[400]}`,
      }}
    >
      <ScrollBox
        sx={{
          maxHeight: 400,
        }}
      >
        <div>
          <p>
            <strong>Spectrum Reach Client Portal</strong>
          </p>
          <p>
            By checking the box to accept these terms and conditions (this “Agreement”) and/or using
            the Spectrum Reach Client Portal (the “Portal”), you accept (in other words, make
            legally binding):
          </p>
          <p>(a)&nbsp;&nbsp; This Agreement;</p>
          <p>
            (b)&nbsp;&nbsp; The applicable Spectrum privacy policies available at{' '}
            <a href='https://www.spectrum.com/policies/your-privacy-rights'>
              https://www.spectrum.com/policies/your-privacy-rights
            </a>
            ; and
          </p>
          <p>
            (c)&nbsp;&nbsp;&nbsp; Any third-party agreements that may apply to your use of the
            Portal.
          </p>
          <p>
            This Agreement is solely between you and Charter Communications Operating, LLC and its
            subsidiaries providing you the services (“Spectrum Reach” or “we / us / our”). You may
            be subject to other agreements that govern your use of 3rd party products and services.
          </p>
          <p>
            You represent that you are of legal age to agree to this Agreement or, if you are not,
            that you have obtained parental or guardian consent to do so. If you do not agree to
            this Agreement, you may not use the Portal.
          </p>
          <p>
            The Spectrum Reach Agreement’s terms (including any usage restrictions, disclaimers and
            limitations on liability) apply to your use of the Portal as if the Spectrum Reach
            Agreement were set out in full in this Agreement. The Portal, and the content and
            information we provide to you via the Portal, constitutes “Confidential Information” of
            Spectrum Reach as that term is defined and used in the Spectrum Reach Agreement.
          </p>
          <p>
            In the event of any ambiguity, inconsistency or conflict between the Spectrum Reach
            Agreement, the Spectrum privacy policies, and this Agreement, the terms and conditions
            of this Agreement will control and prevail with respect to the limited subject matter
            hereof.
          </p>
          <p>&nbsp;</p>
          <p>
            THE SPECTRUM REACH AGREEMENT INCLUDES A BINDING ARBITRATION PROVISION, WHICH INCLUDES A
            WAIVER OF CLASS ACTIONS AND PROVISIONS FOR OPTING OUT OF ARBITRATION.
          </p>
          <p>Usage Rights and Restrictions</p>
          <p>
            You may not access or use the Portal (a) on any device that you do not own or that is
            not in your possession and control or (b) other than as authorized by this Agreement.
            You may not examine or manipulate the software code underlying the Portal, and you have
            no rights in that code other than the use right described in the preceding sentence.
          </p>
          <p>
            We may, at any time and without notice, modify or discontinue all or part of the Portal;
            charge, modify or waive fees required to use the Portal; or offer special opportunities
            to some or all Portal users, subject to applicable law.
          </p>
          <p>
            You are permitted to use the Portal only to manage and/or make payments towards your
            Spectrum Reach account and/or request changes to billing address and contact information
            for linked advertisers, and you may not circumvent or otherwise interfere with any
            authentication or access restrictions that we have implemented with respect to the
            Portal or any content or information contained in the Portal. You may not provide access
            to the Portal to any person who is not an authorized employee or agent of your business.
            You may not copy, publicly perform or retransmit any content or information you obtain
            from the Portal except as otherwise permitted in the Spectrum Reach Agreement.
          </p>
          <p>
            Without limitation, the following restrictions also apply to your use of the Portal:
          </p>
          <p>
            1.&nbsp;&nbsp;&nbsp;&nbsp; You may not access or make the Portal available over a
            network where it could be used by unauthorized parties or otherwise
            downloaded/distributed to any device without our knowledge or authorization.
          </p>
          <p>&nbsp;</p>
          <p>
            2.&nbsp;&nbsp;&nbsp;&nbsp; As a condition of the limited license for the Portal, you may
            not: (i) publish, display, disclose, rent, lease, modify, loan, distribute, or create
            derivative works based on the Portal, or any part thereof; (ii) reverse engineer,
            decompile, translate, adapt, disassemble or otherwise reduce the Portal to human
            readable form; (iii) attempt to create the source code from the object code for the
            Portal; (iv) make or convert any third party software contained in the Portal into a
            stand-alone product; (v) take any action that will infringe upon the intellectual
            property or other proprietary rights of Spectrum Reach, its affiliates, or any third
            party software provider; or (vi) sublicense, rent, lease, or assign the Portal.
          </p>
          <p>&nbsp;</p>
          <p>
            3.&nbsp;&nbsp;&nbsp;&nbsp; You may not remove any proprietary notices, marks, or labels
            from the Portal.
          </p>
          <p>&nbsp;</p>
          <p>
            4.&nbsp;&nbsp;&nbsp;&nbsp; You agree that you will only use the Portal in a manner that
            complies with all applicable laws in the jurisdictions in which you use Portal.{' '}
            <strong>
              Without limiting the foregoing or any provision of the Spectrum Reach Agreement, you
              may not upload to the portal any content or other information, or use the Portal in
              any manner, which (a) infringes, violates or misappropriates any right of any third
              party, including but not limited to copyright, trademark, patent, trade secret,
              privacy, publicity or other proprietary rights, or (b) is false or misleading.
            </strong>
          </p>
          <p>&nbsp;</p>
          <p>
            5.&nbsp;&nbsp;&nbsp;&nbsp; You may not use the Portal in an attempt to circumvent, or in
            conjunction with any device, program, or service designed to circumvent, technological
            measures employed to control access to, use of, or the rights in, a content file,
            service, or other work protected by the copyright laws of any jurisdiction.
          </p>
          <p>&nbsp;</p>
          <p>
            6.&nbsp;&nbsp;&nbsp;&nbsp; You are solely responsible for securing any
            connectivity/access to your devices and Internet service by means of passwords or other
            reliable means. As such you are solely responsible for any conduct undertaken on or via
            the Portal by means of access to your devices or Internet service by any party, whether
            or not such access is authorized by you, and any loss, damage, or other liability
            arising therefrom.
          </p>
          <p>&nbsp;</p>
          <p>
            7.&nbsp;&nbsp;&nbsp;&nbsp; You acknowledge that the Portal is the sole and exclusive
            property of Spectrum Reach. By accepting this license you do not become the owner of all
            or any part of the Portal. You further agree not to assign this Agreement to any person
            or entity without the prior written consent of Spectrum Reach. You agree to promptly
            provide written notice to Spectrum Reach of any unauthorized reproduction, publication,
            disclosure or distribution of the Portal.
          </p>
          <p>&nbsp;</p>
          <p>
            We are responsible for the Portal, but we have no obligation to furnish any maintenance
            or support services with respect to the Portal or the content or information contained
            in the Portal. Any maintenance or support we provide will be at our sole discretion.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Registration and Account Creation</strong>
          </p>
          <p>
            You may be required to provide certain registration information. You agree and represent
            that all registration information provided by you is accurate and up to date. If any of
            your registration information changes, you must update it by contacting our team via the
            Support Page.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Fees and Payment</strong>
          </p>
          <p>
            Upon prior notice to you, Spectrum Reach may charge you fees for the Portal or for
            services provided and enabled by the Portal. Any fees and payment thereof will be
            governed by the terms of the Spectrum Reach Agreement. In the event of nonpayment of
            fees for any reason, Spectrum Reach reserves the right to either suspend or terminate
            your account and all its obligations under this Agreement.
          </p>
          <p>
            Data Access Charges. Certain Portal functions may require data access, and your data
            access provider (for example, your wireless service provider) may charge you data access
            fees in connection with your use of the Portal. You are solely responsible for any data
            access or other charges you incur.
          </p>
          <p>
            <strong>Customer Experience Improvement</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            By accessing and using the Portal, you understand and consent to allow us to collect
            information that allows us to improve the quality of our services, and therefore your
            customer experience. Specifically, by such election, you consent to our collection of
            information through the use of additional code (i.e. a cookie) which may be downloaded
            to and stored on your device as a result of your access and use of the Portal. This
            additional code captures the following pertaining to your use of Portal and other
            applications on your device: the time of an event, the elements you have interacted
            with, metadata and other details about these elements, how you came to the Portal, what
            search engine and search keywords you may have used to get to the Portal, information
            about the device you are on such as the operating system, and browser, as well as your
            city/region/country location. We may also collect other information about you through
            the Portal, subject to the applicable Spectrum privacy policies.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Links and Third-Party Contact</strong>
          </p>
          <p>
            The Portal may display, or contain links to, third-party products, services, and web
            sites. Any opinions, advice, statements, services, offers, or other information that
            constitutes part of the content expressed, authored, or made available by other users or
            other third parties on the Portal, or which is accessible through or may be located
            using the Portal (collectively, &quot;Third Party Content&quot;) are those of the
            respective authors or producers and not of us or our shareholders, directors, officers,
            employees, agents, or representatives.
          </p>
          <p>
            We do not control Third Party Content and do not guarantee the accuracy, integrity or
            quality of such Third Party Content. We are not responsible for the performance of, we
            do not endorse, and we are not responsible or liable for, any Third Party Content or any
            information or materials advertised in any Third Party Content. By using the Portal, you
            may be exposed to content that is offensive, indecent, or objectionable. We are not be
            responsible or liable, directly or indirectly, for any damage or loss caused to you by
            your use of or reliance on any goods, the Portal, or information available on or through
            any third party service or Third Party Content. It is your responsibility to evaluate
            the information, opinion, advice, or other content available on and through the Portal.
          </p>
          <p>
            <strong>Electronic Communications</strong>
          </p>
          <p>
            When you visit the Portal or send e-mails to us, you are communicating with us
            electronically. You consent to receive communications regarding the Portal from us
            electronically. We will communicate with you regarding the Portal by e-mail or by
            posting notices on the Portal. You agree that all agreements, notices, disclosures and
            other communications that we provide to you electronically regarding the Portal satisfy
            any legal requirement that such communications be in writing. By way of clarification,
            any notices required under the Spectrum Reach Agreement must continue to be made in
            accordance with the terms of the Spectrum Reach Agreement.
          </p>
          <p>
            <strong>Use Policies</strong>
          </p>
          <p>
            You are solely responsible for any content and other material that you submit, publish,
            transmit, or display on, through, or with the Portal.
          </p>
          <p>
            <strong>Trademarks</strong>
          </p>
          <p>
            The Portal, the Spectrum Reach logo, and any other product or service name or slogan
            displayed on our Portal are trademarks of Spectrum Reach and its suppliers or licensors,
            and may not be copied, imitated or used, in whole or in part, without the prior written
            permission of Spectrum Reach or the applicable trademark holder. You may not use any
            metatags or any other &quot;hidden text&quot; utilizing the Portal or any other name,
            trademark or product or service name contained therein without our prior written
            permission. In addition, the look and feel of our Portal, including all page headers,
            custom graphics, button icons and scripts, is the service mark, trademark and/or trade
            dress of Spectrum Reach and may not be copied, imitated or used, in whole or in part,
            without our prior written permission. All other trademarks, registered trademarks,
            product names and company names or logos mentioned in our Portal are the property of
            their respective owners. Reference to any products, services, processes or other
            information, by trade name, trademark, manufacturer, supplier, or otherwise does not
            constitute or imply endorsement, sponsorship, or recommendation thereof by us.
          </p>
          <p>
            <strong>Feedback</strong>
          </p>
          <p>
            We may provide you with a mechanism to provide feedback, suggestions, and ideas, if you
            choose, about the Portal (&quot;Feedback&quot;). You agree that we may, in our sole
            discretion, use the Feedback you provide to us in any way, including in future
            enhancements and modifications to the Portal. You hereby grant to us and our assigns a
            perpetual, worldwide, fully transferable, sublicensable, irrevocable, royalty-free
            license to use, reproduce, modify, create derivative works from, distribute, and display
            the Feedback in any manner any for any purpose, without in any media, software, or
            technology of any kind now existing or developed in the future, without any obligation
            to provide attribution or compensation to you or any third party.
          </p>
          <p>Username and Password</p>
          <p>
            We reserve the right to reject any username or password you request in our sole
            discretion. You are responsible for maintaining the confidentiality of any username or
            password we provide to you, and you agree not to transfer such password or username, or
            lend or otherwise transfer your right to use or access the Portal, to any third party.
          </p>
          <p>
            You agree to immediately notify us of any unauthorized use of your username or password
            or any other breach of security related to your account, your username or the Portal. If
            someone else acquires this information (through no fault of ours), we may assume that
            you have authorized that person’s use of the information.
          </p>
          <p>
            <strong>Changes to</strong> <strong>This Agreement</strong>
          </p>
          <p>
            We reserve the right to change this Agreement from time to time at our sole discretion,
            and to provide you with notice of such change by any reasonable means, including by
            making the revised version available through the Portal. You can determine when this
            Agreement was last revised by referring to the &quot;Effective date&quot; legend at the
            top of this page. If you continue to use the Portal following any change in this
            Agreement, you will have accepted the changes (in other words, made them legally
            binding).
          </p>
          <p>
            <strong>Suspension or Termination of Rights</strong>
          </p>
          <p>
            We may suspend, restrict or terminate your access to or use of the Portal at any time
            and for any reason (including if we believe that you have violated or acted
            inconsistently with the letter or spirit of this Agreement, or if we believe that your
            Spectrum Reach username, password, or network has been accessed by a third party). The
            rights granted to you to use the Portal will terminate immediately upon the expiration
            or termination of the Spectrum Reach Agreement, or upon your breach of the Spectrum
            Reach Agreement or any other portion of this Agreement.
          </p>
          <p>
            We reserve the right to take any steps that we believe are necessary or appropriate to
            enforce and/or verify your compliance with this Agreement.
          </p>
          <p>System Requirements</p>
          <p>
            We may suggest or require certain system requirements, including but not limited to
            changes to your devices, network or software, in order to access or use the Portal, or
            in order to optimize Portal performance or your user experience. We will not be
            responsible for any loss or damage to your systems, applications or data resulting from
            your compliance with our system requirements, and your compliance with our system
            requirements is at your risk.
          </p>
          <p>General</p>
          <p>
            To the maximum extent permitted by applicable law, (a) we will have no warranty
            obligation whatsoever with respect to the Portal or any maintenance or support we
            provide with respect to the portal, (b) we will not be responsible for any claims,
            losses, liabilities, damages, costs or expenses attributable to any failure of the
            Portal, or any maintenance or support we provide with respect to the portal, to conform
            to any warranty, and (c) we specifically disclaim any warranties of title,
            noninfringement, fitness for a particular purpose, and merchantability. The Portal and
            any maintenance or support we provide with respect to the portal are provided &quot;As
            is&quot; and your access to and use of the Portal is at your risk. If you are
            dissatisfied with the Portal, your sole and exclusive remedy is to discontinue accessing
            and using the Portal.
          </p>
          <p>
            By accessing or using the Portal, you represent and warrant that you are not located in
            a country that is subject to a U.S. Government embargo, or that has been designated by
            the U.S. Government as a &quot;terrorist supporting&quot; country, and that you are not
            listed on any U.S. Government list of prohibited or restricted parties.
          </p>
          <p>
            If a court or similar body determines that a portion of this Agreement is invalid or
            unenforce&shy;able, the remaining terms and conditions of this Agreement should stand.
            The invalid or unenforceable portion should be interpreted as closely as possible
            (consistent with the law in your area) so as to reflect the intention of the original.
            Except with our consent, you may not transfer or assign to any other person (in other
            words, make another person legally responsible for) the rights granted by or your
            obligation to comply with this Agreement.
          </p>
          <p>
            This Agreement, together with the Spectrum Reach Agreement, and the applicable Spectrum
            privacy policies, constitute the entire agreement between you and Spectrum Reach
            relating to the Portal. You are not entitled to rely on any other agreements or
            undertakings between you and Spectrum Reach relating to the same.
          </p>
          <p>
            This Agreement is not intended to benefit (in other words, to create any rights or
            obligations for) anyone other than you and us. Upon your acceptance of these terms, we
            will have the right (and will be deemed to have accepted the right) to enforce these
            terms against you as a third-party beneficiary of this Agreement.
          </p>
          <p>
            The Portal is provided by Spectrum Reach, 1633 Broadway, 39th Floor, New York, NY 10019.
            We are responsible for addressing any claims or issues you may have relating to the
            Portal. If you have a question or complaint regarding the Portal, please e-mail us at
            reachme@charter.com.
          </p>
          <p>© 2021 Charter Communications. All rights reserved.</p>
          <p>&nbsp;</p>
        </div>
      </ScrollBox>
    </Box>
  );
}
