import React, { useEffect } from 'react';
import { Alert, Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import InvoiceOrOrders from './Invoices/InvoiceOrOrders';
import { formatAmount } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../routes/Routes';
import { IInvoiceOrOrderFilters, OrderOrInvoiceTypes } from '../../redux/overview/OverviewModels';
import {
  fetchAdvertiserOrders,
  fetchAgencyOrders,
  updateOrderFilters,
  updateSelectedOrders,
} from '../../redux/overview/OverviewSlice';
import { RootState, StoreDispatch } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { ClientTypes } from '../../redux/auth/AuthModels';
import { ComponentOverlayLoader } from '../../components/Loaders/Loader';
import { ColorPartial } from '@mui/material/styles/createPalette';
import ManagedOrders from './ManagedOrders';
import { transformToSelectedOrderItem } from '../InvoicesAndOrders/InvoiceAndOrderUtils';
import InvoiceOrOrderFilters from './InvoiceOrOrderFilters';

const OpenOrders = () => {
  const dispatch: StoreDispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const {
    orders,
    ordersError,
    ordersStatus,
    selectedOrders,
    selectedOrdersTotal,
    managedOrders,
    orderFilters,
  } = useSelector((state: RootState) => state.overview);
  const { clientType } = useSelector((state: RootState) => state.authData);
  const isAdvertiser = clientType === ClientTypes.Advertiser;

  useEffect(() => {
    if (clientType && clientType === ClientTypes.Agency) {
      dispatch(fetchAgencyOrders(orderFilters));
    }
    if (clientType && clientType === ClientTypes.Advertiser) {
      dispatch(fetchAdvertiserOrders(orderFilters));
    }
  }, [clientType, orderFilters]);

  const handlePayAll = () => {
    selectAllOrders();
    navigate(PATHS.MAKE_ORDER_PAYMENT_INFO);
  };

  const selectAllOrders = () => {
    // Filtering out any in progress orders
    const availableOrders = orders
      .map((order) => {
        return {
          ...order,
          openOrders: (order?.openOrders || []).filter((openOrder) => !openOrder.inProgress),
        };
      })
      .filter((order) => order.openOrders.length);
    let selectedOrders = transformToSelectedOrderItem(availableOrders, false);
    if (managedOrders.length) {
      selectedOrders = selectedOrders.concat(transformToSelectedOrderItem(managedOrders, true));
    }
    dispatch(updateSelectedOrders(selectedOrders));
  };

  const handleUpdateFilters = (filters: IInvoiceOrOrderFilters) => {
    dispatch(updateOrderFilters(filters));
  };

  const isOrdersLoading = ordersStatus === 'loading';

  return (
    <>
      <Typography variant='eyebrow'>PREPAYMENT</Typography>
      <Typography variant='h4'>Prepay Upcoming Orders</Typography>
      <InvoiceOrOrderFilters filters={orderFilters} setFilters={handleUpdateFilters} />
      <Box sx={{ position: 'relative', minHeight: 100 }}>
        {ordersError && (
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {ordersError}
            </Typography>
          </Alert>
        )}
        {isOrdersLoading ? (
          <Box sx={{ mt: 30 }}>
            <ComponentOverlayLoader />
          </Box>
        ) : (
          <>
            {orders?.length > 0 ? (
              <InvoiceOrOrders data={orders} type={OrderOrInvoiceTypes.Order} />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 100,
                }}
              >
                <Typography variant='body2' color={grey[500]}>
                  No Orders Found
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      {isAdvertiser && <ManagedOrders />}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 10, mb: 10 }}>
        <Typography variant='h6' mt='12' mb='8'>
          Selected Total:
        </Typography>
        <Typography variant='h6' mt='12' mb='8'>
          {formatAmount(selectedOrdersTotal)}
        </Typography>
      </Box>
      <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={theme.spacing(4)}>
        <Button
          variant='outlined'
          size='large'
          disabled={!selectedOrders?.length}
          sx={{
            width: !isMobile ? '200px' : '100%',
          }}
          onClick={() => navigate(PATHS.MAKE_ORDER_PAYMENT_INFO)}
        >
          Pay Selected
        </Button>
        <Button
          onClick={handlePayAll}
          disabled={!(orders?.length || managedOrders?.length)}
          variant='contained'
          size='large'
          sx={{ width: !isMobile ? '200px' : '100%' }}
        >
          Pay All
        </Button>
      </Box>
    </>
  );
};

export default OpenOrders;
