import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledFooter = styled('footer')(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  return {
    padding: theme.spacing(16),
    background: primary[800],
    '.help-link': {
      color: primary[200],
      width: '100%',
      margin: theme.spacing(3, 0),
      textDecoration: 'none',
      '&.half-width': {
        width: '50%',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
      '&.support-link': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '.support-icon': {
          height: '0.9em',
          marginRight: theme.spacing(2),
        },
      },
    },
    '.link-header': {
      color: white[500],
      marginBottom: theme.spacing(6),
    },
    '.right-item': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '.links-container': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '.icon': {
      color: primary[200],
      '&:first-of-type': {
        paddingLeft: 0,
      },
      '&:hover': {
        color: white[500],
      },
    },
    '.divider': {
      borderColor: grey[900],
    },
    '.anchor-link': {
      color: white[600],
      padding: theme.spacing(0, 5),
      textDecoration: 'underline',
      '&:first-of-type': {
        paddingLeft: 0,
      },
    },
    '.side-header': {
      color: white[500],
    },
    '.info-text': {
      color: white[600],
    },
  };
});
