import { Button } from '@mui/material';
import React from 'react';

interface ICancelButtonProps {
  onCancel: () => void;
}

const CancelButton = ({ onCancel }: ICancelButtonProps) => (
  <Button
    variant='text'
    sx={{
      width: 'fit-content',
      padding: 0,
      minWidth: 'auto',
    }}
    onClick={onCancel}
  >
    Cancel
  </Button>
);
export default CancelButton;
