import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Typography, useTheme } from '@mui/material';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import { IPendingUserRegistrationDetails } from '../../redux/auth/AuthModels';
import RegistrationDetails from './RegistrationDetails';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { signupPendingUser } from '../../redux/auth/AuthSlice';
import TermsAndConditions from '../ReviewRequest/TermsAndConditions';
import HelpText from '../ReviewRequest/HelpText';

const CompleteRegistration = () => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { signupPendingUserStatus, signupPendingUserError } = useSelector(
    (state: RootState) => state.authData,
  );
  const [userRegistrationDetails, setUserRegistrationDetails] =
    useState<IPendingUserRegistrationDetails>({
      firstName: '',
      lastName: '',
      isTermsAndConditionsAccepted: false,
    });

  const requiredFields: Array<keyof IPendingUserRegistrationDetails> = [
    'firstName',
    'lastName',
    'isTermsAndConditionsAccepted',
  ];

  const isAllReqFieldsFilled = requiredFields.reduce((isValid, key) => {
    return isValid && !!userRegistrationDetails[key];
  }, true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let value: string | boolean = event.target.value;
    if (name === 'isTermsAndConditionsAccepted') {
      value = event.target.checked;
    }
    setUserRegistrationDetails({ ...userRegistrationDetails, [name]: value });
  };

  const handleComplete = () => {
    dispatch(signupPendingUser(userRegistrationDetails));
  };

  useEffect(() => {
    if (signupPendingUserStatus === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [signupPendingUserStatus]);

  return (
    <PageContentContainer>
      <Box mt={25} mb={25}>
        <Typography variant='eyebrow' mt={20} mb={5}>
          Complete Registration
        </Typography>
        <RegistrationDetails
          userRegistrationDetails={userRegistrationDetails}
          handleChange={handleChange}
        />
        <TermsAndConditions formData={userRegistrationDetails} handleChange={handleChange} />
        {signupPendingUserError && (
          <Typography mt={10} mb={10} variant='paragraph2' color='error'>
            {signupPendingUserError}
          </Typography>
        )}
        <Button
          variant='contained'
          size='large'
          onClick={handleComplete}
          disabled={!isAllReqFieldsFilled}
        >
          {isLoading ? (
            <CircularProgress
              size={28}
              style={{ color: theme.palette.common.white, marginRight: 10 }}
              className='loader'
            />
          ) : (
            'Continue'
          )}
        </Button>
        <Box
          marginTop={theme.spacing(16)}
          display='flex'
          flexDirection='column'
          gap={theme.spacing(8)}
        >
          <Divider />
          <HelpText />
        </Box>
      </Box>
    </PageContentContainer>
  );
};
export default CompleteRegistration;
