import React, { useEffect } from 'react';
import {
  IAdvertiserOrAgency,
  InvoiceOrOrderPaymentStatus,
  IOpenInvoice,
  ISelectedInvoiceOrOrderItem,
} from '../../redux/overview/OverviewModels';
import { Box, useTheme } from '@mui/material';
import InvoiceDetails from './Invoices/InvoiceDetails';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { useVirtualizer } from '@tanstack/react-virtual';

interface IInvoiceProps {
  advertiser: IAdvertiserOrAgency;
  selectedInvoices: ISelectedInvoiceOrOrderItem[];
  handleSelection: (advertiser: IAdvertiserOrAgency, invoice: IOpenInvoice) => void;
}

const Invoice = ({ advertiser, selectedInvoices, handleSelection }: IInvoiceProps) => {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const items = advertiser.openInvoices || [];

  const [collapseStatus, setCollapseStatus] = React.useState<any>({});

  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,

    estimateSize: (i) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return collapseStatus?.[items[i].invoiceNumber] ? 780 : 130;
    },
  });
  useEffect(() => {
    virtualizer.measure();
  }, [collapseStatus]);

  const selectedInvoice = selectedInvoices.find((selectedInvoice) => {
    return selectedInvoice?.advertiserOrAgencyId === advertiser?.advertiserOrAgencyId;
  });

  const handleCollapse = (invoiceId: any, status: boolean) => {
    setCollapseStatus({ ...collapseStatus, [invoiceId]: status });
  };

  return (
    <Box sx={{ maxHeight: '400px', overflowY: 'auto', p: 5 }} ref={parentRef}>
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {virtualizer.getVirtualItems().map((virtualRow) => {
          const invoice: IOpenInvoice = items[virtualRow.index];
          const isSelected = (selectedInvoice?.items || []).find(
            (item) => item?.number === invoice?.invoiceNumber,
          );
          return (
            <Box
              key={virtualRow.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
              py={4}
              borderTop={`${theme.spacing(0.5)} dashed ${grey[200]}`}
            >
              <InvoiceDetails
                selectable
                isSelected={!!isSelected}
                onSelect={(invoice, checked) => handleSelection(advertiser, invoice)}
                paymentStatus={InvoiceOrOrderPaymentStatus.UnPaid}
                invoice={invoice}
                collapseStatus={!!collapseStatus?.[invoice.invoiceNumber]}
                onCollapse={handleCollapse}
              />
            </Box>
          );
        })}
      </div>
    </Box>
  );
};
export default Invoice;
