import React from 'react';
import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { InvoiceAndOrdersViewType, PaymentFlowType } from '../../../redux/overview/OverviewModels';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/Store';
import { formatAmount } from '../../../utils/common';
import SelectedInvoicesAndOrders from '../SelectedInvoicesAndOrders/SelectedInvoicesAndOrders';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const PaymentAmount = ({
  totalAmount,
  handleNext,
}: {
  totalAmount: number;
  handleNext: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const { selectedInvoicesTotal, selectedOrdersTotal } = useSelector(
    (state: RootState) => state.overview,
  );
  const { selectedPaymentFlowType } = useSelector((state: RootState) => state.paymentData);
  const isInvoice = selectedPaymentFlowType === PaymentFlowType.Invoices;
  const total = isInvoice ? selectedInvoicesTotal : selectedOrdersTotal;
  return (
    <>
      <Box mt={6}>
        <Box sx={{ backgroundColor: theme.palette.grey[50], padding: theme.spacing(8) }}>
          <Typography variant='eyebrow'>
            {isInvoice ? 'Invoices Total:' : 'Orders total:'}
          </Typography>
          <Typography variant='h3' mt={theme.spacing(3)}>
            {formatAmount(totalAmount)}
          </Typography>
        </Box>
        <Box mt={10} mb={10}>
          <SelectedInvoicesAndOrders viewType={InvoiceAndOrdersViewType.PAYMENT_AMOUNT} />
          <>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 8, mb: 8 }}>
              <Typography variant='h6'>Total:</Typography>
              <Typography variant='h6'>{formatAmount(total)}</Typography>
            </Box>
          </>
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Button
          variant='outlined'
          size='large'
          disabled={true}
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 12 : 16),
            margin: isMobile ? theme.spacing(0, 0, 10, 0) : theme.spacing(0),
          }}
        >
          Previous
        </Button>
        <Button
          variant='contained'
          size='large'
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 16 : 24),
          }}
          onClick={handleNext}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};
export default PaymentAmount;
