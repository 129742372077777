import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import SpectrumTextField from '../../components/Spectrum/TextField/SpectrumTextField';
import { IPendingUserRegistrationDetails } from '../../redux/auth/AuthModels';

type IRegistrationDetailsProps = {
  userRegistrationDetails: IPendingUserRegistrationDetails;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RegistrationDetails = ({
  userRegistrationDetails,
  handleChange,
}: IRegistrationDetailsProps) => {
  const theme = useTheme();
  return (
    <>
      <Typography variant='h3' mb={10}>
        Your Information
      </Typography>
      <Typography variant='paragraph2' mb={5} color={theme.palette.common.black}>
        Complete the next steps to complete your registration for the Client Portal.
      </Typography>
      <Box
        mt={10}
        mb={10}
        sx={{
          display: 'grid',
          gap: 10,
        }}
      >
        <SpectrumTextField
          required
          label='First Name'
          variant='outlined'
          size='medium'
          name='firstName'
          onChange={handleChange}
          value={userRegistrationDetails?.firstName}
        />
        <SpectrumTextField
          required
          label='Last Name'
          variant='outlined'
          size='medium'
          name='lastName'
          onChange={handleChange}
          value={userRegistrationDetails?.lastName}
        />
      </Box>
    </>
  );
};
export default RegistrationDetails;
