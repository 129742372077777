import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ColorPartial } from '@mui/material/styles/createPalette';

const InvoiceOrOrderDetailsRow = ({ label, value }: { label: string; value: string }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return (
    <>
      <Box
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent='space-between'
        pt={theme.spacing(8)}
        pb={theme.spacing(12)}
      >
        <Typography variant='eyebrow'>{label}</Typography>
        <Typography variant='paragraph1'>{value}</Typography>
      </Box>
      <Divider
        sx={{
          borderStyle: 'dashed',
          color: grey[200],
        }}
      />
    </>
  );
};
export default InvoiceOrOrderDetailsRow;
