import {
  ClientTypes,
  IAuthState,
  IFetchAccessTokenUsingRefreshToken,
  IFetchTokenUsingCodePayload,
  INewUserRegistrationDetails,
  IPendingUserRegistrationDetails,
} from './AuthModels';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../services/ApiService';
import { appConstants, authApiEndPoints, ssoConfig } from '../../constants/apiConstants';
import queryString from 'query-string';
import {
  constants,
  createSessionToken,
  getAuthItem,
  removeTokens,
  saveToken,
} from '../../utils/authUtils';
import { IAPIResponseError } from '../autoPay/AutoPayModel';
import { formatErrors } from '../../utils/common';

const initialState: IAuthState = {
  userDetails: null,
  clientType: null,
  clientId: '',
  clientName: '',
  userDetailsStatus: 'idle',
  userDetailsError: null,
  error: null,
  accessTokenStatus: 'idle',
  signupPendingUserStatus: 'idle',
  signupPendingUserError: null,
  signupNewUserStatus: 'idle',
  signupNewUserError: null,
};

export const fetchTokenUsingAuthCode = createAsyncThunk(
  'auth/fetchTokenUsingCode',
  async (authCode: string) => {
    const payload: IFetchTokenUsingCodePayload = {
      grant_type: 'authorization_code',
      code: authCode,
      redirect_uri: appConstants.callbackURL,
      client_id: ssoConfig.clientId,
      scope: ssoConfig.scope,
    };
    const formData = queryString.stringify(payload);
    const response = await ApiService.postData(authApiEndPoints.tokenURL, {}, formData, {
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return response.data;
  },
);
export const fetchAccessTokenUsingRefreshToken = createAsyncThunk(
  'auth/fetchAccessTokenUsingRefreshToken',
  async () => {
    const refreshToken = getAuthItem(constants.REFRESH_TOKEN);
    const payload: IFetchAccessTokenUsingRefreshToken = {
      grant_type: 'refresh_token',
      client_id: ssoConfig.clientId,
      refresh_token: refreshToken || '',
    };
    const formData = queryString.stringify(payload);
    const response = await ApiService.postData(authApiEndPoints.tokenURL, {}, formData, {
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return response.data;
  },
);
export const fetchUserDetails = createAsyncThunk('auth/userDetails', async (params) => {
  const response = await ApiService.getData(authApiEndPoints.userDetails, params);
  return response.data;
});
export const signupPendingUser = createAsyncThunk(
  'auth/signupUser',
  async (data: IPendingUserRegistrationDetails, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(authApiEndPoints.signupPendingUser, {}, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const signupNewUser = createAsyncThunk(
  'auth/signupNewUser',
  async (data: INewUserRegistrationDetails, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(authApiEndPoints.signupNewUser, {}, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const AuthDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    logoutUser: (state) => {
      removeTokens();
      state.accessTokenStatus = 'idle';
      state.userDetailsStatus = 'idle';
      state.userDetails = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTokenUsingAuthCode.pending, (state) => {
        state.accessTokenStatus = 'loading';
      })
      .addCase(fetchTokenUsingAuthCode.fulfilled, (state, action) => {
        state.accessTokenStatus = 'succeeded';
        createSessionToken();
        saveToken(action.payload);
      })
      .addCase(fetchTokenUsingAuthCode.rejected, (state, action) => {
        state.accessTokenStatus = 'failed';
        state.error = action?.error?.message || 'Failed to get token details';
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.userDetailsStatus = 'loading';
        state.userDetailsError = '';
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.userDetailsStatus = 'succeeded';
        state.userDetails = action?.payload?.data;
        const clientType = state.userDetails?.tblUser?.tblAdvertiser
          ? ClientTypes.Advertiser
          : ClientTypes.Agency;
        if (clientType === ClientTypes.Advertiser) {
          state.clientId = state.userDetails?.tblUser?.tblAdvertiser?.advertiserId || '';
          state.clientName = state.userDetails?.tblUser?.tblAdvertiser?.advertiserName || '';
        }
        if (clientType === ClientTypes.Agency) {
          state.clientId = state.userDetails?.tblUser?.tblAgency?.agencyId || '';
          state.clientName = state.userDetails?.tblUser?.tblAgency?.agencyName || '';
        }
        state.clientType = clientType;
        state.userDetailsError = '';
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.userDetailsStatus = 'failed';
        state.userDetailsError = action?.error?.message || 'Failed to get user details';
      })
      .addCase(fetchAccessTokenUsingRefreshToken.pending, (state) => {
        state.accessTokenStatus = 'loading';
      })
      .addCase(fetchAccessTokenUsingRefreshToken.fulfilled, (state, action) => {
        state.accessTokenStatus = 'succeeded';
        saveToken(action.payload);
      })
      .addCase(fetchAccessTokenUsingRefreshToken.rejected, (state, action) => {
        state.accessTokenStatus = 'failed';
        state.error = action?.error?.message || 'Failed to renew access token';
      })
      .addCase(signupPendingUser.pending, (state) => {
        state.signupPendingUserStatus = 'loading';
      })
      .addCase(signupPendingUser.fulfilled, (state, action) => {
        state.signupPendingUserStatus = 'succeeded';
        state.userDetails = action?.payload?.data;
      })
      .addCase(signupPendingUser.rejected, (state, action) => {
        state.signupPendingUserStatus = 'failed';
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        const errorMessage = response?.errors ? formatErrors(response?.errors) : response?.error;
        state.signupPendingUserError = errorMessage || 'Failed to sign up user';
      })
      .addCase(signupNewUser.pending, (state) => {
        state.signupNewUserError = '';
        state.signupNewUserStatus = 'loading';
      })
      .addCase(signupNewUser.fulfilled, (state) => {
        state.signupNewUserStatus = 'succeeded';
      })
      .addCase(signupNewUser.rejected, (state, action) => {
        state.signupNewUserStatus = 'failed';
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        const errorMessage = response?.errors ? formatErrors(response?.errors) : response?.error;
        state.signupNewUserError = errorMessage || 'Failed to sign up user';
      });
  },
});
export const { logoutUser } = AuthDataSlice.actions;
export default AuthDataSlice.reducer;
