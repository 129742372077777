import { styled } from '@mui/material/styles';
import { AppBar, Menu } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledAppBar = styled(AppBar)(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  return {
    background: primary[800],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    minHeight: theme.spacing(38),
    padding: theme.spacing(0, 16),
    position: 'relative',
    zIndex: 1300,
    boxShadow: 'none',
    '.logo-container': {
      display: 'flex',
      alignItems: 'center',
      '.project-logo': {
        marginLeft: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        color: theme.palette.common.white,
        textAlign: 'left',
        textTransform: 'uppercase',
        width: '60px',
        borderLeft: `1px solid ${white[300]}`,
      },
    },
    '.links-container': {
      flexGrow: 1,
      textAlign: 'center',
      '.link': {
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: theme.spacing(38),
        height: theme.spacing(38),
        textDecoration: 'none',
        margin: theme.spacing(0, 10),
        color: white[600],
        outline: 'none',
        '&:hover': {
          cursor: 'pointer',
          borderBottom: `${theme.spacing(2)} solid ${primary[200]}`,
          color: white[500],
        },
        '&.link-active': {
          borderBottom: `${theme.spacing(2)} solid ${primary[200]}`,
          color: white[500],
        },
      },
    },
    '.right': {
      display: 'flex',
      alignItems: 'center',
    },
  };
});
export const StyledNavSubMenu = styled(Menu)(({ theme }) => {
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    '.link': {
      display: 'block',
      minWidth: '200px',
      textDecoration: 'none',
      li: {
        color: `${black[500]}`,
      },
    },
    '.link-active': {
      li: {
        color: `${white[500]}`,
        backgroundColor: `${grey[500]}`,
      },
    },
  };
});
