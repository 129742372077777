import React from 'react';
import SpectrumAlert from '../../../components/Spectrum/Alert/SpectrumAlert';
import { Button, useMediaQuery, useTheme } from '@mui/material';

const PaymentAmount = ({ handleNext }: { handleNext: () => void }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  return (
    <>
      <SpectrumAlert
        message='You will be charged the total balance for your account on the dates you specify. The total
      payment may fluctuate from month-to-month.'
      />
      <Button
        variant='contained'
        size='large'
        sx={{
          width: !isMobile ? 'fit-content' : '100%',
          padding: theme.spacing(9, isMobile ? 16 : 24),
        }}
        onClick={handleNext}
      >
        Continue
      </Button>
    </>
  );
};

export default PaymentAmount;
