import React, { useEffect, useState } from 'react';
import { PATHS } from '../../routes/Routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { formatAmount, formatDate } from '../../utils/common';
import CancelPaymentDialog from './CancelPayment';

import {
  ArrowBack,
  CalendarMonthOutlined,
  CreditCardOutlined,
  ExpandCircleDownOutlined,
  PrintOutlined,
} from '@mui/icons-material';
import {
  cancelScheduledPayment,
  fetchPaymentHistoryDetails,
  resetCancelledPaymentStatus,
} from '../../redux/payment/PaymentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { ComponentLoader } from '../../components/Loaders/Loader';
import StatusCell from '../../components/Spectrum/Table/StatusCell';
import { IOpenInvoice, IOpenOrder, OrderOrInvoiceTypes } from '../../redux/overview/OverviewModels';
import OrderDetails from '../Overview/Invoices/OrderDetails';
import InvoiceDetails from '../Overview/Invoices/InvoiceDetails';
import {
  IPaidInvoicesAndOrderItem,
  IPaidInvoicesAndOrders,
} from '../../redux/payment/PaymentModel';
import { transformToInvoice, transformToOrder } from './InvoiceAndOrderUtils';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from '../Overview/Invoices/InvoiceStyles';

const PaymentDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch: StoreDispatch = useDispatch();
  const {
    paymentHistoryDetails,
    paymentHistoryDetailsStatus,
    paymentHistoryDetailsError,
    cancelledScheduledPaymentStatus,
    cancelledScheduledPaymentError,
  } = useSelector((state: RootState) => state.paymentData);
  const { contactInfo } = useSelector((state: RootState) => state.helpData);

  const [collapseStatus, setCollapseStatus] = React.useState<{ [x: string]: boolean }>({});

  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const [showCancelPaymentDialog, setShowCancelPaymentDialog] = useState(false);

  const handlePrint = () => {
    window?.print();
  };

  const handleCancelPaymentDialog = () => {
    showCancelPaymentDialog && dispatch(resetCancelledPaymentStatus());
    setShowCancelPaymentDialog((prv) => !prv);
  };

  const handelConfirm = () => {
    dispatch(cancelScheduledPayment(paymentHistoryDetails!.transactionId));
  };

  const goBack = () => {
    navigate(PATHS.OVERVIEW);
  };

  const getPaymentHistoryDetailsWrapper = () => {
    dispatch(
      fetchPaymentHistoryDetails({
        transactionId: params.get('transactionId') ?? '',
      }),
    );
  };

  const handleCollapse = (itemId: string, status: boolean) => {
    setCollapseStatus({ ...collapseStatus, [itemId]: status });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPaymentHistoryDetailsWrapper();
  }, []);

  useEffect(() => {
    if (cancelledScheduledPaymentStatus === 'succeeded') {
      setShowCancelPaymentDialog(false);
      getPaymentHistoryDetailsWrapper();
    }
  }, [cancelledScheduledPaymentStatus]);

  const renderPaymentDetails = () => {
    if (paymentHistoryDetailsStatus === 'loading') {
      return <ComponentLoader />;
    }

    if (paymentHistoryDetailsStatus === 'failed') {
      return <ErrorPage title='Something went wrong' message={paymentHistoryDetailsError} />;
    }

    if (!paymentHistoryDetails) {
      return (
        <Typography color='info' my={theme.spacing(10)} variant='body2'>
          You don&apos;t have any invoices & orders
        </Typography>
      );
    }

    return (
      <>
        <Box sx={{ mb: theme.spacing(12) }} display='flex' justifyContent='space-between'>
          <Box>
            <Typography sx={{ mb: 2 }} variant='h2'>
              Payment Details
            </Typography>
            <Typography variant='paragraph2'>These are the details for this payment.</Typography>
          </Box>
          <Box display='flex' gap={theme.spacing(12)}>
            <Button
              onClick={handlePrint}
              variant='text'
              sx={{ justifyContent: 'flex-start', gap: theme.spacing(4) }}
            >
              <PrintOutlined />
              Print
            </Button>
            {paymentHistoryDetails.isEligibleToCancel && (
              <Button variant='text' onClick={handleCancelPaymentDialog} sx={{ color: grey[500] }}>
                Cancel Payment
              </Button>
            )}
          </Box>
        </Box>
        <Divider sx={{ mb: theme.spacing(8) }} />
        <Box>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='eyebrow' color={theme.palette.secondary.main}>
              Summary
            </Typography>
            <StatusCell status={paymentHistoryDetails.paymentStatus} />
          </Box>
          <Box
            display='grid'
            gridTemplateColumns='1fr 1fr'
            my={theme.spacing(12)}
            rowGap={theme.spacing(8)}
          >
            <Typography variant='h5' flexGrow={1}>
              Payment ID
            </Typography>
            <Typography flexGrow={1} color='black'>
              {`#${paymentHistoryDetails.transactionId}`}
            </Typography>
            <Typography variant='h5' flexGrow={1}>
              Amount
            </Typography>
            <Typography flexGrow={1} color='black'>
              {formatAmount(paymentHistoryDetails.amount)}
            </Typography>
            <Typography variant='h5' flexGrow={1}>
              Payment Date
            </Typography>
            <Typography flexGrow={1} color='black'>
              {formatDate(paymentHistoryDetails.paymentDate)}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mb: theme.spacing(8) }} />
        {paymentHistoryDetails.paidInvoicesAndOrders.length > 0 && (
          <Box>
            <Box display='flex'>
              <Typography variant='eyebrow' color={theme.palette.secondary.main}>
                Invoices & Orders Paid
              </Typography>
            </Box>
            <Box my={theme.spacing(12)}>
              {paymentHistoryDetails.paidInvoicesAndOrders.map(
                (paidInvoiceAndOrder: IPaidInvoicesAndOrders, index: number) => (
                  <StyledAccordion key={index}>
                    <StyledAccordionSummary
                      expandIcon={<ExpandCircleDownOutlined color='primary' />}
                    >
                      <Box
                        display='flex'
                        justifyContent='space-between'
                        sx={{
                          width: '100%',
                        }}
                      >
                        <Typography variant='h6'>{paidInvoiceAndOrder.advertiserName}</Typography>
                        <Typography>{formatAmount(paidInvoiceAndOrder.amount)}</Typography>
                      </Box>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                      {paidInvoiceAndOrder?.invoicesAndOrderDetails.map(
                        (invoicesAndOrder: IPaidInvoicesAndOrderItem, index: number) => {
                          if (invoicesAndOrder?.type === OrderOrInvoiceTypes.Invoice) {
                            const invoiceDetails: IOpenInvoice =
                              transformToInvoice(invoicesAndOrder);
                            return (
                              <Box
                                key={index}
                                py={4}
                                borderTop={`${theme.spacing(0.5)} dashed ${grey[200]}`}
                              >
                                <InvoiceDetails
                                  collapseStatus={!!collapseStatus?.[invoiceDetails.invoiceNumber]}
                                  onCollapse={handleCollapse}
                                  key={index}
                                  invoice={invoiceDetails}
                                />
                              </Box>
                            );
                          } else {
                            const orderDetails: IOpenOrder = transformToOrder(invoicesAndOrder);
                            const id = orderDetails.orderNumber + orderDetails.dueDate;
                            return (
                              <Box
                                key={index}
                                py={4}
                                borderTop={`${theme.spacing(0.5)} dashed ${grey[200]}`}
                              >
                                <OrderDetails
                                  collapseStatus={!!collapseStatus?.[id]}
                                  onCollapse={handleCollapse}
                                  key={index}
                                  order={orderDetails}
                                />
                              </Box>
                            );
                          }
                        },
                      )}
                    </StyledAccordionDetails>
                  </StyledAccordion>
                ),
              )}
            </Box>
          </Box>
        )}
        <Divider sx={{ mb: theme.spacing(8) }} />
        <Box>
          <Box display='flex'>
            <Typography variant='eyebrow' color={theme.palette.secondary.main}>
              Payment Method
            </Typography>
          </Box>
          <Box my={theme.spacing(12)}>
            <Typography variant='h6' mb={theme.spacing(4)}>
              {paymentHistoryDetails.cardName}
            </Typography>
            <Box display='flex' gap={theme.spacing(4)}>
              <CreditCardOutlined color='primary' />
              <Typography variant='body1'>{paymentHistoryDetails.cardDetail}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mb: theme.spacing(8) }} />
        <Box>
          <Box display='flex'>
            <Typography variant='eyebrow' color={theme.palette.secondary.main}>
              Payment date
            </Typography>
          </Box>
          <Box my={theme.spacing(12)}>
            <Box display='flex' gap={theme.spacing(4)}>
              <CalendarMonthOutlined color='primary' />
              <Typography variant='body1'>
                {formatDate(paymentHistoryDetails.paymentDate)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <CancelPaymentDialog
          apiStatus={cancelledScheduledPaymentStatus}
          errorMsg={cancelledScheduledPaymentError}
          open={showCancelPaymentDialog}
          onClose={handleCancelPaymentDialog}
          onConfirm={handelConfirm}
          helpEmail={contactInfo?.email}
        />
      </>
    );
  };

  return (
    <PageContentContainer>
      <Button sx={{ justifyContent: 'flex-start', p: 0 }} onClick={goBack}>
        <ArrowBack sx={{ mr: theme.spacing(2) }} />
        Payment History
      </Button>
      <Box display='flex' flexDirection='column' rowGap={theme.spacing(4)}>
        {renderPaymentDetails()}
      </Box>
    </PageContentContainer>
  );
};

export default PaymentDetails;
