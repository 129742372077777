import React from 'react';

const confirmationMessage =
  'Are you sure you want to leave this page, you may lose all the progress and have to start again?';
const useConfirmPageReload = (isUnsafeTabClose: boolean) => {
  React.useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      if (isUnsafeTabClose) {
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isUnsafeTabClose]);
};

export default useConfirmPageReload;
