import React, { SetStateAction } from 'react';
import { Alert, Box, Button, useMediaQuery, useTheme } from '@mui/material';

import StoredPaymentMethod from '../../../components/StoredPaymentMethods/StoredPaymentMethod';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const SelectPaymentMethod = ({
  selectedPaymentMethodId,
  setSelectedPaymentMethodId,
  handleNext,
  handlePrevious,
}: {
  selectedPaymentMethodId: string;
  setSelectedPaymentMethodId: React.Dispatch<SetStateAction<string>>;
  handleNext?: () => void;
  handlePrevious?: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  return (
    <>
      <Alert
        severity='info'
        variant='standard'
        sx={{
          color: theme.palette.common.black,
          fontSize: '1rem',
        }}
      >
        For payments over $100,000.00, please use a bank account.
      </Alert>
      <StoredPaymentMethod
        isSelectable
        selectedPaymentMethodId={selectedPaymentMethodId}
        setSelectedPaymentMethodId={setSelectedPaymentMethodId}
      />
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Button
          variant='outlined'
          size='large'
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 12 : 16),
            margin: isMobile ? theme.spacing(0, 0, 10, 0) : theme.spacing(0),
          }}
          onClick={handlePrevious}
        >
          Previous
        </Button>
        <Button
          variant='contained'
          size='large'
          disabled={!selectedPaymentMethodId}
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 16 : 24),
          }}
          onClick={handleNext}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};

export default SelectPaymentMethod;
