import React from 'react';
import { Alert, AlertColor, Typography } from '@mui/material';

interface ISpectrumAlertProps {
  message: string;
  severity?: AlertColor;
  variant?: 'standard' | 'filled' | 'outlined';
}

const SpectrumAlert = ({
  message,
  severity = 'info',
  variant = 'standard',
}: ISpectrumAlertProps) => {
  return (
    <Alert severity={severity} variant={variant}>
      <Typography variant='paragraph2' component='span' color='black'>
        {message}
      </Typography>
    </Alert>
  );
};
export default SpectrumAlert;
