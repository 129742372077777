import { styled } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledContainer = styled('div')(({ theme }) => {
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return {
    backgroundColor: grey[50],
    padding: theme.spacing(6),
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    '.footer': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(8),
    },
  };
});
