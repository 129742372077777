import { configureStore } from '@reduxjs/toolkit';
import AuthDataReducer from './auth/AuthSlice';
import OverviewDataReducer from './overview/OverviewSlice';
import AutoPayReducer from './autoPay/AutoPaySlice';
import PaymentReducer from './payment/PaymentSlice';
import AccountReducer from './account/AccountSlice';
import TransactionHistoryReducer from './transaction/TransactionSlice';
import InvoicesAndOrdersReducer from './InvoicesAndOrders/InvoicesAndOrderSlice';
import HelpReducer from './help/HelpSlice';

export const store = configureStore({
  reducer: {
    authData: AuthDataReducer,
    overview: OverviewDataReducer,
    autoPay: AutoPayReducer,
    paymentData: PaymentReducer,
    accountData: AccountReducer,
    transactionData: TransactionHistoryReducer,
    invoiceAndOrdersData: InvoicesAndOrdersReducer,
    helpData: HelpReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `StoreDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type StoreDispatch = typeof store.dispatch;
