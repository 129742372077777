import { Alert } from '@mui/material';
import React from 'react';

const OutageAlert = ({ message }: { message: string }) => {
  return (
    <Alert severity='error' sx={{ justifyContent: 'center' }}>
      {message}
    </Alert>
  );
};

export default OutageAlert;
