import React from 'react';
import { StyledTablePagination } from './SpectrumPaginationStyles';

interface ISpectrumPaginationProps {
  currentPage: number;
  itemsPerPage: number;
  onPageChange?: (page: number) => void;
  onItemsPerPageChange?: (page: number) => void;
  totalItems: number;
  pageOptions?: number[];
  labelRowsPerPage?: string;
}

const SpectrumPagination = ({
  itemsPerPage,
  currentPage,
  onPageChange,
  onItemsPerPageChange,
  totalItems,
  labelRowsPerPage = 'Rows per page',
}: ISpectrumPaginationProps) => {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (onItemsPerPageChange) {
      onItemsPerPageChange(parseInt(event.target.value, 10));
    }
  };
  return (
    <StyledTablePagination
      rowsPerPageOptions={[5, 10, 25, 50]}
      count={totalItems}
      rowsPerPage={itemsPerPage ? Number(itemsPerPage) : 0}
      page={currentPage}
      labelRowsPerPage={labelRowsPerPage}
      SelectProps={{
        inputProps: {
          'aria-label': labelRowsPerPage,
        },
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};
export default SpectrumPagination;
