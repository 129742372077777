import { Button, styled, useMediaQuery } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledButton = styled(Button)(({ theme }) => {
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  return {
    padding: theme.spacing(0, 3),
    minWidth: 18,
    height: 26,
    borderRadius: 0,
    margin: theme.spacing(isMobile ? 5 : 0, 1.5),
    color: black[200],
    '&:hover': {
      color: black[500],
    },
    '&.selected': {
      color: black[500],
    },
  };
});
