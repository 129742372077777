import { CardContent, FormControlLabel, styled } from '@mui/material';

export const StyledRadioLabel = styled(FormControlLabel)(({ theme }) => {
  return {
    padding: theme.spacing(6, 4),
    margin: theme.spacing(4, 0, 4, 0),
    boxShadow: theme.shadows[2],
    border: `2px solid ${theme.palette.grey[200]}`,
    '.MuiFormControlLabel-label': {
      width: '100%',
    },
  };
});
export const StyledCardContent = styled(CardContent)(() => {
  return {
    display: 'flex',
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  };
});
