import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { StyledDateRangePickerWrapper } from './DateRangePickerStyles';

interface ICustomDatePicker extends ReactDatePickerProps {
  label: string;
  value: any;
  onChange: any;
  name?: any;
}

const CustomDateRangePicker = ({ label, value, onChange, name, ...rest }: ICustomDatePicker) => {
  const [startDate = null, endDate = null] = value || [];
  const handleChange = (date: [Date | null, Date | null]) => {
    onChange({ target: { name, value: date } });
  };
  const handleCalendarClose = () => {
    if (startDate && !endDate) {
      onChange({ target: { name, value: [startDate, startDate] } });
    }
  };
  return (
    <StyledDateRangePickerWrapper>
      {label && <label>{label}</label>}
      <DatePicker
        {...rest}
        selected={startDate}
        onChange={handleChange}
        onCalendarClose={handleCalendarClose}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        monthsShown={2}
        placeholderText='MM/DD/YYYY - MM/DD/YYYY'
      />
    </StyledDateRangePickerWrapper>
  );
};

export default CustomDateRangePicker;
