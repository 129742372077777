import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';

const HelpText = () => {
  const theme = useTheme();
  const { contactInfo } = useSelector((state: RootState) => state.helpData);
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  return (
    <Typography variant='body1'>
      For help, please reach out to{' '}
      <Typography color={primary[500]} variant='underline' component='span'>
        {contactInfo?.email}.
      </Typography>
    </Typography>
  );
};

export default HelpText;
