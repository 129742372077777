import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';

export const StyledHelpBox = styled(Box)(({ theme }) => ({
  '.new-user': {
    maxWidth: 700,

    '.MuiAccordionSummary-root': {
      minHeight: 'auto',
    },

    '.MuiAccordionSummary-content': {
      margin: theme.spacing(2, 0),
    },
  },
  '.faqs': {
    maxWidth: 700,

    '.MuiAccordion-root.Mui-expanded': {
      margin: theme.spacing(4, 0),
    },

    '.MuiAccordionSummary-root': {
      minHeight: 'auto',
    },
  },
}));

export const StyledHelpAccordion = styled(MuiAccordion)(({ theme }) => ({
  background: theme.palette.grey[100],
  padding: theme.spacing(4, 6.5),

  ':first-of-type': {
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
  },
}));

export const StyledFAQAccordion = styled(MuiAccordion)(({ theme }) => ({
  border: `${theme.spacing(0.5)} solid ${theme.palette.grey[400]}`,
  boxShadow: 'none',
  padding: theme.spacing(0, 6.5),
  marginBottom: 8,

  ':first-of-type': {
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
  },
}));

export const StyledAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(360deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(5, 0),
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    minHeight: 'auto',
    marginBottom: 6,
    marginTop: 10,
  },
}));

export const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: theme.palette.common.black,
  padding: theme.spacing(4),
}));
