import React, { useEffect } from 'react';
import { Alert, Box, Button, MenuItem, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import SpectrumSelectField from '../../../components/Spectrum/Select/SpectrumSelect';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { fetchAdvertisers, fetchRoles, setAdvertisers } from '../../../redux/account/AccountSlice';
import { ComponentOverlayLoader } from '../../../components/Loaders/Loader';
import { IAdvertiser, IInviteUserDetails } from '../../../redux/account/AccountModels';
import { Roles } from '../Members/MemberRoleMappings';
import { ClientTypes } from '../../../redux/auth/AuthModels';

interface ISelectRoleStepProps {
  userDetails: IInviteUserDetails;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  handlePrevious: () => void;
}

const SelectRoleStep = ({
  userDetails,
  handleChange,
  handleNext,
  handlePrevious,
}: ISelectRoleStepProps) => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const { roles, rolesError, rolesStatus, advertisers, accountInformation } = useSelector(
    (state: RootState) => state.accountData,
  );
  const {
    userDetails: loggedInUserDetails,
    clientType,
    clientId,
    userDetails: loginUserDetails,
  } = useSelector((state: RootState) => state.authData);
  const isManager = loggedInUserDetails?.tblUser?.tblUserRole?.name === Roles.MANAGER;
  const filteredRoles = isManager
    ? (roles || []).filter((role) => role.name !== Roles.MANAGER)
    : roles;

  const isAgency = clientType === ClientTypes.Agency;

  useEffect(() => {
    if (!roles.length) {
      dispatch(fetchRoles({ includeAccountOwner: false }));
    }
    if (!advertisers.length && isAgency) {
      dispatch(fetchAdvertisers({ agencyId: accountInformation?.accountPkId }));
    } else if (!isAgency) {
      const advertiserDetails: IAdvertiser = {
        advertiserId: clientId,
        advertiserName: loginUserDetails?.tblUser?.tblAdvertiser?.advertiserName || '',
        id: loginUserDetails?.tblUser?.tblAdvertiser?.id || 0,
      };
      dispatch(setAdvertisers([advertiserDetails]));
    }
  }, []);

  const isLoading = rolesStatus === 'loading';

  return (
    <div>
      <Typography variant='h2' mb={5}>
        Set User Role
      </Typography>
      <Typography variant='body1' mb={10} color={grey[500]}>
        All team members will be able to view and pay invoices. Managers can also manage team
        members access to individual accounts.
      </Typography>
      {isLoading && <ComponentOverlayLoader />}
      <SpectrumSelectField
        required
        label='Role'
        variant='outlined'
        size='medium'
        name='role'
        onChange={handleChange}
        value={userDetails?.role}
        displayEmpty
      >
        <MenuItem value='' disabled>
          Select
        </MenuItem>
        {filteredRoles.map((role) => (
          <MenuItem key={role?.id} value={role?.id}>
            {role?.name}
          </MenuItem>
        ))}
      </SpectrumSelectField>
      {rolesError && (
        <Alert severity='error' variant='outlined' sx={{ mt: 10, mb: 10 }}>
          <Typography variant='paragraph2' component='span' color='error'>
            {rolesError}
          </Typography>
        </Alert>
      )}
      <Box sx={{ display: 'flex', mt: 15 }}>
        <Button sx={{ mr: 5 }} onClick={handlePrevious} variant='outlined' size='large' fullWidth>
          Back
        </Button>
        <Button
          sx={{ ml: 5 }}
          onClick={handleNext}
          variant='contained'
          size='large'
          fullWidth
          disabled={!userDetails?.role}
        >
          Add to Account
        </Button>
      </Box>
    </div>
  );
};
export default SelectRoleStep;
