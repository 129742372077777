import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import { PATHS } from '../../routes/Routes';
import CustomerSupportFooter from './CustomerSupportFooter';
import CancelButton from '../../components/CancelButton/CancelButton';
import { PaymentFlowType } from '../../redux/overview/OverviewModels';
import { scrollToTop } from '../../utils/common';
import useConfirmPageReload from '../../routes/ConfirmPageReload';

interface IMakePaymentInfoProps {
  type: PaymentFlowType;
}
const MakePaymentInfo = ({ type }: IMakePaymentInfoProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const primary: ColorPartial = theme.palette.primary as ColorPartial;

  const handleCancel = () => {
    navigate(PATHS.OVERVIEW);
  };

  const isInvoice = type === PaymentFlowType.Invoices;

  useEffect(() => {
    scrollToTop();
  }, []);

  useConfirmPageReload(true);

  return (
    <PageContentContainer>
      <Stack spacing={theme.spacing(8)}>
        <Card
          sx={{
            border: `1px solid ${grey[200]}`,
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              padding: 0,
            }}
          >
            <Box sx={{ p: theme.spacing(0, 16) }}>
              <Stack gap={theme.spacing(12)}>
                <Box pt={theme.spacing(12)}>
                  <Typography variant='eyebrow'>Make a Payment</Typography>
                  <Typography variant='h2' color={primary[800]}>
                    Paying {type}
                  </Typography>
                </Box>
                <Typography variant='paragraph2' color={theme.palette.common.black}>
                  {isInvoice
                    ? 'In the following steps, you will be able to pay for your selected invoices. If you have a credit balance, you will be able to apply some or all of the balance before making your payment.'
                    : 'In the following steps, you will be able to pay for your selected orders. If you have a credit balance, you will be able to apply some or all of the balance before making your payment.'}
                </Typography>
                <Box
                  display='flex'
                  flexDirection={isMobile ? 'column' : 'row'}
                  gap={theme.spacing(4)}
                >
                  <NavLink to={isInvoice ? PATHS.MAKE_INVOICE_PAYMENT : PATHS.MAKE_ORDER_PAYMENT}>
                    <Button
                      variant='contained'
                      size='large'
                      sx={{
                        width: !isMobile ? 'fit-content' : '100%',
                        padding: theme.spacing(9, isMobile ? 16 : 24),
                      }}
                    >
                      Continue
                    </Button>
                  </NavLink>
                </Box>
              </Stack>
              <CustomerSupportFooter />
            </Box>
          </CardContent>
        </Card>
        <CancelButton onCancel={handleCancel} />
      </Stack>
    </PageContentContainer>
  );
};

export default MakePaymentInfo;
