import { styled, tableCellClasses } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { ColorPartial } from '@mui/material/styles/createPalette';
import TableContainer from '@mui/material/TableContainer';

export const StyledTableWrapper = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});

export const StyledTableContainer = styled(TableContainer)(({ theme }) => {
  return {
    borderRadius: theme.spacing(2),
    border: 'none',
    boxShadow: 'none',
  };
});

export const StyledTableCell = styled(TableCell)(({ theme, sx }: any) => {
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      fontSize: 16,
      '.MuiTableSortLabel-root': {
        width: '100%',
        display: 'flex',
        gap: theme.spacing(3),
      },
    },
    [`&.${tableCellClasses.body}`]: {
      padding: theme.spacing(4, 8),
      fontSize: sx?.fontSize ?? 16,
      fontWeight: sx?.fontWeight ?? 400,
      borderColor: black[100],
    },
  };
});
export const StyledSortIcon = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    height: theme.spacing(13),
    width: theme.spacing(10),
    '.asc-icon': {
      fontSize: '1.2rem',
      top: 0,
      position: 'absolute',
    },
    '.desc-icon': {
      fontSize: '1.2rem',
      top: '8px',
      position: 'absolute',
    },
  };
});
