import React, { useState } from 'react';
import { Alert, Box, Grid, Switch, Typography, useTheme } from '@mui/material';
import { IInvoiceAndOrderListItem } from '../../../redux/InvoicesAndOrders/InvoicesAndOrderModels';
import { ColorPartial } from '@mui/material/styles/createPalette';
import {
  changeAdvertiserAccess,
  fetchInvoiceAndOrdersList,
} from '../../../redux/InvoicesAndOrders/InvoicesAndOrderSlice';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';

const InvoiceAndOrderListItem = ({ item }: { item: IInvoiceAndOrderListItem }) => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const [changeError, setChangeError] = useState('');

  const { filters } = useSelector((state: RootState) => state.invoiceAndOrdersData);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeError('');
    changeAdvertiserAccess({ advertiserId: item.advertiserPKId, hasAccess: event.target.checked })
      .then((result) => {
        dispatch(fetchInvoiceAndOrdersList(filters));
      })
      .catch((error) => {
        setChangeError(error?.error || 'Failed to change advertiser access');
      });
  };

  return (
    <div>
      <Box pt={6} pb={6}>
        <Grid container justifyContent='space-between'>
          <Grid item laptop={11}>
            <Typography variant='h6' mb={4}>
              {item?.advertiserName}
            </Typography>
            <Typography variant='body2' color={grey[500]}>
              {item?.openInvoices} Open Invoices, {item?.openOrders} open Orders
            </Typography>
          </Grid>
          <Grid item laptop={1}>
            <Switch
              checked={item?.hasAccess}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'enable-invoice-order-sharing' }}
            />
          </Grid>
        </Grid>
      </Box>
      {changeError && (
        <Alert severity='error' variant='outlined' sx={{ mt: 10, mb: 10 }}>
          <Typography variant='paragraph2' component='span' color='error'>
            {changeError}
          </Typography>
        </Alert>
      )}
    </div>
  );
};
export default InvoiceAndOrderListItem;
