import React from 'react';
import { Box, Typography } from '@mui/material';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import InvoicesAndOrdersList from './InvoicesAndOrdersHistory/InvoicesAndOrdersList';

const InvoicesAndOrders = () => {
  return (
    <PageContentContainer>
      <Typography variant='eyebrow'>PAID INVOICES & ORDERS</Typography>
      <Typography variant='h4'>History</Typography>
      <Typography variant='caption'>
        Invoices and orders may take up to 24 hours to appear after payment
      </Typography>
      <Box sx={{ mt: 6 }}>
        <InvoicesAndOrdersList />
      </Box>
    </PageContentContainer>
  );
};

export default InvoicesAndOrders;
