import React from 'react';
import Member from './Member';
import { Stack } from '@mui/material';
import { IUserAccountDetail } from '../../../redux/account/AccountModels';

interface IMembersProps {
  members: IUserAccountDetail[];
}

const Members = ({ members = [] }: IMembersProps) => {
  return (
    <Stack spacing={10}>
      {members.map((member, index) => {
        return <Member key={index} data={{ ...member }} />;
      })}
    </Stack>
  );
};
export default Members;
