import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { useMediaQuery } from '@mui/material';

export const StyledSpectrumDialog = styled(Dialog)(({ theme }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return {
    '& .MuiDialog-paper': isMobile
      ? {
          height: '100%',
          width: '100%',
          margin: 'unset',
          maxWidth: 'unset',
          maxHeight: 'unset',
        }
      : {
          width: 600,
        },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  };
});
