import React from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import { Box, Typography } from '@mui/material';

import { CARD, EFT } from '../../constants/common';

import { IPaymentMethod } from '../../redux/autoPay/AutoPayModel';

interface IProps {
  type: string;
  data: IPaymentMethod;
}

const MethodDescriptionAsPerBrand = ({ type, data }: IProps) => {
  switch (type) {
    case CARD:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PaymentIcon color='inherit' fontSize='medium' />
          <Typography variant='h6' ml={4} mr={4}>
            {data.type} .. {data.lastFourNumbers}
          </Typography>
          <Typography>Exp {data.expirationDate}</Typography>
        </Box>
      );
    case EFT:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccountBalanceIcon color='inherit' fontSize='medium' />
          <Typography variant='h6' ml={4} mr={4}>
            {data.type} .. {data.lastFourNumbers}
          </Typography>
        </Box>
      );
    default:
      return null;
  }
};

export default MethodDescriptionAsPerBrand;
