import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../redux/Store';
import { isUserAuthenticated } from '../utils/authUtils';
import { PATHS } from './Routes';
import { PageLoader } from '../components/Loaders/Loader';
import CompleteRegistration from '../pages/Auth/CompleteRegistration';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import RegistrationConfirmation from '../pages/Auth/RegistrationConfirmation';
import ReviewRequest from '../pages/ReviewRequest/ReviewRequest';
import InActiveUser from '../pages/Auth/InActiveUser';
import { UserStatusTypes } from '../redux/auth/AuthModels';

/** Determine If Route is Authenticated and Completed Profile*/
const ProtectedRoute = ({ children }: any) => {
  // For better performance and Memoization created separate selectors
  const userDetailsStatus = useSelector((state: RootState) => state.authData.userDetailsStatus);
  const userDetails = useSelector((state: RootState) => state.authData.userDetails);
  const userDetailsError = useSelector((state: RootState) => state.authData.userDetailsError);

  const isUserDetailsFetching = userDetailsStatus === 'loading' || userDetailsStatus === 'idle';
  const isAuthenticated = isUserAuthenticated();
  if (isAuthenticated) {
    if (isUserDetailsFetching) {
      return <PageLoader />;
    }
    if (userDetailsError) {
      return <ErrorPage title='Something went wrong' message={userDetailsError} />;
    }
    if (userDetails?.tblUser?.userStatusId === 1) {
      return <RegistrationConfirmation />;
    }

    if (userDetails?.tblUser) {
      if (
        userDetails.tblUser.tblUserStatus.name === 'NeedsReview' ||
        userDetails.tblUser.tblUserStatus.name === 'Pending'
      ) {
        return <ReviewRequest isUnderReview={true} />;
      }
    } else {
      if (userDetails?.isWaitingForActivation) {
        return <CompleteRegistration />;
      } else {
        return <ReviewRequest isUnderReview={false} />;
      }
    }

    if (userDetails?.tblUser?.tblUserStatus?.name === UserStatusTypes.Inactive) {
      return <InActiveUser />;
    }

    return children;
  } else {
    return <Navigate to={PATHS.LOGIN} />;
  }
};

export default ProtectedRoute;
