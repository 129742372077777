import React from 'react';
import { LoaderContainer } from './LoaderStyles';
import { CircularProgress } from '@mui/material';

export function PageLoader() {
  return (
    <LoaderContainer id='loader' className='pageLoader'>
      <CircularProgress size='3rem' className='pageLoaderImg' />
    </LoaderContainer>
  );
}

export function ComponentLoader() {
  return (
    <LoaderContainer data-testid='loader' id='loader' className='componentLoader'>
      <CircularProgress className='componentLoaderImg' />
    </LoaderContainer>
  );
}

export function ComponentOverlayLoader() {
  return (
    <LoaderContainer data-testid='loader' id='loader' className='componentLoader overlay'>
      <CircularProgress className='componentLoaderImg' />
    </LoaderContainer>
  );
}
