import { ISelectedInvoiceOrOrderItem } from '../redux/overview/OverviewModels';

export const calculateTotalReduceFn = (
  details: { count: number; total: number },
  selectedItem: ISelectedInvoiceOrOrderItem,
) => {
  let itemTotal = 0;
  const itemCount = selectedItem?.items?.length || 0;
  selectedItem.items.forEach((item) => {
    itemTotal = selectedItem?.isCustomAmount
      ? (item.customAmount || 0) + itemTotal
      : item.amount + itemTotal;
  });
  return { total: details.total + itemTotal, count: details.count + itemCount };
};
export const getSelectedItemTotal = (data: ISelectedInvoiceOrOrderItem[]) => {
  return data.reduce(calculateTotalReduceFn, {
    count: 0,
    total: 0,
  });
};
