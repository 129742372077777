import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { IconButton } from '@mui/material';

export const StyledLinksContainer = styled('div')(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(3, 8),
    '.links-container': {
      flexGrow: 1,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      '.link': {
        display: 'inline-block',
        textDecoration: 'none',
        margin: theme.spacing(5, 0),
        outline: 'none',
        textTransform: 'capitalize',
        color: black[500],
        '&.child-link': {
          fontSize: '14px',
        },
        '&.link-active': {
          color: primary[200],
        },
      },
    },
  };
});
export const StyledIconButton = styled(IconButton)(({ theme }) => {
  const white: ColorPartial = theme.palette.white as ColorPartial;
  return {
    color: white[500],
    minHeight: 75,
  };
});
