import { styled } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledSelectedInvoicesContainer = styled('div')(({ theme }) => {
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  return {
    '.header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(8),
      '&.align-left': {
        justifyContent: 'flex-start',
      },
      '.selected': {
        backgroundColor: primary[500],
        color: theme.palette.common.white,
        borderRadius: '100%',
        width: theme.spacing(13),
        height: theme.spacing(13),
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    '.invoices-container': {
      maxHeight: 700,
      overflow: 'auto',
    },
    '.item': {
      padding: theme.spacing(8),
      margin: theme.spacing(8, 0),
      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
      '.title': {
        paddingBottom: theme.spacing(5),
      },
      '.sub-item': {
        '&:not(:first-child)': {
          borderTop: `1px dashed ${grey[400]}`,
        },
        padding: theme.spacing(4, 0),
      },
      '.more-items': {
        padding: theme.spacing(2, 0, 4, 0),
        fontWeight: 500,
        cursor: 'pointer',
      },
      '.item-details': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '.total': {
        borderTop: `1px solid ${grey[400]}`,
        padding: theme.spacing(5, 0),
      },
    },
  };
});
