import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Typography, useTheme } from '@mui/material';

import YourInformation from './YourInformation';
import TermsAndConditions from './TermsAndConditions';
import IntroComponent from './IntroComponent';
import HelpText from './HelpText';
import { signupNewUser } from '../../redux/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { INewUserRegistrationDetails } from '../../redux/auth/AuthModels';

const ReviewRequestSteps = () => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const { signupNewUserStatus, signupNewUserError } = useSelector(
    (state: RootState) => state.authData,
  );

  const [formData, setFormData] = useState<INewUserRegistrationDetails>({
    firstName: '',
    lastName: '',
    customerId: '',
    companyName: '',
    companyType: '',
    accountExecutive: '',
    recentInvoiceNumber: '',
    isTermsAndConditionsAccepted: false,
  });

  const requiredFields: Array<keyof INewUserRegistrationDetails> = [
    'firstName',
    'lastName',
    'companyName',
    'accountExecutive',
    'customerId',
    'isTermsAndConditionsAccepted',
  ];

  const isAllReqFieldsFilled = requiredFields.reduce((isValid, key) => {
    return isValid && !!formData[key];
  }, true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    let value: any = event.target.value;
    if (name === 'worksWithOtherAgencies') {
      value = value === 'yes';
    } else if (name === 'isTermsAndConditionsAccepted') {
      value = event.target.checked;
    }
    setFormData((prv) => ({ ...prv, [name]: value }));
  };

  const handleSubmit = () => {
    dispatch(signupNewUser(formData));
  };

  useEffect(() => {
    if (signupNewUserStatus === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [signupNewUserStatus]);

  return (
    <Box>
      <IntroComponent />
      <YourInformation formData={formData} handleChange={handleChange} />
      <TermsAndConditions formData={formData} handleChange={handleChange} />
      {signupNewUserError && (
        <Typography mt={10} mb={10} variant='paragraph2' color='error'>
          {signupNewUserError}
        </Typography>
      )}
      <Button
        variant='contained'
        size='large'
        onClick={handleSubmit}
        disabled={!isAllReqFieldsFilled}
      >
        {isLoading ? (
          <CircularProgress
            size={28}
            style={{ color: theme.palette.common.white, marginRight: 10 }}
            className='loader'
          />
        ) : (
          'Submit for Review'
        )}
      </Button>
      <Box
        marginTop={theme.spacing(16)}
        display='flex'
        flexDirection='column'
        gap={theme.spacing(8)}
      >
        <Divider />
        <HelpText />
      </Box>
    </Box>
  );
};

export default ReviewRequestSteps;
