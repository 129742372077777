import React, { useState } from 'react';
import { Box, Stepper, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { StyledStep, StyledStepConnector } from '../../SetUpAutoPay/SetUpAutoPayStyles';
import EmailStep from './EmailStep';
import SelectRoleStep from './SelectRoleStep';
import AccountAccessStep from './AccountAccess';
import InviteSentConfirmation from './InviteSentConfirmation';
import { IInviteUserDetails } from '../../../redux/account/AccountModels';
import { Actions } from '../Members/MemberRoleMappings';

interface IInviteUserDetailsProps {
  handleClose: (data: { type: Actions }) => void;
}

const InviteUserDetails = ({ handleClose }: IInviteUserDetailsProps) => {
  const theme = useTheme();
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const [stage, setStage] = useState(1);
  const [userDetails, setUserDetails] = useState<IInviteUserDetails>({
    email: '',
    role: '',
    selectedAdvertisers: [],
  });
  const [userErrorDetails, setUserErrorDetails] = useState<IInviteUserDetails>({
    email: '',
    role: '',
    selectedAdvertisers: [],
  });

  const handleNext = () => {
    setStage(stage + 1);
  };
  const handlePrevious = () => {
    setStage(stage - 1);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserDetails({ ...userDetails, [name]: value });
    setUserErrorDetails({ ...userErrorDetails, [name]: '' });
  };

  return (
    <div>
      {stage !== 4 && (
        <Box sx={{ width: 290, mt: 10, mb: 10 }}>
          <Stepper activeStep={stage} connector={<StyledStepConnector />}>
            {[1, 2, 3, 4].map((step) => (
              <StyledStep key={step} />
            ))}
          </Stepper>
          <Typography variant='caption'>
            <Typography component='span' color={primary[500]} variant='caption'>
              {stage}
            </Typography>{' '}
            / 3
          </Typography>
        </Box>
      )}
      {stage === 1 && (
        <EmailStep
          userDetails={userDetails}
          userErrorDetails={userErrorDetails}
          setUserErrorDetails={setUserErrorDetails}
          handleChange={handleChange}
          handleNext={handleNext}
        />
      )}
      {stage === 2 && (
        <SelectRoleStep
          userDetails={userDetails}
          handleChange={handleChange}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      )}
      {stage === 3 && (
        <AccountAccessStep
          userDetails={userDetails}
          handleChange={handleChange}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      )}
      {stage === 4 && <InviteSentConfirmation handleClose={handleClose} />}
    </div>
  );
};
export default InviteUserDetails;
