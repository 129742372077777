import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const aboutUsLinks = [
  {
    label: 'FAQ',
    link: '/help',
  },
  {
    label: 'Careers',
    link: 'https://www.spectrumreach.com/careers',
  },
  {
    label: 'Contact Us',
    link: '/help',
  },
  {
    label: 'Leased Access',
    link: 'https://www.spectrumreach.com/leased-access',
  },
];
export const charterBrandsLinks = [
  {
    label: 'About Charter',
    link: 'https://corporate.charter.com/about-charter',
  },
  {
    label: 'Spectrum Residential',
    link: 'https://spectrum.com/',
  },
  {
    label: 'Spectrum Business',
    link: 'https://business.spectrum.com/',
  },
  {
    label: 'Spectrum Enterprise',
    link: 'https://enterprise.spectrum.com/',
  },
  {
    label: 'Spectrum Account',
    link: 'https://www.spectrum.net/',
  },
  {
    label: 'Spectrum Originals',
    link: 'https://spectrumoriginals.com/',
  },
];
export const socialIconLinks = [
  {
    label: 'Mail',
    icon: MailOutlineIcon,
    link: 'mailto:SRClientPortal@charter.com',
  },
  {
    label: 'facebook',
    icon: FacebookIcon,
    link: 'https://www.facebook.com/SpectrumReach/?ref=br_rs',
  },
  {
    label: 'twitter',
    icon: TwitterIcon,
    link: 'https://twitter.com/SpectrumReach',
  },
  {
    label: 'instagram',
    icon: InstagramIcon,
    link: 'https://www.instagram.com/spectrumreach/',
  },
  {
    label: 'linkedin',
    icon: LinkedInIcon,
    link: 'https://www.linkedin.com/company/spectrum-reach/',
  },
];
export const privacyLinks = [
  { label: 'Terms & Conditions', link: '/help' },
  { label: 'Your Privacy Rights', link: 'https://www.spectrum.com/policies/your-privacy-rights' },
];
export const californiaPrivacyLinks = [
  {
    label: 'Limit the Use of My Sensitive Personal Information',
    link: 'https://www.spectrum.com/policies/your-privacy-rights-opt-out',
  },
  {
    label: 'Do Not Sell or Share My Personal Information',
    link: 'https://spectrum.com/policies/your-privacy-rights-opt-out',
  },
  { label: 'Privacy Rights', link: 'https://www.spectrum.com/policies/california' },
];
