import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const IntroComponent = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        gap={theme.spacing(8)}
        marginBottom={theme.spacing(20)}
      >
        <Typography variant='eyebrow' marginBottom={0}>
          Welcome
        </Typography>
        <Typography variant='h4'>Connect Your Account</Typography>
        <Typography variant='body1'>
          Since this is your first time logging into Spectrum Reach Client Portal, we need some
          additional information to get you to the right team. Please fill out the information below
          for us to review your account.
        </Typography>
      </Box>
    </>
  );
};

export default IntroComponent;
