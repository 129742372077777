import React from 'react';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import { Alert, Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../routes/Routes';
import CustomerSupportFooter from '../MakePayment/CustomerSupportFooter';

const AutoPayConfirmationFailed = ({ error }: { error: string }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate(PATHS.OVERVIEW);
  };
  return (
    <PageContentContainer>
      <Box
        sx={{
          maxWidth: 616,
          margin: '0 auto',
        }}
      >
        <Typography variant='eyebrow' mt={theme.spacing(20)} color={theme.palette.error.main}>
          ISSUE
        </Typography>
        <Stack gap={theme.spacing(8)}>
          <Typography variant='h1'>Your Autopayment Could Not Be Scheduled</Typography>
          <Typography variant='paragraph1' color={theme.palette.common.black}>
            Your payment could not be processed for the following reason:
          </Typography>
          <Alert
            severity='error'
            variant='standard'
            sx={{
              borderLeft: `${theme.spacing(1)} solid ${theme.palette.error.main}`,
              alignItems: 'center',
            }}
          >
            <Typography variant='body1' color={theme.palette.common.black}>
              {error}
            </Typography>
          </Alert>
          <CustomerSupportFooter msg="Have any questions? Contact us at" />
        </Stack>
        <Box>
          <Button
            variant='text'
            onClick={handleGoHome}
            sx={{
              width: 'fit-content',
              minWidth: 'auto',
              mt: 12,
            }}
          >
            Back to Home
          </Button>
        </Box>
      </Box>
    </PageContentContainer>
  );
};
export default AutoPayConfirmationFailed;
