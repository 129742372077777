import {
  IAdvertiserChangeAccessPayload,
  IInvoiceAndOrderSharingFilters,
  IInvoiceAndOrdersState,
  IInvoicesAndOrdersResponse,
  IMakePaymentInvoices,
  IMakePaymentOrders,
} from './InvoicesAndOrderModels';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../services/ApiService';
import { invoiceAndOrderEndPoints } from '../../constants/apiConstants';
import { IAPIResponseError } from '../autoPay/AutoPayModel';

const initialState: IInvoiceAndOrdersState = {
  invoiceAndOrdersList: {} as IInvoicesAndOrdersResponse,
  invoiceAndOrdersListStatus: 'idle',
  invoiceAndOrdersListError: null,
  makePaymentStatus: 'idle',
  makePaymentError: null,
  makePaymentResult: '',
  filters: {
    start: 0,
    length: 10,
    searchValue: '',
    sortColumn: 'AdvertiserName',
    sortDirection: 'asc',
    jumpTo: '',
  },
};
export const fetchInvoiceAndOrdersList = createAsyncThunk(
  'account/FetchInvoiceAndOrdersList',
  async (payload: IInvoiceAndOrderSharingFilters) => {
    const response = await ApiService.postData(
      invoiceAndOrderEndPoints.getInvoiceAndOrdersList,
      {},
      payload,
    );
    return response.data;
  },
);

export const changeAdvertiserAccess = (payload: IAdvertiserChangeAccessPayload) => {
  return ApiService.postData(invoiceAndOrderEndPoints.changeAdvertiserAccessURL, {}, payload);
};

export const makeAgencyInvoiceImmediatePayment = createAsyncThunk(
  'invoices/MakeAgencyInvoiceImmediatePayment',
  async (payload: IMakePaymentInvoices, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        invoiceAndOrderEndPoints.makeAgencyInvoicesPaymentURL,
        {},
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const makeAgencyInvoiceSchedulePayment = createAsyncThunk(
  'invoices/MakeAgencyInvoiceSchedulePayment',
  async (payload: IMakePaymentInvoices, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        invoiceAndOrderEndPoints.makeAgencyInvoicesPaymentURL,
        {},
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const makeAgencyOrderImmediatePayment = createAsyncThunk(
  'orders/MakeAgencyOrderImmediatePayment',
  async (payload: IMakePaymentOrders, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        invoiceAndOrderEndPoints.makeAgencyOrdersPaymentURL,
        {},
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const makeAgencyOrderSchedulePayment = createAsyncThunk(
  'orders/MakeAgencyOrderSchedulePayment',
  async (payload: IMakePaymentOrders, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        invoiceAndOrderEndPoints.makeAgencyOrdersPaymentURL,
        {},
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const makeAdvertiserInvoiceImmediatePayment = createAsyncThunk(
  'invoices/MakeAdvertiserInvoiceImmediatePayment',
  async (payload: IMakePaymentInvoices, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        invoiceAndOrderEndPoints.makeAdvertiserInvoicesPaymentURL,
        {},
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const makeAdvertiserInvoiceSchedulePayment = createAsyncThunk(
  'invoices/MakeAdvertiserInvoiceSchedulePayment',
  async (payload: IMakePaymentInvoices, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        invoiceAndOrderEndPoints.makeAdvertiserInvoicesPaymentURL,
        {},
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const makeAdvertiserOrderImmediatePayment = createAsyncThunk(
  'orders/MakeAdvertiserOrderImmediatePayment',
  async (payload: IMakePaymentOrders, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        invoiceAndOrderEndPoints.makeAdvertiserOrdersPaymentURL,
        {},
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const makeAdvertiserOrderSchedulePayment = createAsyncThunk(
  'orders/MakeAdvertiserOrderSchedulePayment',
  async (payload: IMakePaymentOrders, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        invoiceAndOrderEndPoints.makeAdvertiserOrdersPaymentURL,
        {},
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const InvoiceAndOrdersSlice = createSlice({
  name: 'invoiceAndOrdersData',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action?.payload;
    },
    resetMakePaymentStatus: (state) => {
      state.makePaymentResult = null;
      state.makePaymentStatus = 'idle';
      state.makePaymentError = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInvoiceAndOrdersList.pending, (state) => {
        state.invoiceAndOrdersListError = '';
        state.invoiceAndOrdersListStatus = 'loading';
      })
      .addCase(fetchInvoiceAndOrdersList.fulfilled, (state, action) => {
        state.invoiceAndOrdersListStatus = 'succeeded';
        state.invoiceAndOrdersList = action.payload.data;
      })
      .addCase(fetchInvoiceAndOrdersList.rejected, (state, action) => {
        state.invoiceAndOrdersListStatus = 'failed';
        state.invoiceAndOrdersListError =
          action?.error?.message || 'Failed to get invoice and orders list';
      })
      //Agency Invoice Immediate
      .addCase(makeAgencyInvoiceImmediatePayment.pending, (state) => {
        state.makePaymentError = '';
        state.makePaymentStatus = 'loading';
      })
      .addCase(makeAgencyInvoiceImmediatePayment.fulfilled, (state, action) => {
        state.makePaymentStatus = 'succeeded';
        state.makePaymentResult = action.payload.data;
      })
      .addCase(makeAgencyInvoiceImmediatePayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.makePaymentStatus = 'failed';
        state.makePaymentError = response?.error || response?.title || 'Failed to make payment';
      })
      //Agency Invoice Schedule
      .addCase(makeAgencyInvoiceSchedulePayment.pending, (state) => {
        state.makePaymentError = '';
        state.makePaymentStatus = 'loading';
      })
      .addCase(makeAgencyInvoiceSchedulePayment.fulfilled, (state, action) => {
        state.makePaymentStatus = 'succeeded';
        state.makePaymentResult = action.payload.data;
      })
      .addCase(makeAgencyInvoiceSchedulePayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.makePaymentStatus = 'failed';
        state.makePaymentError = response?.error || response?.title || 'Failed to make payment';
      })
      //Advertiser Invoice Immediate
      .addCase(makeAdvertiserInvoiceImmediatePayment.pending, (state) => {
        state.makePaymentError = '';
        state.makePaymentStatus = 'loading';
      })
      .addCase(makeAdvertiserInvoiceImmediatePayment.fulfilled, (state, action) => {
        state.makePaymentStatus = 'succeeded';
        state.makePaymentResult = action.payload.data;
      })
      .addCase(makeAdvertiserInvoiceImmediatePayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.makePaymentStatus = 'failed';
        state.makePaymentError = response?.error || response?.title || 'Failed to make payment';
      })
      //Advertiser Invoice Schedule
      .addCase(makeAdvertiserInvoiceSchedulePayment.pending, (state) => {
        state.makePaymentError = '';
        state.makePaymentStatus = 'loading';
      })
      .addCase(makeAdvertiserInvoiceSchedulePayment.fulfilled, (state, action) => {
        state.makePaymentStatus = 'succeeded';
        state.makePaymentResult = action.payload.data;
      })
      .addCase(makeAdvertiserInvoiceSchedulePayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.makePaymentStatus = 'failed';
        state.makePaymentError = response?.error || response?.title || 'Failed to make payment';
      })

      //Agency Orders Immediate
      .addCase(makeAgencyOrderImmediatePayment.pending, (state) => {
        state.makePaymentError = '';
        state.makePaymentStatus = 'loading';
      })
      .addCase(makeAgencyOrderImmediatePayment.fulfilled, (state, action) => {
        state.makePaymentStatus = 'succeeded';
        state.makePaymentResult = action.payload.data;
      })
      .addCase(makeAgencyOrderImmediatePayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.makePaymentStatus = 'failed';
        state.makePaymentError = response?.error || response?.title || 'Failed to make payment';
      })
      //Agency Order Schedule
      .addCase(makeAgencyOrderSchedulePayment.pending, (state) => {
        state.makePaymentError = '';
        state.makePaymentStatus = 'loading';
      })
      .addCase(makeAgencyOrderSchedulePayment.fulfilled, (state, action) => {
        state.makePaymentStatus = 'succeeded';
        state.makePaymentResult = action.payload.data;
      })
      .addCase(makeAgencyOrderSchedulePayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.makePaymentStatus = 'failed';
        state.makePaymentError = response?.error || response?.title || 'Failed to make payment';
      })
      //Advertiser Order Immediate
      .addCase(makeAdvertiserOrderImmediatePayment.pending, (state) => {
        state.makePaymentError = '';
        state.makePaymentStatus = 'loading';
      })
      .addCase(makeAdvertiserOrderImmediatePayment.fulfilled, (state, action) => {
        state.makePaymentStatus = 'succeeded';
        state.makePaymentResult = action.payload.data;
      })
      .addCase(makeAdvertiserOrderImmediatePayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.makePaymentStatus = 'failed';
        state.makePaymentError = response?.error || response?.title || 'Failed to make payment';
      })
      //Advertiser Order Schedule
      .addCase(makeAdvertiserOrderSchedulePayment.pending, (state) => {
        state.makePaymentError = '';
        state.makePaymentStatus = 'loading';
      })
      .addCase(makeAdvertiserOrderSchedulePayment.fulfilled, (state, action) => {
        state.makePaymentStatus = 'succeeded';
        state.makePaymentResult = action.payload.data;
      })
      .addCase(makeAdvertiserOrderSchedulePayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.makePaymentStatus = 'failed';
        state.makePaymentError = response?.error || response?.title || 'Failed to make payment';
      });
  },
});
export const { updateFilters, resetMakePaymentStatus } = InvoiceAndOrdersSlice.actions;

export default InvoiceAndOrdersSlice.reducer;
