import { ColorPartial } from '@mui/material/styles/createPalette';
import { TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTablePagination = styled(TablePagination)(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    color: black[50],
    border: 'none',
    '.MuiPaginationItem-root': {
      color: black[50],
      '&.Mui-selected': {
        backgroundColor: primary[500],
        color: white[500],
        '&:hover': {
          backgroundColor: primary[500],
          color: white[500],
        },
      },
    },
    '.MuiTablePagination-selectLabel': {
      marginRight: theme.spacing(4),
      fontSize: 16,
      textTransform: 'capitalize',
    },
    '.MuiInputBase-root': {
      fontSize: 16,
      marginRight: theme.spacing(4),
    },
    '.MuiTablePagination-select': {
      fontWeight: 500,
    },
    '.MuiTablePagination-displayedRows': {
      fontSize: 16,
      fontWeight: 500,
      '&::after': {
        fontWeight: 400,
        content: '" results"',
      },
    },
    '.MuiTablePagination-actions': {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '.MuiTablePagination-spacer': {
      display: 'none',
    },
  };
});
