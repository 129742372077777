export enum Roles {
  ACCOUNT_OWNER = 'Account Owner',
  MANAGER = 'Manager',
  TEAM_MEMBER = 'Team Member',
}
export enum AccountStatus {
  Pending = 'Pending',
}
export enum Actions {
  TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP',
  MANAGE_ACCOUNT_ACCESS = 'MANAGE_ACCOUNT_ACCESS',
  CHANGE_USER_ROLE = 'CHANGE_USER_ROLE',
  REMOVE_USER = 'REMOVE_USER',
  INVITE_USER = 'INVITE_USER',
}
export const MemberColors = {
  [Roles.ACCOUNT_OWNER]: '#00938C',
  [Roles.MANAGER]: '#0077AE',
  [Roles.TEAM_MEMBER]: '#0077AE',
  [AccountStatus.Pending]: '#63738A',
};
export const GetAllowedActions = (loginAs: Roles, memberRole: Roles): Actions[] => {
  if (loginAs === Roles.ACCOUNT_OWNER) {
    if (memberRole === Roles.ACCOUNT_OWNER) {
      return [Actions.TRANSFER_OWNERSHIP];
    }
    if (memberRole === Roles.TEAM_MEMBER || memberRole === Roles.MANAGER) {
      return [Actions.MANAGE_ACCOUNT_ACCESS, Actions.CHANGE_USER_ROLE, Actions.REMOVE_USER];
    }
  } else if (loginAs === Roles.MANAGER) {
    if (memberRole === Roles.ACCOUNT_OWNER || memberRole === Roles.MANAGER) {
      return [];
    }
    if (memberRole === Roles.TEAM_MEMBER) {
      return [Actions.MANAGE_ACCOUNT_ACCESS];
    }
  } else if (loginAs === Roles.TEAM_MEMBER) {
    return [];
  }
  return [];
};
