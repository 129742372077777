import * as React from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function SpectrumSearch({ ...rest }: Omit<TextFieldProps, 'variant'>) {
  return (
    <TextField
      className='rounded'
      size='medium'
      {...rest}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
