import React, { useEffect } from 'react';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../routes/Routes';
import {
  formatAmount,
  formatDate,
  getLocalDateWithOffset,
  sameDay,
  scrollToTop,
} from '../../utils/common';
import { IPaymentMethod } from '../../redux/autoPay/AutoPayModel';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { ISelectedInvoiceOrOrderItem, PaymentFlowType } from '../../redux/overview/OverviewModels';
import CustomerSupportFooter from './CustomerSupportFooter';

const SelectedInvoiceOrOrder = ({
  title = '',
  items,
  selectedPaymentFlowType,
  isCustomAmount,
}: {
  title: string;
  selectedPaymentFlowType: PaymentFlowType;
  items: Array<{ number: string; customAmount: number; amount: number; dueDate: string }>;
  isCustomAmount: boolean;
}) => {
  const theme = useTheme();
  const prefix = selectedPaymentFlowType === PaymentFlowType.Invoices ? 'Inv #' : 'Ord #';
  return (
    <Box mt={10} mb={10}>
      <Typography variant='h6'>{title}</Typography>
      <Stack gap={3} mt={5} mb={5}>
        {items.map((item, index) => (
          <div key={index}>
            <Typography color={theme.palette.grey[500]}>
              {prefix}
              {item?.number} - {formatAmount(isCustomAmount ? item?.customAmount : item?.amount)}
            </Typography>
          </div>
        ))}
      </Stack>
    </Box>
  );
};

const MakePaymentConfirmation = ({
  payOn,
  selectedPaymentMethodDetail,
  totalAmount,
}: {
  payOn: any;
  totalAmount: number;
  selectedPaymentMethodDetail?: IPaymentMethod;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { contactInfo } = useSelector((state: RootState) => state.helpData);
  const { selectedPaymentFlowType } = useSelector((state: RootState) => state.paymentData);
  const isInvoice = selectedPaymentFlowType === PaymentFlowType.Invoices;

  const { selectedInvoices, selectedOrders } = useSelector((state: RootState) => state.overview);

  const selectedItemsData: ISelectedInvoiceOrOrderItem[] = isInvoice
    ? selectedInvoices
    : selectedOrders;

  const handleGoHome = () => {
    navigate(PATHS.OVERVIEW);
  };

  const formattedAmount = formatAmount(totalAmount);
  const formattedDate = payOn && formatDate(payOn);
  const isPayDateToday = sameDay(payOn, getLocalDateWithOffset());

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <PageContentContainer>
      <Typography variant='eyebrow' mt={20} mb={5}>
        Success
      </Typography>
      <Typography variant='h1' mb={8}>
        {isPayDateToday ? 'Thank you for your payment!' : 'Your payment has been scheduled'}
      </Typography>
      <Typography variant='paragraph1' mb={8} color={theme.palette.common.black}>
        {isPayDateToday
          ? 'Your payment has been received and will be reflected on your account within 1-3 business days'
          : `Your payment has been scheduled and will be processed on ${formattedDate}.`}
      </Typography>
      <Card
        sx={{
          mb: 10,
          boxShadow: 4,
        }}
      >
        <CardContent>
          <Typography variant='eyebrow' mb={5}>
            PAYMENT DETAILS
          </Typography>
          <Box mb={5} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h3' mb={5}>
              {formattedAmount}
            </Typography>
            <Box display='flex' mb={5}>
              <Typography variant='h6' mr={4}>
                {selectedPaymentMethodDetail?.type}* {selectedPaymentMethodDetail?.lastFourNumbers}
              </Typography>
              to be paid on
              <Typography variant='h6' ml={4}>
                {formattedDate}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Typography variant='h5' mb={10} mt={10}>
              Paid {isInvoice ? 'Invoices' : 'Orders'}
            </Typography>
            {selectedItemsData.map((item, i) => (
              <SelectedInvoiceOrOrder
                key={i}
                selectedPaymentFlowType={selectedPaymentFlowType}
                title={item?.advertiserOrAgencyName}
                items={item?.items}
                isCustomAmount={!!item?.isCustomAmount}
              />
            ))}
          </Box>
        </CardContent>
      </Card>
      <CustomerSupportFooter msg="Have any questions? Contact us at" />
      <Button
        variant='text'
        onClick={handleGoHome}
        sx={{
          width: 'fit-content',
          minWidth: 'auto',
          mt: 12,
          mb: 12,
        }}
      >
        Back to Client Portal Home
      </Button>
    </PageContentContainer>
  );
};
export default MakePaymentConfirmation;
