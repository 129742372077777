import { IPaymentMethod } from '../../redux/autoPay/AutoPayModel';
import { CARD, EFT } from '../../constants/common';
import PaymentIcon from '@mui/icons-material/Payment';
import { Box, Button, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import React from 'react';

const ReviewPaymentMethod = ({
  selectedPaymentMethodDetail,
  moveToStepLabel,
  onMoveToStep,
}: {
  selectedPaymentMethodDetail?: IPaymentMethod;
  moveToStepLabel: string;
  onMoveToStep: (step: number) => void;
}) => {
  const renderMethodDescriptionAsPerBrand = () => {
    const paymentMethod = selectedPaymentMethodDetail?.paymentMethod;
    switch (paymentMethod) {
      case CARD:
        return (
          <>
            <PaymentIcon color='primary' fontSize='small' />
            <Typography variant='body1'>
              {`${selectedPaymentMethodDetail?.type} (...${selectedPaymentMethodDetail?.lastFourNumbers})`}
            </Typography>
          </>
        );
      case EFT:
        return (
          <>
            <AccountBalanceIcon color='primary' fontSize='small' />
            <Typography variant='body1'>
              {selectedPaymentMethodDetail?.type} .. {selectedPaymentMethodDetail?.lastFourNumbers}
            </Typography>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Typography variant='h4'>Payment Method</Typography>
      <Box display='flex' gap={4} mt={8}>
        {renderMethodDescriptionAsPerBrand()}
      </Box>
      <Button
        variant='text'
        sx={{
          mt: 6,
          textDecoration: 'underline',
        }}
        onClick={() => onMoveToStep(3)}
      >
        {moveToStepLabel}
      </Button>
    </>
  );
};
export default ReviewPaymentMethod;