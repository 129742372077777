import {
  IAccountState,
  IAdvertiserResponse,
  IGetUserAccountInfoPayload,
  IInviteUserPayload,
  IManageAccountAccessPayload,
} from './AccountModels';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { accountApiEndPoints } from '../../constants/apiConstants';
import ApiService from '../../services/ApiService';

const initialState: IAccountState = {
  accountInformation: null,
  accountInformationStatus: 'idle',
  accountInformationError: '',
  accountAllUsers: [],
  accountAllUsersError: '',
  accountAllUsersStatus: 'idle',
  rolesStatus: 'idle',
  rolesError: '',
  roles: [],
  advertisersStatus: 'idle',
  advertisersError: '',
  advertisers: [],
  filters: {
    start: 0,
    length: 10,
    searchValue: '',
    sortColumn: '',
    sortDirection: '',
  },
};
export const fetchUserAccountInformation = createAsyncThunk(
  'account/FetchUserAccountInformation',
  async (payload: IGetUserAccountInfoPayload) => {
    const response = await ApiService.postData(
      accountApiEndPoints.getUserAccountInformation,
      {},
      payload,
    );
    return response.data;
  },
);
export const fetchAccountAllUsers = createAsyncThunk('account/fetchAccountAllUsers', async () => {
  const response = await ApiService.getData(accountApiEndPoints.getAccountAllUsers, {});
  return response.data;
});
export const transferAccountOwnership = (params: { transferToUserSSOId: string }) =>
  ApiService.getData(accountApiEndPoints.transferAccountOwnership, params);

export const removeUserFromAccount = (params: { userSSOId: string }) =>
  ApiService.getData(accountApiEndPoints.removeUser, params);

export const changeRole = (params: { changeUserSSOId: string; changeRoleTo: number }) =>
  ApiService.getData(accountApiEndPoints.changeRole, params);

export const inviteUser = (payload: IInviteUserPayload) =>
  ApiService.postData(accountApiEndPoints.inviteUser, {}, payload);

export const manageAccessAccount = (payload: IManageAccountAccessPayload) =>
  ApiService.postData(accountApiEndPoints.manageAccessAccount, {}, payload);

export const fetchRoles = createAsyncThunk(
  'account/fetchRoles',
  async (params: { includeAccountOwner: boolean }) => {
    const response = await ApiService.getData(accountApiEndPoints.getRoles, params);
    return response.data;
  },
);
export const fetchAdvertisers = createAsyncThunk(
  'account/fetchAdvertisers',
  async (params: { agencyId: any }) => {
    const response = await ApiService.getData(accountApiEndPoints.getAdvertisers, params);
    return response.data;
  },
);

export const AccountDataSlice = createSlice({
  name: 'accountData',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action?.payload;
    },
    setAdvertisers: (state, action) => {
      state.advertisers = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserAccountInformation.pending, (state) => {
        state.accountInformationStatus = 'loading';
      })
      .addCase(fetchUserAccountInformation.fulfilled, (state, action) => {
        state.accountInformationStatus = 'succeeded';
        state.accountInformation = action.payload.data;
      })
      .addCase(fetchUserAccountInformation.rejected, (state, action) => {
        state.accountInformationStatus = 'failed';
        state.accountInformationError =
          action?.error?.message || 'Failed to get user account information';
      })
      .addCase(fetchAccountAllUsers.pending, (state) => {
        state.accountAllUsersStatus = 'loading';
      })
      .addCase(fetchAccountAllUsers.fulfilled, (state, action) => {
        state.accountAllUsersStatus = 'succeeded';
        state.accountAllUsers = action.payload.data;
      })
      .addCase(fetchAccountAllUsers.rejected, (state, action) => {
        state.accountAllUsersStatus = 'failed';
        state.accountAllUsersError = action?.error?.message || 'Failed to get users';
      })
      .addCase(fetchRoles.pending, (state) => {
        state.rolesStatus = 'loading';
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.rolesStatus = 'succeeded';
        state.roles = action.payload.data;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.rolesStatus = 'failed';
        state.accountAllUsersError = action?.error?.message || 'Failed to get users';
      })
      .addCase(fetchAdvertisers.pending, (state) => {
        state.advertisersStatus = 'loading';
      })
      .addCase(fetchAdvertisers.fulfilled, (state, action) => {
        state.advertisersStatus = 'succeeded';
        const advertisers: IAdvertiserResponse[] = action.payload.data || [];
        state.advertisers = advertisers.map((advertiser) => advertiser?.tblAdvertiser);
      })
      .addCase(fetchAdvertisers.rejected, (state, action) => {
        state.advertisersStatus = 'failed';
        state.advertisersError = action?.error?.message || 'Failed to get advertisers';
      });
  },
});
export const { updateFilters, setAdvertisers } = AccountDataSlice.actions;

export default AccountDataSlice.reducer;
