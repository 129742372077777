import React from 'react';
import SelectedInvoicesAndOrders from '../SelectedInvoicesAndOrders/SelectedInvoicesAndOrders';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { StyledContainer } from './PaymentSummaryStyles';
import { formatAmount, formatDate, formatNumber } from '../../../utils/common';
import { InvoiceAndOrdersViewType } from '../../../redux/overview/OverviewModels';
import { StyledBoxContainer } from '../Steps/ReviewDetailStyles';

interface IPaymentSummaryProps {
  activeStep: number;
  payOn: any;
  totalAmount: number;
  count: number;
}

const SummaryTitle = ({ title }: any) => {
  const theme = useTheme();
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  return (
    <Typography
      color={primary[500]}
      variant='eyebrow'
      sx={{
        fontSize: '14px',
        paddingBottom: theme.spacing(4),
        mt: 8,
        mb: 8,
      }}
    >
      {title}
    </Typography>
  );
};

const PaymentAmount = ({ amount }: { amount: string }) => {
  return (
    <Box>
      <Divider />
      <SummaryTitle title='Payment Amount' />
      <Box mb={10}>
        <Typography variant='h6'>Your Payment</Typography>
        <Typography variant='body1'>{amount}</Typography>
      </Box>
    </Box>
  );
};

const PaymentDate = ({ payOn }: { payOn: string }) => {
  return (
    <Box>
      <Divider />
      <SummaryTitle title='Payment Date' />
      {payOn && formatDate(payOn)}
    </Box>
  );
};

const PaymentSummary = ({ activeStep, payOn, totalAmount, count }: IPaymentSummaryProps) => {
  const formattedAmount = formatAmount(totalAmount);
  const formattedValue = formatNumber(count);
  return (
    <StyledContainer>
      <Box sx={{ flexGrow: 1 }}>
        <StyledBoxContainer className='header'>
          <Typography variant='eyebrow' color='primary'>
            Selected
          </Typography>
          <Typography className='selected' variant='subtitle2'>
            {formattedValue}
          </Typography>
        </StyledBoxContainer>
        <SelectedInvoicesAndOrders viewType={InvoiceAndOrdersViewType.SUMMARY} />
        {activeStep > 1 && <PaymentAmount amount={formattedAmount} />}
        {activeStep > 2 && <PaymentDate payOn={payOn} />}
      </Box>
      <Divider />
      <Box className='footer'>
        <Typography variant='h6'>Payment Total</Typography>
        <Typography variant='h6'>{formattedAmount}</Typography>
      </Box>
    </StyledContainer>
  );
};
export default PaymentSummary;
