import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Collapse, TableSortLabel, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
  StyledSortIcon,
  StyledTableCell,
  StyledTableContainer,
  StyledTableWrapper,
} from './SpectrumTableStyles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { ComponentLoader } from '../../Loaders/Loader';
import TableCell from '@mui/material/TableCell';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { StyledTablePagination } from '../../Pagination/SpectrumPaginationStyles';

export declare type CellAlignType = 'inherit' | 'left' | 'center' | 'right' | 'justify';
export declare type Order = 'asc' | 'desc' | false;

export interface IColumn<T> {
  label: string;
  accessorKey: string;
  sortKey?: null | string;
  disablePadding?: boolean;
  width?: string;
  render?: (x: T) => React.ReactElement | string;
  fixed?: string;
  className?: string;
  headerClassName?: string;
  align?: CellAlignType;
  collapseRowTrigger?: boolean;
}

interface ISpectrumTableProps<T> {
  title?: string;
  columns: Array<IColumn<T>>;
  orderBy?: string;
  order?: Order;
  onSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  data: T[];
  currentPage: number;
  itemsPerPage: number;
  onPageChange?: (page: number) => void;
  onItemsPerPageChange?: (page: number) => void;
  totalItems: number;
  pageOptions?: number[];
  loading?: boolean;
  hidePagination?: boolean;
  collapseRowContent?: (row: T) => React.ReactNode;
}

interface ISortIcon {
  active: boolean;
  direction: 'desc' | 'asc';
}

export default function SpectrumTable<T>({
  columns,
  data,
  loading,
  totalItems,
  order,
  orderBy,
  onSort,
  itemsPerPage,
  currentPage,
  onPageChange,
  onItemsPerPageChange,
  collapseRowContent,
}: ISpectrumTableProps<T>) {
  const theme = useTheme();

  const [rowCollapse, setRowCollapse] = React.useState<any>({});

  const handleRowCollapse = (column: IColumn<T>, rowIndex: number) => () => {
    if (column?.collapseRowTrigger) {
      setRowCollapse({ ...rowCollapse, [rowIndex]: !rowCollapse[rowIndex] });
    }
  };

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    if (onSort && property) {
      onSort(event, property);
    }
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (onItemsPerPageChange) {
      onItemsPerPageChange(parseInt(event.target.value, 10));
    }
  };

  const SortIcon = ({ active, direction }: ISortIcon) => {
    const primary: ColorPartial = theme.palette.primary as ColorPartial;
    const isAsc = active && direction === 'asc';
    const isDesc = active && direction === 'desc';
    return (
      <StyledSortIcon>
        {
          <ArrowDropUpIcon
            className='asc-icon'
            sx={{ color: isAsc ? primary[500] : theme.palette.common.black }}
          />
        }
        {
          <ArrowDropDownIcon
            className='desc-icon'
            sx={{ color: isDesc ? primary[500] : theme.palette.common.black }}
          />
        }
      </StyledSortIcon>
    );
  };

  return (
    <StyledTableWrapper>
      <StyledTableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='spectrum table'>
          <TableHead>
            <TableRow>
              {columns.map((column, columnIndex: number) => (
                <StyledTableCell
                  key={`${column.sortKey}_${column.accessorKey}_${columnIndex}`}
                  align={column.align}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === column.sortKey ? order || false : false}
                  width={column.width}
                >
                  <TableSortLabel
                    onClick={createSortHandler(column.sortKey)}
                    IconComponent={() => {
                      return column.sortKey ? (
                        <SortIcon
                          active={orderBy === column.sortKey}
                          direction={order && orderBy === column.sortKey ? order : 'asc'}
                        />
                      ) : (
                        <Box sx={visuallyHidden}></Box>
                      );
                    }}
                  >
                    {column?.label}
                    {orderBy === column.sortKey ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <ComponentLoader />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableBody>
                {(data || []).map((row: any, rowIndex: number) => (
                  <React.Fragment key={rowIndex}>
                    <TableRow
                      sx={{
                        '&:last-child th, &:last-child td': {
                          borderBottom: 0,
                        },
                      }}
                    >
                      {columns.map((column, index) =>
                        column?.collapseRowTrigger ? (
                          <StyledTableCell
                            align={column?.align}
                            key={index}
                            onClick={handleRowCollapse(column, rowIndex)}
                          >
                            {rowCollapse[rowIndex] ? (
                              <RemoveCircleIcon color='primary' sx={{ cursor: 'pointer' }} />
                            ) : (
                              <AddCircleIcon color='primary' sx={{ cursor: 'pointer' }} />
                            )}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell align={column?.align} key={index}>
                            {column?.render ? column.render(row) : row[column?.accessorKey]}
                          </StyledTableCell>
                        ),
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={columns.length}>
                        <Collapse in={rowCollapse[rowIndex]} timeout='auto' unmountOnExit>
                          {collapseRowContent ? collapseRowContent(row) : ''}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
                {!data?.length && (
                  <TableRow>
                    <TableCell align='center' colSpan={columns.length}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          )}
        </Table>
      </StyledTableContainer>
      <table>
        <tfoot>
          <tr>
            <StyledTablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={columns.length}
              count={totalItems || 0}
              rowsPerPage={itemsPerPage ? Number(itemsPerPage) : 0}
              page={currentPage}
              labelRowsPerPage='Rows per page'
              SelectProps={{
                inputProps: {
                  'aria-label': 'Rows per page',
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </tr>
        </tfoot>
      </table>
    </StyledTableWrapper>
  );
}
