import React, { useState } from 'react';
import { Box, Button, Collapse, Stack, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { ColorPartial } from '@mui/material/styles/createPalette';
import AddIcon from '@mui/icons-material/Add';
import { formatAmount } from '../../utils/common';

const BalanceItem = ({ label, amount }: { label: string; amount: number }) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        padding: 6,
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: 1,
        border: `1px solid ${grey[400]}`,
      }}
    >
      <Typography variant='eyebrow'>{label}</Typography>
      <Typography variant='eyebrow'>{formatAmount(amount || 0)}</Typography>
    </Box>
  );
};

interface ICreditBalance {
  totalCreditBalance: number;
  totalCreditAdjustments: number;
  totalCreditPayments: number;
  totalCreditPrepayments: number;
  totalCreditInvoices: number;
}

const CreditBalance = ({
  totalCreditBalance,
  totalCreditAdjustments,
  totalCreditPrepayments,
  totalCreditPayments,
  totalCreditInvoices,
}: ICreditBalance) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const { contactInfo } = useSelector((state: RootState) => state.helpData);
  const [collapseStatus, setCollapseStatus] = useState(false);
  const handleCollapse = () => {
    setCollapseStatus(!collapseStatus);
  };
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(8),
        border: `1px solid ${grey[400]}`,
      }}
    >
      <Typography variant='h5' mb={theme.spacing(3)}>
        Credit Balance
      </Typography>
      <Typography variant='body2' mb={theme.spacing(3)}>
        A total credit balance of{' '}
        <strong style={{ margin: '0 5px' }}>{formatAmount(totalCreditBalance)}</strong> can be used
        toward this payment.
      </Typography>
      <Typography variant='caption' sx={{ display: 'inline-block', mb: 8, fontStyle: 'italic' }}>
        To apply a credit balance toward a payment, please call
        <strong style={{ margin: '0 5px' }}>{contactInfo?.phoneNumber}</strong> or email
        <strong style={{ margin: '0 5px' }}>{contactInfo?.email}</strong>
      </Typography>
      <Collapse in={collapseStatus}>
        <Stack gap={8}>
          <BalanceItem amount={totalCreditAdjustments} label='Adjustments' />
          <BalanceItem amount={totalCreditPayments} label='Payments' />
          <BalanceItem amount={totalCreditPrepayments} label='Prepayments' />
          <BalanceItem amount={totalCreditInvoices} label='Invoices' />
        </Stack>
      </Collapse>
      <Box mt={8} mb={8}>
        <Button size='small' startIcon={<AddIcon />} onClick={handleCollapse}>
          {collapseStatus ? 'Hide Details' : 'View Details'}
        </Button>
      </Box>
    </Box>
  );
};

export default CreditBalance;
