import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, useTheme } from '@mui/material';
import { StoreDispatch } from '../../../redux/Store';
import { updateFilters } from '../../../redux/transaction/TransactionSlice';

const PeriodFilters = ({ filters }: any) => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();

  const handlePeriodChange = (value: string) => {
    dispatch(updateFilters({ ...filters, period: value }));
  };
  return (
    <Box sx={{ display: 'flex', gap: theme.spacing(4) }}>
      {[
        {
          label: 'Previous Period',
          value: 'previous',
        },
        {
          label: 'Current Period',
          value: 'current',
        },
        {
          label: 'All',
          value: '',
        },
      ].map((option, index) => (
        <Button
          color={filters.period === option.value ? 'primary' : 'inherit'}
          key={`${option.value}_${index}`}
          variant='contained'
          sx={{
            padding: theme.spacing(2, 6),
            height: theme.spacing(10),
            fontSize: theme.spacing(5),
            minWidth: 'unset',
            lineHeight: '1rem',
          }}
          onClick={() => handlePeriodChange(option.value)}
        >
          {option.label}
        </Button>
      ))}
    </Box>
  );
};

export default PeriodFilters;
