import React, { useState } from 'react';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { IAdvertiser } from '../../../redux/account/AccountModels';

interface ILinkedAdvertisers {
  advertisers: IAdvertiser[];
}

const LinkedAdvertisers = ({ advertisers }: ILinkedAdvertisers) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const numberToShow = 6;
  const total = advertisers.length;
  const remaining = total - numberToShow;
  const [collapseStatus, setCollapseStatus] = useState(false);

  const handleCollapse = () => {
    setCollapseStatus(!collapseStatus);
  };
  return (
    <Box>
      <Typography variant='h6' sx={{ fontSize: 16, mb: 6, mt: 10 }}>
        Advertiser
      </Typography>
      <div>
        {advertisers.slice(0, collapseStatus ? total : numberToShow).map((advertiser, index) => (
          <Chip
            variant='outlined'
            sx={{ mb: 3, mr: 2, backgroundColor: grey[50] }}
            key={index}
            label={advertiser?.advertiserName}
          />
        ))}
        <Typography
          className='more-items'
          variant='underline'
          color='primary'
          sx={{ cursor: 'pointer' }}
          onClick={handleCollapse}
        >
          {remaining > 0 && (collapseStatus ? 'View less' : `+${remaining} others`)}
        </Typography>
      </div>
    </Box>
  );
};
export default LinkedAdvertisers;
