import React, { useEffect } from 'react';
import HelpText from './HelpText';
import { Box, Typography, useTheme } from '@mui/material';
import { scrollToTop } from '../../utils/common';

const UnderReview = () => {
  const theme = useTheme();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={theme.spacing(12)}
      marginBottom={theme.spacing(12)}
    >
      <Box>
        <Typography variant='eyebrow' marginBottom={theme.spacing(2)}>
          UNDER REVIEW
        </Typography>
        <Typography variant='h4'>Your Account is Being Processed</Typography>
      </Box>
      <Typography variant='body1'>
        Thank you! We have received your information and will begin reviewing your account to
        connect you to Client Portal. Once your account has been processed, a member of our team
        will reach out to you with next steps.
      </Typography>
      <HelpText />
    </Box>
  );
};

export default UnderReview;
