import React from 'react';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import { Box, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';

const RegistrationConfirmation = () => {
  const theme = useTheme();
  const { contactInfo } = useSelector((state: RootState) => state.helpData);
  const SUPPORT_EMAIL = contactInfo?.email;
  return (
    <PageContentContainer>
      <Box mt={25} mb={25}>
        <Typography variant='eyebrow' mt={20} mb={5}>
          Account Created
        </Typography>
        <Typography variant='h3' mb={10}>
          Your Account is Being Processed
        </Typography>
        <Typography variant='paragraph2' mb={5} color={theme.palette.common.black}>
          Thank you for joining Spectrum Reach Client Portal. Your account is being reviewed and
          will be processed shortly. A member of our team will reach out to you with next steps.
        </Typography>
        <Typography variant='paragraph2' mb={5} color={theme.palette.common.black}>
          For help, please reach out to
          <a href={`mailto:${SUPPORT_EMAIL}`}>
            <Typography
              color='black'
              sx={{
                ml: 4,
                textDecorationLine: 'underline',
              }}
              component='span'
              variant='paragraph2'
            >
              {SUPPORT_EMAIL}
            </Typography>
          </a>
        </Typography>
      </Box>
    </PageContentContainer>
  );
};
export default RegistrationConfirmation;
