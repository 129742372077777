import { Chip } from '@mui/material';
import React from 'react';

const StatusCell = ({ status }: { status: string }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'Processed':
        return 'success';
      case 'Failed':
      case 'Canceled':
        return 'error';
      case 'Processing':
        return 'warning';
      case 'Received':
        return 'primary';
      case 'Active':
        return 'success';
      case 'Pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  return <Chip label={status} color={getStatusColor()} />;
};

export default StatusCell;
