export enum OrderOrInvoiceTypes {
  Order = 'Order',
  Invoice = 'Invoice',
  ManagedOrder = 'ManagedOrder',
  ManagedInvoice = 'ManagedInvoice',
}
export enum InvoiceOrOrderPaymentStatus {
  Paid = 'Paid',
  UnPaid = 'UnPaid',
}

export enum PaymentFlowType {
  ORDERS = 'Orders',
  Invoices = 'Invoices',
}
export enum InvoiceAndOrdersViewType {
  SUMMARY = 'summary',
  PAYMENT_AMOUNT = 'paymentAmount',
  REVIEW = 'review',
}

export interface IAgencyOpenInvoicesResponse {
  agencyTotalCreditBalance: number;
  agencyTotalCreditAdjustments: number;
  agencyTotalCreditPayments: number;
  agencyTotalCreditPrepayments: number;
  agencyTotalCreditInvoices: number;
  totalBalance: number;
  agency: IAgency;
  presignedURL: string;
}
export interface IAgency {
  agencyId: string;
  agencyName: string;
  advertisers: IAdvertiserOrAgencyResponse[];
}

export interface IManagedInvoiceOrderResponse {
  advertiserId: string;
  advertiserName: string;
  totalInvoiceAmount: number;
  totalOrderAmount: number;
  totalCreditBalance: number;
  adjustments: number;
  payments: number;
  prePayments: number;
  invoices: number;
  totalBalance: number;
  managedAgencies: ManagedAgency[];
}
export interface ManagedAgency {
  agencyId: string;
  agencyName: string;
  totalOpenInvoiceAmount: number;
  totalOpenOrderAmount: number;
  openInvoices: IOpenInvoice[];
  openOrders: IOpenOrder[];
}
export interface IMakePaymentOrderPayload {
  number: string;
  orderDate: string;
  dueDate: string;
  amount: number;
  isCustomAmount: boolean;
}
export interface IMakePaymentInvoicePayload {
  number: string;
  amount: number;
  isCustomAmount: boolean;
}
export interface ISelectedInvoiceOrOrderItem {
  advertiserOrAgencyName: string;
  advertiserOrAgencyId: number;
  totalCreditBalance: number;
  totalCreditAdjustments: number;
  totalCreditPayments: number;
  totalCreditPrepayments: number;
  totalCreditInvoices: number;
  isManaged: boolean;
  isCustomAmount?: boolean;
  items: Array<{
    number: string;
    amount: number;
    dueDate: string;
    date: string;
    customAmount: number;
    inProgress: boolean;
  }>;
}

export interface IAdvertiserOrAgencyResponse extends IAdvertiserOrAgency {
  advertiserId?: string;
  advertiserName?: string;
}

export interface IAdvertiserOrAgency {
  advertiserOrAgencyId: any;
  advertiserOrAgencyName: string;
  totalOpenInvoiceAmount: number;
  totalOpenOrderAmount: number;
  totalCreditBalance: number;
  totalCreditAdjustments: number;
  totalCreditPayments: number;
  totalCreditPrepayments: number;
  totalCreditInvoices: number;
  totalBalance: number;
  openInvoices: IOpenInvoice[];
  openOrders: IOpenOrder[];
}

export interface IOpenOrder {
  orderNumber: string;
  note: string;
  orderDate: string;
  dueDate: string;
  paymentDate: string;
  estimateNumber: string;
  orderAmount: number;
  taxAmount: number;
  accountExecutive: string;
  inProgress?: boolean;
}

export interface IOpenInvoice {
  invoiceNumber: string;
  isAdjusted: boolean;
  totalInvoiceAmount: number;
  dueDate: string;
  paymentDate: string;
  invoiceDate: string;
  orderNumber: string;
  invoiceOrders: IOpenInvoiceOrder[];
  accountExecutive: string;
  originalAmount: number;
  adjustmentAmount: number;
  afterAdjustmentAmount: number;
  promotionalDiscount: number;
  agencyDiscount: number;
  taxAmount: number;
  repFirmDiscount: number;
  netAmount: number;
  payments: number;
  openAmount: number;
  isOpenInvoice: boolean;
  isManagedInvoice: boolean;
  estimateNumber: string;
  inProgress?: boolean;
}

export interface IOpenInvoiceOrder {
  orderNumber: string;
  orderDate: string;
  orderAmount: number;
}

export interface IInvoiceOrOrderFilters {
  startDate: string | null;
  endDate: string | null;
  searchValue: string;
}

export interface ICreditBalance {
  totalCreditBalance: number;
  totalCreditAdjustments: number;
  totalCreditPayments: number;
  totalCreditPrepayments: number;
  totalCreditInvoices: number;
}

export interface IOverviewState {
  selectedInvoices: ISelectedInvoiceOrOrderItem[];
  selectedOrders: ISelectedInvoiceOrOrderItem[];
  selectedOrdersTotal: number;
  selectedInvoicesTotal: number;
  selectedInvoicesCount: number;
  selectedOrdersCount: number;
  // Credit Balance
  creditBalance: ICreditBalance;
  // General
  isPayFull: boolean;
  // Invoices
  invoiceTotal: number;
  invoices: IAdvertiserOrAgency[];
  invoicesStatus: string;
  invoicesError: any;
  invoicePresignedURL: string;
  // Invoice Filters
  invoiceFilters: IInvoiceOrOrderFilters;
  // Managed Invoices
  managedInvoiceTotal: number;
  managedInvoices: IAdvertiserOrAgency[];
  managedInvoicesStatus: string;
  managedInvoicesError: any;
  // Managed Orders
  managedOrdersTotal: number;
  managedOrders: IAdvertiserOrAgency[];
  managedOrdersStatus: string;
  managedOrdersError: any;
  // Order Filters
  orderFilters: IInvoiceOrOrderFilters;
  //Orders
  ordersTotal: number;
  orders: IAdvertiserOrAgency[];
  ordersStatus: string;
  ordersError: any;
}
