import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { Actions } from '../Members/MemberRoleMappings';

interface IInviteSentConfirmationProps {
  handleClose: (data: { type: Actions }) => void;
}

const InviteSentConfirmation = ({ handleClose }: IInviteSentConfirmationProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;

  const handleConfirm = () => {
    handleClose({ type: Actions.INVITE_USER });
  };

  return (
    <div>
      <Typography variant='h2' mb={5}>
        Invitation Sent
      </Typography>
      <Typography variant='body1' mb={10} color={grey[500]}>
        An invitation email has been sent to the email address provided
      </Typography>
      <Button sx={{ mt: 10 }} onClick={handleConfirm} variant='contained' size='large'>
        Ok
      </Button>
    </div>
  );
};

export default InviteSentConfirmation;
