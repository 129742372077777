import React, { useState } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import { StyledSelectedInvoicesContainer } from './SelectedInvoicesStyles';
import InvoiceAndOrderCard from './InvoiceAndOrderCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import {
  InvoiceAndOrdersViewType,
  ISelectedInvoiceOrOrderItem,
  PaymentFlowType,
} from '../../../redux/overview/OverviewModels';
import CreditBalance from '../CreditBalance';
import { ClientTypes } from '../../../redux/auth/AuthModels';
import {
  updateSelectedInvoices,
  updateSelectedOrders,
} from '../../../redux/overview/OverviewSlice';

const SelectedInvoicesAndOrders = ({ viewType }: { viewType?: InvoiceAndOrdersViewType }) => {
  const dispatch: StoreDispatch = useDispatch();
  const isReviewStep = viewType === InvoiceAndOrdersViewType.REVIEW;
  const isSummary = viewType === InvoiceAndOrdersViewType.SUMMARY;
  const isPaymentAmount = viewType === InvoiceAndOrdersViewType.PAYMENT_AMOUNT;
  const [collapseStatus, setCollapseStatus] = useState(isReviewStep || isPaymentAmount);

  const { selectedPaymentFlowType } = useSelector((state: RootState) => state.paymentData);
  const { clientType } = useSelector((state: RootState) => state.authData);
  const { selectedInvoices, selectedOrders } = useSelector((state: RootState) => state.overview);

  const isAdvertiser = clientType === ClientTypes.Advertiser;
  const isInvoice = selectedPaymentFlowType === PaymentFlowType.Invoices;
  const label = isInvoice ? 'Invoices' : 'Orders';

  const handleCollapse = () => {
    setCollapseStatus(!collapseStatus);
  };

  const handleUpdateSelectedInvoiceOrOrders = (updatedItem: ISelectedInvoiceOrOrderItem) => {
    if (isInvoice) {
      const modItems = [...selectedInvoices];
      const modItemIndex = selectedInvoices.findIndex(
        (invoice) => invoice.advertiserOrAgencyId === updatedItem.advertiserOrAgencyId,
      );
      modItems.splice(modItemIndex, 1, updatedItem);
      dispatch(updateSelectedInvoices(modItems));
      return;
    }
    const modItems = [...selectedOrders];
    const modItemIndex = modItems.findIndex(
      (invoice) => invoice.advertiserOrAgencyId === updatedItem.advertiserOrAgencyId,
    );
    modItems.splice(modItemIndex, 1, updatedItem);
    dispatch(updateSelectedOrders(modItems));
  };

  const selectedItemsData: ISelectedInvoiceOrOrderItem[] = isInvoice
    ? selectedInvoices
    : selectedOrders;

  return (
    <StyledSelectedInvoicesContainer>
      <Box sx={{ flexGrow: 1 }}>
        <Collapse in={collapseStatus} timeout='auto'>
          <Box className='invoices-container'>
            {selectedItemsData.map((selectedItem, i) => (
              <InvoiceAndOrderCard
                viewType={viewType}
                paymentFlowType={selectedPaymentFlowType}
                key={i}
                selectedAdvertiserInvoiceOrOrder={selectedItem}
                handleUpdate={handleUpdateSelectedInvoiceOrOrders}
                hideFooter={isAdvertiser && !selectedItem.isManaged}
              >
                {isPaymentAmount && selectedItem?.totalCreditBalance !== 0 && (
                  <Box mt={10} mb={10}>
                    <CreditBalance {...selectedItem} />
                  </Box>
                )}
              </InvoiceAndOrderCard>
            ))}
          </Box>
        </Collapse>
        {isSummary && (
          <Typography
            className='more-items'
            variant='underline'
            color='primary'
            onClick={handleCollapse}
            sx={{ cursor: 'pointer', mt: 5, mb: 5 }}
          >
            {collapseStatus ? `Hide selected ${label}` : `+Show selected ${label}`}
          </Typography>
        )}
      </Box>
    </StyledSelectedInvoicesContainer>
  );
};
export default SelectedInvoicesAndOrders;
