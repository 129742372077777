import React from 'react';
import { NavLink } from 'react-router-dom';
import { Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StyledContainer } from './HelpFooterStyles';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';

const HelpFooter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const { contactInfo } = useSelector((state: RootState) => state.helpData);
  return (
    <StyledContainer>
      <Grid container spacing={0} justifyContent='space-between'>
        <Grid className='left-item' item tablet={8} laptop={10} mobile={12}>
          <Typography alignItems='center' variant='eyebrow' className='side-heading'>
            <QuestionAnswerIcon className='support-icon' /> Need Help ?
          </Typography>
          <Typography alignItems='center' className='info-text' variant='subtitle2'>
            Contact our support team and a member from our team will get back to you within 24
            hours.
          </Typography>
        </Grid>
        <Grid className='right-item' item tablet={4} laptop={2} mobile={12}>
          <Divider orientation={isMobile ? 'horizontal' : 'vertical'} className='divider' />
          <div className='links-container'>
            <Typography
              to={'mailto:' + contactInfo?.email}
              component={NavLink}
              mt={2}
              mb={2}
              color='primary'
              alignItems='center'
              variant='subtitle2'
            >
              {contactInfo?.email}
            </Typography>
            <Typography
              to='#'
              component={NavLink}
              mt={2}
              mb={2}
              color='primary'
              alignItems='center'
              variant='subtitle2'
            >
              {contactInfo?.phoneNumber}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
export default HelpFooter;
