import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

const ScrollBox = styled(Box)(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return {
    overflowY: 'scroll',
    overflow: 'auto',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 12,
    },
    '&::-webkit-scrollbar-track': {
      background: grey[400],
      borderRadius: 40,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 40,
      backgroundColor: primary[500],
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: primary[500],
    },
  };
});

export default ScrollBox