import React from 'react';
import { StyledFooter } from './FooterStyles';
import { NavLink } from 'react-router-dom';
import { ReactComponent as SpectrumLogo } from '../../assets/images/spectrum-logo-white.svg';
import { Box, Divider, Grid, IconButton, Link, Typography } from '@mui/material';
import { PATHS } from '../../routes/Routes';
import SupportIcon from '@mui/icons-material/Support';
import {
  aboutUsLinks,
  californiaPrivacyLinks,
  charterBrandsLinks,
  privacyLinks,
  socialIconLinks,
} from './Links';

const Footer = () => {
  return (
    <StyledFooter>
      <Grid container spacing={0} justifyContent='space-between'>
        <Grid className='left-item' item mobile={8}>
          <div className='logo-container left'>
            <div className='spectrum-logo' role='presentation'>
              <NavLink to='/'>
                <SpectrumLogo />
              </NavLink>
            </div>
          </div>
        </Grid>
        <Grid className='right-item' item mobile={4}>
          <Typography
            textAlign='right'
            alignItems='center'
            className='help-link support-link'
            variant='paragraph2'
            to={PATHS.HELP}
            component={NavLink}
          >
            <SupportIcon className='support-icon' /> Get Help
          </Typography>
        </Grid>
      </Grid>
      <Box mt={14} mb={4}>
        <Grid container spacing={0} flexDirection='row'>
          <Grid mb={4} item tablet={2}>
            <Typography alignItems='center' className='link-header' variant='eyebrow'>
              About US
            </Typography>
            <div className='links-container'>
              {aboutUsLinks.map((link, index) => (
                <Typography
                  key={index}
                  to={link?.link}
                  component={NavLink}
                  mt={2}
                  mb={2}
                  alignItems='center'
                  className='help-link'
                  variant='paragraph2'
                >
                  {link?.label}
                </Typography>
              ))}
            </div>
          </Grid>
          <Grid mb={4} item tablet={8}>
            <Typography alignItems='center' className='link-header' variant='eyebrow'>
              Charter Brands
            </Typography>
            <div className='links-container'>
              {charterBrandsLinks.map((link, index) => (
                <Typography
                  key={index}
                  to={link?.link}
                  component={NavLink}
                  mt={2}
                  mb={2}
                  alignItems='center'
                  className='help-link half-width'
                  variant='paragraph2'
                >
                  {link?.label}
                </Typography>
              ))}
            </div>
          </Grid>
          <Grid mb={4} item tablet={2}>
            <Typography alignItems='center' className='link-header' variant='eyebrow'>
              Connect with us
            </Typography>
            <div className='links-container'>
              {socialIconLinks.map((link) => {
                const Icon = link.icon;
                return (
                  <Link key={link?.label} href={link?.link}>
                    <IconButton key={link.label} className='icon' aria-label={link.label}>
                      <Icon />
                    </IconButton>
                  </Link>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Box>
      <Divider className='divider' />
      <Box mt={14} mb={4}>
        {privacyLinks.map((link) => (
          <Link key={link?.label} className='anchor-link' href={link?.link}>
            {link?.label}
          </Link>
        ))}
      </Box>
      <Box mt={14} mb={4}>
        <Typography alignItems='center' className='side-header' variant='h6'>
          California:
        </Typography>
        <Box mt={8} mb={4}>
          {californiaPrivacyLinks.map((link) => (
            <Link key={link?.label} className='anchor-link' href={link?.link}>
              {link?.label}
            </Link>
          ))}
        </Box>
        <Box mt={10} mb={4}>
          <Typography alignItems='center' className='info-text' variant='subtitle2'>
            If you are a customer with a disability, please contact us if you need assistance.
          </Typography>
        </Box>
        <Box mt={6} mb={4}>
          <Typography alignItems='center' className='info-text' variant='subtitle2'>
            Copyright © 2023 Spectrum Reach
          </Typography>
        </Box>
      </Box>
    </StyledFooter>
  );
};
export default Footer;
