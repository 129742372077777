import React, { SetStateAction } from 'react';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import SpectrumDatePicker from '../../../components/DatePicker/DatePicker';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { getLocalDateWithOffset } from '../../../utils/common';

const PaymentDate = ({
  payOn,
  setPayOn,
  handlePrevious,
  handleNext,
}: {
  payOn: any;
  setPayOn: React.Dispatch<SetStateAction<any>>;
  handleNext: () => void;
  handlePrevious: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const utcDate = getLocalDateWithOffset();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPayOn(value);
  };

  return (
    <>
      <Box>
        <SpectrumDatePicker
          placeholder='Select Date'
          label=''
          value={payOn}
          minDate={utcDate}
          onChange={handleChange}
        />
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Button
          variant='outlined'
          size='large'
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 12 : 16),
            margin: isMobile ? theme.spacing(0, 0, 10, 0) : theme.spacing(0),
          }}
          onClick={handlePrevious}
        >
          Previous
        </Button>
        <Button
          variant='contained'
          size='large'
          disabled={!payOn}
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 16 : 24),
          }}
          onClick={handleNext}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};

export default PaymentDate;
