import { Box, Chip, Typography, useTheme } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { ColorPartial } from '@mui/material/styles/createPalette';
import React from 'react';

import { IPayment } from '../../redux/payment/PaymentModel';
import { formatDate } from '../../utils/common';

const PaymentInfo = ({ payment }: { payment: IPayment }) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' alignItems='center' gap={theme.spacing(3)}>
        <Typography variant='h6'>
          {formatDate(payment.nextChargeDate)}
        </Typography>
        {payment.isRecurring && (
          <Chip
            label='AutoPay'
            icon={<SyncIcon />}
            size='small'
            sx={{
              fontSize: 11.11,
              paddingX: theme.spacing(2),
            }}
          />
        )}
      </Box>
      <Typography variant='subtitle' display='block' component='div' color={grey[500]} mt={theme.spacing(2)}>
        <Typography
          color={grey[500]}
          variant='subtitle'
          fontWeight={700}
        >
          {payment.paymentBrand} ..
          {payment.paymentMethodSuffix}
        </Typography>
      </Typography>
    </Box>
  );
};

export default PaymentInfo;
