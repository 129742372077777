import { Box, Card, Grid, Stack, Stepper, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { ColorPartial } from '@mui/material/styles/createPalette';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import { StyledStep, StyledStepConnector } from '../SetUpAutoPay/SetUpAutoPayStyles';
import { scrollToTop } from '../../utils/common';
import PaymentAmount from './Steps/PaymentAmount';
import CancelButton from '../../components/CancelButton/CancelButton';
import { PATHS } from '../../routes/Routes';
import { useNavigate } from 'react-router-dom';
import CustomerSupportFooter from './CustomerSupportFooter';
import { StyledCardContent } from './MakeAutoPaymentStyles';
import SelectPaymentMethod from '../SetUpAutoPay/Steps/SelectPaymentMethod';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import PaymentSummary from './PaymentSummary/PaymentSummary';
import ReviewDetails from './Steps/ReviewDetails';
import PaymentDate from './Steps/PaymentDate';
import { PaymentFlowType } from '../../redux/overview/OverviewModels';
import { setSelectedPaymentType } from '../../redux/payment/PaymentSlice';
import useConfirmPageReload from '../../routes/ConfirmPageReload';
import { IPaymentMethod } from '../../redux/autoPay/AutoPayModel';
import MakePaymentConfirmation from './MakePaymentConfirmation';
import MakePaymentConfirmationFailed from './MakePaymentConfirmationFailed';
import { resetMakePaymentStatus } from '../../redux/InvoicesAndOrders/InvoicesAndOrderSlice';

export const MAKE_PAYMENT_STEPS: Record<number, string> = {
  1: 'Payment Amount',
  2: 'Select Payment Date',
  3: 'Select Payment Method',
  4: 'Review Details',
};

interface IMakePaymentStepperProps {
  type: PaymentFlowType;
}

const MakePaymentStepper = ({ type }: IMakePaymentStepperProps) => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { paymentMethods } = useSelector((state: RootState) => state.autoPay);
  const { makePaymentError, makePaymentStatus } = useSelector(
    (state: RootState) => state.invoiceAndOrdersData,
  );
  const {
    selectedOrdersTotal,
    selectedInvoicesTotal,
    selectedInvoices,
    selectedOrders,
    selectedOrdersCount,
    selectedInvoicesCount,
  } = useSelector((state: RootState) => state.overview);
  const { selectedPaymentFlowType } = useSelector((state: RootState) => state.paymentData);
  const isInvoice = selectedPaymentFlowType === PaymentFlowType.Invoices;
  const selectedCount = isInvoice ? selectedInvoicesCount : selectedOrdersCount;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const [activeStep, setActiveStep] = useState<number>(1);

  const [payOn, setPayOn] = useState<null | string>(null);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>('');

  const selectedPaymentMethodDetail = useMemo(
    () =>
      paymentMethods?.find((paymentMethod) => paymentMethod.spmId === selectedPaymentMethodId) ||
      ({} as IPaymentMethod),
    [paymentMethods, selectedPaymentMethodId],
  );
  const totalAmount =
    type === PaymentFlowType.Invoices ? selectedInvoicesTotal : selectedOrdersTotal;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    scrollToTop();
  };
  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
    scrollToTop();
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 1:
        return <PaymentAmount totalAmount={totalAmount} handleNext={handleNext} />;
      case 2:
        return (
          <PaymentDate
            payOn={payOn}
            setPayOn={setPayOn}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <SelectPaymentMethod
            selectedPaymentMethodId={selectedPaymentMethodId}
            setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        );
      case 4:
        return (
          <ReviewDetails
            payOn={payOn || ''}
            totalAmount={totalAmount}
            selectedPaymentMethodDetail={selectedPaymentMethodDetail}
            setActiveStep={setActiveStep}
            handlePrevious={handlePrevious}
          />
        );
      default:
        return null;
    }
  };

  const handleCancel = () => {
    navigate(PATHS.OVERVIEW);
  };

  useEffect(() => {
    // If no invoices or orders selected redirect back to overview page
    if (!(selectedInvoices.length || selectedOrders.length)) {
      handleCancel();
    }
    dispatch(setSelectedPaymentType(type));
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [activeStep]);

  useEffect(() => {
    return () => {
      dispatch(resetMakePaymentStatus());
    };
  }, []);

  const showSummaryPanel = activeStep !== 4 && activeStep !== 1;

  useConfirmPageReload(true);

  if (makePaymentStatus === 'succeeded') {
    return (
      <MakePaymentConfirmation
        totalAmount={totalAmount}
        payOn={payOn}
        selectedPaymentMethodDetail={selectedPaymentMethodDetail}
      />
    );
  }

  if (makePaymentStatus === 'failed') {
    return <MakePaymentConfirmationFailed error={makePaymentError} />;
  }

  return (
    <PageContentContainer>
      <Stack spacing={theme.spacing(8)}>
        <Card
          sx={{
            border: `1px solid ${grey[200]}`,
          }}
        >
          <StyledCardContent>
            <Grid container>
              <Grid
                item
                laptop={showSummaryPanel ? 8 : 12}
                tablet={showSummaryPanel ? 7 : 12}
                small={12}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: theme.spacing(12),
                  }}
                >
                  <Box sx={{ width: 290 }}>
                    <Stepper activeStep={activeStep} connector={<StyledStepConnector />}>
                      {[1, 2, 3, 4, 5].map((step) => (
                        <StyledStep key={step} />
                      ))}
                    </Stepper>
                    <Typography variant='caption'>
                      <Typography component='span' color={primary[500]} variant='caption'>
                        {activeStep}
                      </Typography>{' '}
                      / 4
                    </Typography>
                  </Box>
                  <Stack spacing={theme.spacing(8)}>
                    <Box
                      sx={{
                        pt: theme.spacing(12),
                      }}
                    >
                      <Typography variant='eyebrow'>Make a Payment</Typography>
                      <Typography variant='h2' color={primary[800]}>
                        {MAKE_PAYMENT_STEPS[activeStep]}
                      </Typography>
                    </Box>
                    {renderSteps()}
                    <Box sx={{ mt: 10 }}>
                      <CustomerSupportFooter />
                    </Box>
                  </Stack>
                </Box>
              </Grid>
              {showSummaryPanel && (
                <Grid item laptop={4} tablet={5} small={12}>
                  <PaymentSummary
                    count={selectedCount}
                    totalAmount={totalAmount}
                    activeStep={activeStep}
                    payOn={payOn}
                  />
                </Grid>
              )}
            </Grid>
          </StyledCardContent>
        </Card>
        <CancelButton onCancel={handleCancel} />
      </Stack>
    </PageContentContainer>
  );
};
export default MakePaymentStepper;
