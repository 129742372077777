import React, { useEffect } from 'react';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import InvoiceOrOrders from './Invoices/InvoiceOrOrders';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { OrderOrInvoiceTypes } from '../../redux/overview/OverviewModels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { fetchManagedOrders } from '../../redux/overview/OverviewSlice';
import { ComponentOverlayLoader } from '../../components/Loaders/Loader';

const ManagedOrders = () => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const { managedOrders, managedOrdersStatus, managedOrdersError, orderFilters } = useSelector(
    (state: RootState) => state.overview,
  );

  useEffect(() => {
    dispatch(fetchManagedOrders(orderFilters));
  }, [orderFilters]);

  const isOrdersLoading = managedOrdersStatus === 'loading';

  return (
    <>
      <Box>
        <Typography variant='h4' fontSize='1rem' mt={theme.spacing(8)}>
          Managed Orders
        </Typography>
        <Typography
          color={grey[500]}
          fontSize='0.875rem'
          mb={theme.spacing(4)}
          sx={{
            fontStyle: 'italic',
          }}
        >
          Payments made by a third-party agency may take up to 24 hours to appear.
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
          minHeight: 100,
        }}
      >
        {managedOrdersError && (
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {managedOrdersError}
            </Typography>
          </Alert>
        )}
        {isOrdersLoading ? (
          <Box sx={{ mt: 30 }}>
            <ComponentOverlayLoader />
          </Box>
        ) : (
          <>
            {managedOrders?.length > 0 ? (
              <InvoiceOrOrders data={managedOrders} type={OrderOrInvoiceTypes.ManagedOrder} />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 100,
                }}
              >
                <Typography variant='body2' color={grey[500]}>
                  No Managed Orders Found
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ManagedOrders;
