import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import HelpText from '../ReviewRequest/HelpText';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';

const InActiveUser = () => {
  const theme = useTheme();
  return (
    <PageContentContainer>
      <Box>
        <Box
          display='flex'
          flexDirection='column'
          gap={theme.spacing(12)}
          marginY={theme.spacing(40)}
          marginBottom={theme.spacing(24)}
        >
          <Box>
            <Typography variant='eyebrow' marginBottom={theme.spacing(4)}>
              ACCOUNT DEACTIVATED
            </Typography>
            <Typography variant='h4'>
              We regret to inform you that your account has been deactivated
            </Typography>
          </Box>
          <HelpText />
        </Box>
      </Box>
    </PageContentContainer>
  );
};
export default InActiveUser;
