import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaymentHistory, resetFilters, updateFilters } from '../../redux/payment/PaymentSlice';
import { IPaymentHistoryObject } from '../../redux/payment/PaymentModel';
import { RootState, StoreDispatch } from '../../redux/Store';
import { formatAmount, formatDate } from '../../utils/common';
import { PAYLOAD_DATE_FORMAT } from '../../constants/common';

import { Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomDateRangePicker from '../../components/DatePicker/DateRangePicker';
import SpectrumSearch from '../../components/Spectrum/Search/SpectrumSearch';
import SpectrumTable, { IColumn } from '../../components/Spectrum/Table/SpectrumTable';
import StatusCell from '../../components/Spectrum/Table/StatusCell';
import { PATHS } from '../../routes/Routes';

const PaymentHistory = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: StoreDispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const { filters, paymentHistory, paymentHistoryStatus } = useSelector(
    (state: RootState) => state.paymentData,
  );

  const [date, setDate] = useState([]);
  const [searchText, setSearchText] = useState('');

  const columns: IColumn<IPaymentHistoryObject>[] = [
    {
      label: 'Payment Date',
      sortKey: 'PaymentDate',
      accessorKey: 'paymentDate',
      render: (data) => <strong>{formatDate(data.paymentDate)}</strong>,
    },
    {
      label: 'Transaction ID',
      sortKey: 'TransactionId',
      accessorKey: 'transactionId',
      render: (data) => (data.transactionId ? `#${data.transactionId}` : ''),
    },
    {
      label: 'Amount',
      sortKey: 'Amount',
      accessorKey: 'amount',
      render: (data) => (data.amount !== undefined ? formatAmount(data.amount) : ''),
    },
    {
      label: 'Status',
      sortKey: 'PaymentStatus',
      accessorKey: 'status',
      render: (data) => <StatusCell status={data.paymentStatus} />,
    },
    {
      label: '',
      sortKey: '',
      accessorKey: '',
      render: (data) => (
        <Button
          variant='text'
          onClick={() =>
            navigate(
              `${PATHS.PAYMENT_DETAILS}?transactionId=${data.transactionId}&orderTypeId=${data.orderTypeId}`,
            )
          }
          sx={{ padding: 0 }}
        >
          View Details
        </Button>
      ),
      disablePadding: true,
      width: '130px',
    },
  ];

  useEffect(() => {
    dispatch(fetchPaymentHistory(filters));
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  const handleChangeOfDate = (event: React.ChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setDate(value);

    const [startDate, endDate]: [Date, Date] = value;
    if ((startDate && endDate) || (!startDate && !endDate)) {
      dispatch(
        updateFilters({
          ...filters,
          startDate: startDate ? formatDate(startDate, PAYLOAD_DATE_FORMAT) : null,
          endDate: endDate ? formatDate(endDate, PAYLOAD_DATE_FORMAT) : null,
          start: 0,
        }),
      );
    }
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setSearchText(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(updateFilters({ ...filters, searchValue: searchText }));
    }
  };

  const handleItemsPerPageChange = (ItemsPerPage: number) => {
    dispatch(updateFilters({ ...filters, length: ItemsPerPage, start: 0 }));
  };

  const handlePageChange = (pageNumber: number) => {
    dispatch(updateFilters({ ...filters, start: pageNumber * filters.length }));
  };

  const handleSort = (_: React.MouseEvent<unknown>, property: string) => {
    dispatch(
      updateFilters({
        ...filters,
        sortColumn: property,
        sortDirection:
          property === filters.sortColumn && filters.sortDirection === 'asc' ? 'desc' : 'asc',
      }),
    );
  };

  return (
    <>
      <Typography variant='eyebrow'>Payment History</Typography>
      <Typography variant='h4'>Your Payments</Typography>
      <Container
        sx={{
          mt: 16,
          mb: 16,
          ...(isMobile
            ? { display: 'flex', flexDirection: 'column', gap: '8px' }
            : {
                display: 'flex',
                justifyContent: 'space-between',
                gap: '8px',
              }),
        }}
      >
        <CustomDateRangePicker label='' name='date' value={date} onChange={handleChangeOfDate} />
        <SpectrumSearch
          placeholder='Search...'
          name='search'
          sx={{ width: '100%' }}
          value={searchText}
          onChange={handleChangeSearch}
          onKeyDown={handleKeyDown}
        />
      </Container>
      <SpectrumTable
        orderBy={filters.sortColumn}
        order={filters.sortDirection === '' ? undefined : filters.sortDirection}
        onSort={handleSort}
        onItemsPerPageChange={handleItemsPerPageChange}
        onPageChange={handlePageChange}
        columns={columns}
        loading={paymentHistoryStatus === 'loading'}
        data={paymentHistory.data}
        currentPage={filters.start / filters.length}
        itemsPerPage={filters.length}
        totalItems={paymentHistory.totalRecords}
      />
    </>
  );
};

export default PaymentHistory;
