import React, { Fragment, useEffect } from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import PaymentIcon from '@mui/icons-material/Payment';

import { AUTOPAY_STEPS } from '../SetUpAutoPay/SetUpAutoPay';
import { formatAmount, formatDate, getLocalDateWithOffset, nth } from '../../utils/common';
import { ACH } from '../../constants/common';
import { IPayment } from '../../redux/payment/PaymentModel';
import { RootState, StoreDispatch } from '../../redux/Store';
import {
  cancelRecurringPayment,
  cancelScheduledPayment,
  resetCancelledPaymentStatus,
} from '../../redux/payment/PaymentSlice';
import CustomerSupportFooter from '../MakePayment/CustomerSupportFooter';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';

interface PaymentDetailProps {
  open: boolean;
  onClose: () => void;
  selectedPaymentDetail: IPayment | null;
}

function PaymentDetail({ onClose, open, selectedPaymentDetail }: PaymentDetailProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const dispatch: StoreDispatch = useDispatch();
  const nextChargeDate = selectedPaymentDetail?.nextChargeDate
    ? formatDate(selectedPaymentDetail?.nextChargeDate)
    : '';
  const todayDate = formatDate(getLocalDateWithOffset());
  const isAllowedToCancel = selectedPaymentDetail?.isRecurring || todayDate !== nextChargeDate;
  const { cancelledRecurringPaymentStatus, cancelledScheduledPaymentStatus } = useSelector(
    (state: RootState) => state.paymentData,
  );

  const handleClose = () => {
    dispatch(resetCancelledPaymentStatus());
    onClose();
  };

  useEffect(() => {
    if (
      cancelledRecurringPaymentStatus === 'succeeded' ||
      cancelledScheduledPaymentStatus === 'succeeded'
    ) {
      handleClose();
    }
  }, [cancelledRecurringPaymentStatus, cancelledScheduledPaymentStatus]);

  const renderMethodDescriptionAsPerBrand = () => {
    const { paymentBrand } = selectedPaymentDetail || {};
    switch (paymentBrand?.trim()) {
      case ACH:
        return (
          <>
            <AccountBalanceIcon color='primary' fontSize='small' />
            <Typography variant='subtitle'>
              {paymentBrand} .. ({selectedPaymentDetail?.paymentMethodSuffix})
            </Typography>
          </>
        );
      default:
        return (
          <>
            <PaymentIcon color='primary' fontSize='small' />
            <Typography variant='subtitle'>
              {`${paymentBrand} (...${selectedPaymentDetail?.paymentMethodSuffix})`}
            </Typography>
          </>
        );
    }
  };
  const renderPaymentSummary = (step: number) => {
    switch (step) {
      case 1:
        return selectedPaymentDetail?.isRecurring ? (
          <>
            <Typography variant='paragraph2' color={theme.palette.common.black}>
              Full Account Balance
            </Typography>
            <Typography variant='subtitle'>
              You will only be charged if you have a balance. If there is a $0 balance, you will not
              be charged.
            </Typography>
          </>
        ) : (
          selectedPaymentDetail && (
            <Typography variant='paragraph2' color={theme.palette.common.black} fontWeight={700}>
              {formatAmount(selectedPaymentDetail.amount)}
            </Typography>
          )
        );
      case 2: {
        const nextChargeDate = new Date(selectedPaymentDetail?.nextChargeDate || '');
        const date = formatDate(nextChargeDate);
        const day = nextChargeDate.getDate();
        return (
          <Box>
            <Typography variant='h6'>Make Payments on the:</Typography>
            {selectedPaymentDetail && (
              <Typography variant='paragraph2' color={theme.palette.common.black}>
                {selectedPaymentDetail?.isRecurring ? (
                  <span>
                    {day}
                    {nth(day)} of each month
                  </span>
                ) : (
                  <span>{date}</span>
                )}
              </Typography>
            )}
          </Box>
        );
      }

      case 3:
        return (
          <Box>
            <Box display='flex' gap={theme.spacing(4)} mt={theme.spacing(4)}>
              {renderMethodDescriptionAsPerBrand()}
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };
  const handleCancelScheduledPayment = () => {
    if (selectedPaymentDetail?.paymentId) {
      dispatch(cancelScheduledPayment(selectedPaymentDetail.paymentId));
    }
  };
  const handleCancelAutoPayment = () => {
    if (selectedPaymentDetail?.paymentId) {
      dispatch(cancelRecurringPayment(selectedPaymentDetail.paymentId));
    }
  };

  return (
    <SpectrumDialog
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h2' mb={theme.spacing(8)}>
            {selectedPaymentDetail?.isRecurring ? 'Autopayment' : 'Scheduled payment'} Details
          </Typography>
        </Box>
        {selectedPaymentDetail && (
          <List dense sx={{ width: '100%' }} disablePadding>
            {[1, 2, 3].map((value) => (
              <Fragment key={value}>
                <ListItem
                  disablePadding
                  alignItems='flex-start'
                  sx={{
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    ...(isMobile && { gap: theme.spacing(8) }),
                  }}
                >
                  <Typography variant='h4' fontSize='1.44rem'>
                    {AUTOPAY_STEPS[value].replace('Select', '')}
                  </Typography>
                  <Box
                    sx={{
                      width: '238px',
                    }}
                  >
                    {selectedPaymentDetail && renderPaymentSummary(value)}
                  </Box>
                </ListItem>
                <Divider component='li' sx={{ my: theme.spacing(8) }} />
              </Fragment>
            ))}
          </List>
        )}
        {!isAllowedToCancel && (
          <Box sx={{ mt: 5, mb: 5 }}>
            <Alert severity='info' variant='outlined'>
              <Typography variant='paragraph2' component='span' color='primary'>
                Your payment is scheduled and cannot be canceled at this time. Cancellations are
                permitted up to 24 hours before the scheduled date. For further assistance, please
                contact our customer support team
              </Typography>
            </Alert>
          </Box>
        )}
        <Box display='flex'>
          <Box display='flex'>
            <Button
              variant='text'
              size='medium'
              onClick={handleClose}
              disabled={cancelledRecurringPaymentStatus === 'loading'}
            >
              Close Window
            </Button>
            {selectedPaymentDetail?.isRecurring ? (
              <Button
                size='medium'
                variant='text'
                onClick={handleCancelAutoPayment}
                disabled={cancelledRecurringPaymentStatus === 'loading'}
                sx={{
                  ml: theme.spacing(8),
                  p: theme.spacing(5),
                }}
              >
                {cancelledRecurringPaymentStatus === 'loading' ? (
                  <CircularProgress
                    size={16}
                    style={{
                      marginRight: theme.spacing(3),
                    }}
                  />
                ) : (
                  <CloseIcon />
                )}
                Stop Payment
              </Button>
            ) : (
              isAllowedToCancel && (
                <Button
                  size='medium'
                  variant='text'
                  onClick={handleCancelScheduledPayment}
                  disabled={cancelledScheduledPaymentStatus === 'loading' || !isAllowedToCancel}
                  sx={{
                    ml: theme.spacing(8),
                    p: theme.spacing(5),
                  }}
                >
                  {cancelledScheduledPaymentStatus === 'loading' ? (
                    <CircularProgress
                      size={16}
                      style={{
                        marginRight: theme.spacing(3),
                      }}
                    />
                  ) : (
                    <CloseIcon />
                  )}
                  Cancel Payment
                </Button>
              )
            )}
          </Box>
        </Box>
        {(cancelledScheduledPaymentStatus === 'failed' ||
          cancelledRecurringPaymentStatus === 'failed') && (
          <Typography mt={theme.spacing()} variant='paragraph2' color='error'>
            Sorry we couldn&apos;t process your request
          </Typography>
        )}
        <Box>
          <CustomerSupportFooter />
        </Box>
      </Box>
    </SpectrumDialog>
  );
}

export default PaymentDetail;
