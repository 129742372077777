import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IAdvertiserOrAgency,
  IAdvertiserOrAgencyResponse,
  IAgencyOpenInvoicesResponse,
  ICreditBalance,
  IInvoiceOrOrderFilters,
  IManagedInvoiceOrderResponse,
  IOverviewState,
} from './OverviewModels';
import ApiService from '../../services/ApiService';
import { invoiceAndOrderEndPoints } from '../../constants/apiConstants';
import { getSelectedItemTotal } from '../../utils/paymentUtils';

const initialState: IOverviewState = {
  isPayFull: false,
  selectedInvoices: [],
  selectedOrders: [],
  selectedOrdersCount: 0,
  selectedInvoicesCount: 0,
  selectedOrdersTotal: 0,
  selectedInvoicesTotal: 0,
  creditBalance: {} as ICreditBalance,
  invoiceFilters: {
    startDate: null,
    endDate: null,
    searchValue: '',
  },
  orderFilters: {
    startDate: null,
    endDate: null,
    searchValue: '',
  },
  invoiceTotal: 0,
  invoices: [],
  invoicesError: null,
  invoicePresignedURL: '',
  invoicesStatus: 'idle',
  managedInvoiceTotal: 0,
  managedInvoices: [],
  managedInvoicesError: null,
  managedInvoicesStatus: 'idle',
  managedOrdersTotal: 0,
  managedOrders: [],
  managedOrdersError: null,
  managedOrdersStatus: 'idle',
  ordersTotal: 0,
  orders: [],
  ordersError: null,
  ordersStatus: 'idle',
};

const transformInvoicesAndAddOrderNumber = (
  advertiserOrAgencies: IAdvertiserOrAgencyResponse[],
) => {
  return (advertiserOrAgencies || []).map((advertiserOrAgency) => {
    const openInvoices = (advertiserOrAgency?.openInvoices || []).map((openInvoice) => {
      let orderNumber = '';
      (openInvoice?.invoiceOrders || []).forEach((invoiceOrder) => {
        orderNumber = orderNumber
          ? orderNumber + ',' + invoiceOrder?.orderNumber
          : invoiceOrder?.orderNumber;
      });
      return { ...openInvoice, orderNumber };
    });
    return {
      ...advertiserOrAgency,
      advertiserOrAgencyId:
        advertiserOrAgency.advertiserOrAgencyId || advertiserOrAgency.advertiserId || '',
      advertiserOrAgencyName:
        advertiserOrAgency.advertiserOrAgencyName || advertiserOrAgency.advertiserName || '',
      openInvoices,
    };
  });
};

const transformOrders = (advertiserOrAgencies: IAdvertiserOrAgencyResponse[]) => {
  return (advertiserOrAgencies || []).map((advertiserOrAgency) => {
    return {
      ...advertiserOrAgency,
      advertiserOrAgencyId: advertiserOrAgency.advertiserId || '',
      advertiserOrAgencyName: advertiserOrAgency.advertiserName || '',
      openOrders: advertiserOrAgency.openOrders,
    };
  });
};

const transformAndAddAdvertiserDetails = (data: IManagedInvoiceOrderResponse) => {
  return (data.managedAgencies || []).map((agency) => {
    const agencyDetails: IAdvertiserOrAgency = {
      totalCreditAdjustments: data.adjustments,
      advertiserOrAgencyId: agency.agencyId,
      advertiserOrAgencyName: agency.agencyName,
      totalCreditInvoices: data.invoices,
      totalCreditPayments: data.payments,
      totalCreditPrepayments: data.prePayments,
      totalBalance: data.totalBalance,
      totalCreditBalance: data.totalCreditBalance,
      ...agency,
    };
    return agencyDetails;
  });
};

export const fetchAgencyInvoices = createAsyncThunk(
  'overview/fetchAgencyInvoices',
  async (payload: IInvoiceOrOrderFilters) => {
    const response = await ApiService.postData(
      invoiceAndOrderEndPoints.getAgencyInvoices,
      {},
      payload,
    );
    if (response?.data?.data?.presignedURL) {
      const presignedResponse = await ApiService.getData(response.data.data.presignedURL);
      presignedResponse.data.data.presignedURL = response.data.data.presignedURL;
      return presignedResponse.data;
    }
    return response.data;
  },
);
export const fetchAgencyOrders = createAsyncThunk(
  'overview/fetchAgencyOrders',
  async (payload: IInvoiceOrOrderFilters) => {
    const response = await ApiService.postData(
      invoiceAndOrderEndPoints.getAgencyOrders,
      {},
      payload,
    );
    return response.data;
  },
);
export const fetchAdvertiserInvoices = createAsyncThunk(
  'overview/fetchAdvertiserInvoices',
  async (payload: IInvoiceOrOrderFilters) => {
    const response = await ApiService.postData(
      invoiceAndOrderEndPoints.fetchAdvertiserInvoicesURL,
      {},
      payload,
    );
    return response.data;
  },
);

export const fetchAdvertiserOrders = createAsyncThunk(
  'overview/fetchAdvertiserOrders',
  async (payload: IInvoiceOrOrderFilters) => {
    const response = await ApiService.postData(
      invoiceAndOrderEndPoints.fetchAdvertiserOrdersURL,
      {},
      payload,
    );
    return response.data;
  },
);
export const fetchManagedInvoices = createAsyncThunk(
  'overview/fetchManagedInvoices',
  async (payload: IInvoiceOrOrderFilters) => {
    const response = await ApiService.postData(
      invoiceAndOrderEndPoints.fetchManagedInvoicesURL,
      {},
      payload,
    );
    return response.data;
  },
);
export const fetchManagedOrders = createAsyncThunk(
  'overview/fetchManagedOrders',
  async (payload: IInvoiceOrOrderFilters) => {
    const response = await ApiService.postData(
      invoiceAndOrderEndPoints.fetchManagedOrdersURL,
      {},
      payload,
    );
    return response.data;
  },
);

export const downloadUnPaidInvoice = (params: { invoiceNumber: string }) => {
  return ApiService.getData(invoiceAndOrderEndPoints.downloadUnPaidInvoiceURL, params);
};
export const downloadPaidInvoice = (params: { invoiceNumber: string }) => {
  return ApiService.getData(invoiceAndOrderEndPoints.downloadPaidInvoiceURL, params);
};

export const OverviewDataSlice = createSlice({
  name: 'overviewData',
  initialState,
  reducers: {
    updateInvoiceFilters: (state, action) => {
      state.invoiceFilters = action?.payload;
    },
    updateOrderFilters: (state, action) => {
      state.orderFilters = action?.payload;
    },
    updateSelectedInvoices: (state, action) => {
      state.selectedInvoices = action?.payload;
      const selectedItemsDetails = getSelectedItemTotal(state.selectedInvoices);
      state.selectedInvoicesTotal = selectedItemsDetails.total;
      state.selectedInvoicesCount = selectedItemsDetails.count;
    },
    updateSelectedOrders: (state, action) => {
      state.selectedOrders = action?.payload;
      const selectedItemsDetails = getSelectedItemTotal(state.selectedOrders);
      state.selectedOrdersTotal = selectedItemsDetails.total;
      state.selectedOrdersCount = selectedItemsDetails.count;
    },
    setIsPaymentFull: (state, action) => {
      state.isPayFull = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAgencyInvoices.pending, (state) => {
        state.invoicesStatus = 'loading';
      })
      .addCase(fetchAgencyInvoices.fulfilled, (state, action) => {
        const data: IAgencyOpenInvoicesResponse = action.payload.data;
        state.invoicesStatus = 'succeeded';
        state.invoiceTotal = data?.totalBalance;
        state.invoicePresignedURL = data.presignedURL;
        state.creditBalance = {
          totalCreditBalance: data?.agencyTotalCreditBalance,
          totalCreditAdjustments: data?.agencyTotalCreditAdjustments,
          totalCreditPayments: data?.agencyTotalCreditPayments,
          totalCreditPrepayments: data?.agencyTotalCreditPrepayments,
          totalCreditInvoices: data?.agencyTotalCreditInvoices,
        } as ICreditBalance;
        state.invoices = transformInvoicesAndAddOrderNumber(data?.agency?.advertisers || []);
      })
      .addCase(fetchAgencyInvoices.rejected, (state, action) => {
        state.invoicesStatus = 'failed';
        state.invoicesError = action?.error?.message || 'Failed to get invoices';
      })
      .addCase(fetchAgencyOrders.pending, (state) => {
        state.ordersStatus = 'loading';
      })
      .addCase(fetchAgencyOrders.fulfilled, (state, action) => {
        state.ordersStatus = 'succeeded';
        state.ordersTotal = action.payload.data?.totalOpenOrderAmount;
        state.orders = transformOrders(action.payload.data?.agency?.advertisers);
      })
      .addCase(fetchAgencyOrders.rejected, (state, action) => {
        state.ordersStatus = 'failed';
        state.ordersError = action?.error?.message || 'Failed to get orders';
      })
      .addCase(fetchAdvertiserInvoices.pending, (state) => {
        state.invoicesStatus = 'loading';
      })
      .addCase(fetchAdvertiserInvoices.fulfilled, (state, action) => {
        const data: IAdvertiserOrAgencyResponse = action.payload.data;
        state.invoicesStatus = 'succeeded';
        state.invoiceTotal = data?.totalOpenInvoiceAmount;
        state.creditBalance = {
          totalCreditBalance: data?.totalCreditBalance,
          totalCreditAdjustments: data?.totalCreditAdjustments,
          totalCreditPayments: data?.totalCreditPayments,
          totalCreditPrepayments: data?.totalCreditPrepayments,
          totalCreditInvoices: data?.totalCreditInvoices,
        } as ICreditBalance;
        state.invoices = [];
        if (data?.openInvoices?.length) {
          state.invoices = transformInvoicesAndAddOrderNumber([data] || []);
        }
      })
      .addCase(fetchAdvertiserInvoices.rejected, (state, action) => {
        state.invoicesStatus = 'failed';
        state.invoicesError = action?.error?.message || 'Failed to get invoices';
      })
      .addCase(fetchManagedInvoices.pending, (state) => {
        state.managedInvoicesStatus = 'loading';
      })
      .addCase(fetchManagedInvoices.fulfilled, (state, action) => {
        state.managedInvoicesStatus = 'succeeded';
        state.managedInvoiceTotal = action.payload.data?.totalOpenInvoiceAmount;
        state.managedInvoices = transformInvoicesAndAddOrderNumber(
          transformAndAddAdvertiserDetails(action.payload.data),
        );
      })
      .addCase(fetchManagedInvoices.rejected, (state, action) => {
        state.managedInvoicesStatus = 'failed';
        state.managedInvoicesError = action?.error?.message || 'Failed to get managed invoices';
      })

      .addCase(fetchManagedOrders.pending, (state) => {
        state.managedOrdersStatus = 'loading';
      })
      .addCase(fetchManagedOrders.fulfilled, (state, action) => {
        state.managedOrdersStatus = 'succeeded';
        state.managedOrdersTotal = action.payload.data?.totalOpenOrderAmount;
        state.managedOrders = transformAndAddAdvertiserDetails(action.payload.data);
      })
      .addCase(fetchManagedOrders.rejected, (state, action) => {
        state.managedOrdersStatus = 'failed';
        state.managedOrdersError = action?.error?.message || 'Failed to get managed orders';
      })

      .addCase(fetchAdvertiserOrders.pending, (state) => {
        state.ordersStatus = 'loading';
      })
      .addCase(fetchAdvertiserOrders.fulfilled, (state, action) => {
        state.ordersStatus = 'succeeded';
        state.ordersTotal = action.payload.data?.totalBalance;
        state.orders = [];
        if (action.payload?.data?.openOrders?.length) {
          state.orders = transformOrders([action.payload.data]);
        }
      })
      .addCase(fetchAdvertiserOrders.rejected, (state, action) => {
        state.ordersStatus = 'failed';
        state.ordersError = action?.error?.message || 'Failed to get orders';
      });
  },
});
export const {
  updateInvoiceFilters,
  updateOrderFilters,
  updateSelectedInvoices,
  updateSelectedOrders,
  setIsPaymentFull,
} = OverviewDataSlice.actions;

export default OverviewDataSlice.reducer;
