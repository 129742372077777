import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';

const CustomerSupportFooter = ({
  msg = 'Need help with your payment? Contact us at',
}: {
  msg?: string;
}) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const { contactInfo } = useSelector((state: RootState) => state.helpData);
  return (
    <Typography component='div' color={grey[500]} variant='subtitle2' mt={theme.spacing(20)}>
      {msg}
      <Typography
        component='span'
        color='primary'
        variant='subtitle2'
        sx={{ ml: 4, mr: 4, fontWeight: 500, textDecorationLine: 'underline' }}
      >
        {contactInfo?.phoneNumber}
      </Typography>
      or{'  '}
      <a href={`mailto:${contactInfo?.email}`}>
        <Typography
          color='primary'
          sx={{
            ml: 2,
            textDecorationLine: 'underline',
            fontWeight: 500,
          }}
          component='span'
          variant='subtitle2'
        >
          {contactInfo?.email}
        </Typography>
      </a>
    </Typography>
  );
};
export default CustomerSupportFooter;
