import React from 'react';
import { StyledAvatar } from './AvatarStyles';

interface IAvatarProps {
  children: any;
  size?: string;
  bgcolor?: string;
  sx?: any;
}

export const Avatar = ({ children, size, bgcolor = 'primary', sx }: IAvatarProps) => {
  return (
    <StyledAvatar size={size} bgcolor={bgcolor} sx={sx}>
      {children}
    </StyledAvatar>
  );
};
export default Avatar;
