import React from 'react';
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
import SpectrumTextField from '../../components/Spectrum/TextField/SpectrumTextField';
import SpectrumSelectField from '../../components/Spectrum/Select/SpectrumSelect';
import { INewUserRegistrationDetails } from '../../redux/auth/AuthModels';

interface IYourInformation {
  formData: INewUserRegistrationDetails;
  handleChange: (x: React.ChangeEvent<HTMLInputElement>) => void;
}

const YourInformation = ({ formData, handleChange }: IYourInformation) => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Typography variant='h4' marginBottom={theme.spacing(8)}>
          Your Information
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        gap={theme.spacing(12)}
        marginY={theme.spacing(12)}
      >
        <SpectrumTextField
          required
          label='First Name'
          variant='outlined'
          size='small'
          name='firstName'
          onChange={handleChange}
          value={formData.firstName}
        />
        <SpectrumTextField
          required
          label='Last Name'
          variant='outlined'
          size='small'
          name='lastName'
          onChange={handleChange}
          value={formData.lastName}
        />
        <SpectrumTextField
          required
          label='Your Company'
          variant='outlined'
          size='small'
          name='companyName'
          onChange={handleChange}
          value={formData.companyName}
        />
        <SpectrumTextField
          required
          label='Customer ID'
          variant='outlined'
          size='small'
          name='customerId'
          onChange={handleChange}
          value={formData.customerId}
        />
        <SpectrumSelectField
          label='Is your company an Advertiser or an Agency?'
          variant='outlined'
          size='small'
          name='companyType'
          onChange={handleChange}
        >
          <MenuItem value='advertiser'>Advertiser</MenuItem>
          <MenuItem value='agency'>Agency</MenuItem>
        </SpectrumSelectField>
        {formData.companyType === 'advertiser' && (
          <SpectrumSelectField
            label='Does your company work with other Agencies?'
            variant='outlined'
            size='small'
            name='worksWithOtherAgencies'
            onChange={handleChange}
          >
            <MenuItem value='true'>Yes</MenuItem>
            <MenuItem value='false'>No</MenuItem>
          </SpectrumSelectField>
        )}
        <SpectrumTextField
          required
          label='Account Executive'
          variant='outlined'
          size='small'
          name='accountExecutive'
          onChange={handleChange}
          value={formData.accountExecutive}
        />
        <SpectrumTextField
          label='Recent Invoice Number'
          variant='outlined'
          size='small'
          name='recentInvoiceNumber'
          onChange={handleChange}
          value={formData.recentInvoiceNumber}
        />
      </Box>
    </>
  );
};

export default YourInformation;
