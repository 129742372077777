import React, { useState } from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';
import InviteUserDetails from './InviteUserDetails';
import { fetchUserAccountInformation } from '../../../redux/account/AccountSlice';
import { IGetUserAccountInfoPayload } from '../../../redux/account/AccountModels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { Actions, Roles } from '../Members/MemberRoleMappings';

const InviteUser = () => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [dialogStatus, setDialogStatus] = useState(false);
  const { userDetails } = useSelector((state: RootState) => state.authData);

  const isTeamMember = userDetails?.tblUser?.tblUserRole?.name === Roles.TEAM_MEMBER;

  const { filters } = useSelector((state: RootState) => state.accountData);

  const handleClose = (result?: { type: Actions } | null) => {
    setDialogStatus(false);
    if (result?.type === Actions.INVITE_USER) {
      dispatch(fetchUserAccountInformation(filters as IGetUserAccountInfoPayload));
    }
  };

  const handleOpen = () => {
    setDialogStatus(true);
  };
  return (
    <div>
      <Button variant='contained' size='large' onClick={handleOpen} disabled={isTeamMember}>
        <AddIcon />
        {isMobile ? '' : 'Invite User'}
      </Button>
      <SpectrumDialog open={dialogStatus} onClose={() => handleClose()}>
        <InviteUserDetails handleClose={handleClose} />
      </SpectrumDialog>
    </div>
  );
};
export default InviteUser;
