import React from 'react';
import PageContentContainer from '../../../components/PageContentContainer/PageContentContainer';
import { Box, Typography } from '@mui/material';
import InvoiceAndOrderSharingFilters from './InvoiceAndOrderSharingFilters';
import InvoiceAndOrdersList from './InvoiceAndOrdersList';

const InvoiceAndOrderSharing = () => {
  return (
    <PageContentContainer>
      <Box sx={{ mb: 10 }}>
        <Typography sx={{ mb: 2 }} variant='eyebrow'>
          ACCOUNT
        </Typography>
        <Typography variant='h2'>Invoice and Order Sharing</Typography>
        <Typography variant='paragraph2'>
          Give your advertisers access to view invoices and orders for their account.
        </Typography>
      </Box>
      <Box>
        <InvoiceAndOrderSharingFilters />
      </Box>
      <Box mt={20}>
        <InvoiceAndOrdersList />
      </Box>
    </PageContentContainer>
  );
};

export default InvoiceAndOrderSharing;
