import React, { useEffect, useState } from 'react';
import { Alert, Box, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  DescriptionOutlined,
  ExpandCircleDownOutlined,
  LocalPhone,
  Mail,
} from '@mui/icons-material';

import TermsAndCondition from './TermsAndCondition';

import {
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledFAQAccordion,
  StyledHelpAccordion,
  StyledHelpBox,
} from './HelpStyles';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import { RootState, StoreDispatch } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactInfo, fetchFaqs } from '../../redux/help/HelpSlice';
import { ComponentLoader } from '../../components/Loaders/Loader';

export default function Help() {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [openTermsView, setOpenTermsView] = useState(false);

  const {
    helpText,
    faqData,
    faqDataStatus,
    faqDataError,
    contactInfo,
    contactInfoError,
    contactInfoStatus,
  } = useSelector((state: RootState) => state.helpData);

  useEffect(() => {
    dispatch(fetchFaqs());
    if (!contactInfo?.email || !contactInfo?.phoneNumber) {
      dispatch(fetchContactInfo());
    }
  }, []);

  const isLoading = faqDataStatus === 'loading';
  const isContactInfoLoading = contactInfoStatus === 'loading';

  return (
    <PageContentContainer>
      <StyledHelpBox marginX={isMobile ? theme.spacing(12) : 0}>
        <Typography variant='eyebrow' mb={2}>
          GET HELP
        </Typography>
        <Typography variant='h3'>Support</Typography>
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <>
            {faqDataError && (
              <Box my={theme.spacing(6)}>
                <Alert severity='error' variant='outlined'>
                  <Typography variant='paragraph2' component='span' color='error'>
                    {faqDataError}
                  </Typography>
                </Alert>
              </Box>
            )}
            {contactInfoError && (
              <Box my={theme.spacing(6)}>
                <Alert severity='error' variant='outlined'>
                  <Typography variant='paragraph2' component='span' color='error'>
                    {contactInfoError}
                  </Typography>
                </Alert>
              </Box>
            )}
            <Grid container spacing={2} mt={16}>
              <Grid>
                <Box display='flex' flexDirection={isMobile ? 'column' : 'row'}>
                  <Box>
                    <Grid className='new-user'>
                      <StyledHelpAccordion defaultExpanded>
                        <StyledAccordionSummary
                          aria-controls='panel-head-content'
                          expandIcon={<ExpandCircleDownOutlined color='primary' />}
                        >
                          <Typography variant='eyebrow' color='primary'>
                            {helpText?.question}
                          </Typography>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                          <pre>{helpText?.answer}</pre>
                        </StyledAccordionDetails>
                      </StyledHelpAccordion>
                    </Grid>
                    <Grid className='faqs'>
                      <Typography mt={12} mb={4} variant='h4'>
                        FAQs
                      </Typography>
                      {(faqData || []).map((faq, index) => (
                        <StyledFAQAccordion key={index}>
                          <StyledAccordionSummary
                            aria-controls={`panel${index}a-content`}
                            expandIcon={<ExpandCircleDownOutlined color='primary' />}
                          >
                            <Typography variant='h6'>{faq.question}</Typography>
                          </StyledAccordionSummary>
                          <StyledAccordionDetails>
                            <pre>{faq?.answer}</pre>
                          </StyledAccordionDetails>
                        </StyledFAQAccordion>
                      ))}

                      <Typography mt={16} mb={8} variant='h4'>
                        Terms and Conditions
                      </Typography>
                      <Box
                        display='flex'
                        alignItems='center'
                        onClick={() => setOpenTermsView((prev) => !prev)}
                        mb={15}
                      >
                        <DescriptionOutlined color='primary' />
                        <Typography variant='h6' color='primary'>
                          View Terms and Conditions
                        </Typography>
                      </Box>
                      {openTermsView && <TermsAndCondition />}
                    </Grid>
                  </Box>
                  <Box display='flex'>
                    {!isMobile && (
                      <Divider
                        orientation='vertical'
                        variant='middle'
                        flexItem
                        sx={{
                          marginBottom: 8,
                          marginLeft: 12,
                          height: 140,
                        }}
                      />
                    )}
                    <Box
                      ml={isMobile ? 0 : 8}
                      mt={isMobile ? 16 : 0}
                      sx={{
                        maxWidth: theme.spacing(115),
                      }}
                      display='flex'
                      flexDirection='column'
                      gap={4}
                    >
                      <Typography variant='eyebrow'>Contact</Typography>
                      {isContactInfoLoading ? (
                        <ComponentLoader />
                      ) : (
                        <>
                          <Box display='flex' alignItems='center' gap={4}>
                            <Mail color='primary' />
                            <a href={`mailto:${contactInfo?.email}`}>
                              <Typography
                                color='black'
                                sx={{
                                  textDecorationLine: 'underline',
                                }}
                                variant='body2'
                              >
                                {contactInfo?.email}
                              </Typography>
                            </a>
                          </Box>
                          <Box display='flex' alignItems='center' gap={4}>
                            <LocalPhone color='primary' />
                            <a href={`tel:${contactInfo?.phoneNumber}`}>
                              <Typography
                                color='black'
                                variant='body2'
                                sx={{
                                  textDecorationLine: 'underline',
                                }}
                              >
                                {contactInfo?.phoneNumber}
                              </Typography>
                            </a>
                          </Box>
                          <Typography variant='body2' color={theme.palette.grey[400]}>
                            {contactInfo?.availabilityText}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </StyledHelpBox>
    </PageContentContainer>
  );
}
