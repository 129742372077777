import React, { ChangeEvent, useState } from 'react';
import { format } from 'date-fns';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { formatAmount } from '../../../utils/common';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {
  InvoiceOrOrderPaymentStatus,
  IOpenInvoice,
  OrderOrInvoiceTypes,
} from '../../../redux/overview/OverviewModels';
import InvoiceOrOrderFooter from './InvoiceOrOrderFooter';
import InvoiceOrOrderDetailsRow from './InvoiceOrOrderDetailsRow';

interface InvoiceDetailsProps {
  paymentStatus?: InvoiceOrOrderPaymentStatus;
  invoice: IOpenInvoice;
  selectable?: boolean;
  isSelected?: boolean;
  onSelect?: (invoice: IOpenInvoice, checked: boolean) => void;
  onCollapse?: (invoiceId: any, status: boolean) => void;
  collapseStatus?: boolean;
}

const InvoiceDetails = ({
  paymentStatus = InvoiceOrOrderPaymentStatus.Paid,
  invoice,
  isSelected,
  selectable,
  onSelect,
  onCollapse,
  collapseStatus,
}: InvoiceDetailsProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;


  const isPaid = paymentStatus === InvoiceOrOrderPaymentStatus.Paid;

  const toggleStatus = () => {
    onCollapse?.(invoice.invoiceNumber,!collapseStatus)
  };

  const renderSummaryRow = (label: string, value: number) => (
    <Box display='flex' justifyContent='space-between' gap={theme.spacing(4)} mb={theme.spacing(4)}>
      <Typography
        display='inline-flex'
        justifyContent='flex-end'
        fontSize='0.875rem'
        fontWeight={700}
        color={primary[800]}
        sx={{
          lineHeight: '20px',
          width: '100%',
        }}
      >
        {label}:
      </Typography>
      <Typography
        display='inline-flex'
        justifyContent='flex-end'
        sx={{
          width: '96px',
        }}
        variant='subtitle'
      >
        {formatAmount(value)}
      </Typography>
    </Box>
  );

  const handleSelection = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onSelect) {
      onSelect(invoice, checked);
    }
  };

  return (
    <Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          {selectable ? (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={invoice.inProgress}
                  checked={isSelected}
                  onChange={handleSelection}
                />
              }
              label={<Typography variant='h6'>#{invoice.invoiceNumber}</Typography>}
            />
          ) : (
            <Typography variant='h6'>#{invoice.invoiceNumber}</Typography>
          )}
        </Box>
        <Box display='flex' flexDirection='column' alignItems='end'>
          {invoice.inProgress ? (
            <Chip sx={{ ml: 4 }} label='In Progress' color='primary' />
          ) : (
            <>
              <Typography variant='h6'>{formatAmount(invoice.openAmount)}</Typography>
              <Typography variant='paragraph2' fontSize={14}>
                due {format(new Date(invoice.dueDate), 'MMM dd, yyyy')}
              </Typography>
              {invoice?.paymentDate && (
                <Typography variant='paragraph2' fontSize={14}>
                  paid {format(new Date(invoice.paymentDate), 'MMM dd, yyyy')}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
      <Collapse in={collapseStatus} unmountOnExit={true}>
        <Box
          sx={{
            border: `${theme.spacing(0.5)} solid ${grey[200]}`,
            background: grey[50],
            px: theme.spacing(8),
            mt: theme.spacing(8),
            width: '100%',
          }}
        >
          <InvoiceOrOrderDetailsRow
            label='Invoice Date'
            value={format(new Date(invoice.invoiceDate), 'MM/dd/yyyy')}
          />
          <InvoiceOrOrderDetailsRow label='Order Number' value={invoice?.orderNumber} />
          <InvoiceOrOrderDetailsRow label='Estimate Number' value={invoice.estimateNumber} />
          <InvoiceOrOrderDetailsRow label='Account Executive' value={invoice.accountExecutive} />
          <Box py={theme.spacing(6)}>
            <Typography variant='eyebrow' pb={theme.spacing(8)}>
              Summary
            </Typography>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              width='fit-content'
              ml='auto'
              mr='0'
            >
              <Stack spacing={theme.spacing(8)}>
                <Box>
                  {renderSummaryRow('Original Amount', invoice.originalAmount)}
                  {invoice.adjustmentAmount < 0 && (
                    <>
                      {renderSummaryRow('Adjustment(s)', invoice.adjustmentAmount)}
                      <Divider
                        sx={{
                          borderStyle: 'dashed',
                          mb: theme.spacing(4),
                          ml: theme.spacing(25),
                        }}
                      />
                      {renderSummaryRow('After Adjustments', invoice.afterAdjustmentAmount)}
                    </>
                  )}
                </Box>
                <Box>
                  {invoice.promotionalDiscount < 0 &&
                    renderSummaryRow('Promotional Discount', invoice.promotionalDiscount)}
                  {invoice.agencyDiscount < 0 &&
                    renderSummaryRow('Agency Discount', invoice.agencyDiscount)}
                  {invoice.taxAmount > 0 && renderSummaryRow('Tax Amount', invoice.taxAmount)}
                  {invoice.repFirmDiscount < 0 &&
                    renderSummaryRow('Rep Firm Discount', invoice.repFirmDiscount)}
                  <Divider
                    sx={{
                      borderStyle: 'dashed',
                      ml: theme.spacing(40),
                    }}
                  />
                </Box>
                {renderSummaryRow('Net Amount', invoice.netAmount)}
                {renderSummaryRow('Payments', invoice.payments)}
              </Stack>
            </Box>
          </Box>
          <Divider style={{ borderColor: theme.palette.common.black }} />
          <Box display='flex' justifyContent='space-between' my={theme.spacing(4)}>
            <Typography variant='h6' fontSize='1.125rem' color={primary[800]}>
              Open Amount
            </Typography>
            <Typography variant='h6' color={primary[800]}>
              {formatAmount(invoice.openAmount)}
            </Typography>
          </Box>
          <InvoiceOrOrderFooter
            type={OrderOrInvoiceTypes.Invoice}
            isPaid={isPaid}
            number={invoice?.invoiceNumber}
          />
        </Box>
      </Collapse>
      <Box display='flex' flexDirection='column' alignItems='end'>
        <Button
          sx={{
            mt: theme.spacing(8),
            ml: 'auto',
            padding: 0,
          }}
          onClick={toggleStatus}
        >
          {collapseStatus ? <RemoveIcon /> : <AddIcon />}
          <Typography fontWeight={500} fontSize='0.875rem' ml={theme.spacing(2)}>
            {collapseStatus ? 'Hide Details' : 'Show Details'}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default InvoiceDetails;
