import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { StyledSpectrumDialog } from './SpectrumDialogStyles';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function SpectrumDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, pl: 20, pr: 20, pt: children ? 10 : 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface ISpectrumDialogProps {
  open: boolean;
  title?: React.ReactNode;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  onClose: () => void;
  spacing?: string;
}

export default function SpectrumDialog({
  open,
  onClose,
  children,
  actions,
  title,
  spacing = '0 20px 20px 20px',
}: ISpectrumDialogProps) {
  return (
    <div>
      <StyledSpectrumDialog
        fullWidth={true}
        maxWidth={'tablet'}
        onClose={onClose}
        aria-labelledby='spectrum-dialog-title'
        open={open}
        disableEscapeKeyDown={true}
      >
        <SpectrumDialogTitle id='spectrum-dialog-title' onClose={onClose}>
          {title}
        </SpectrumDialogTitle>
        <DialogContent sx={{ p: spacing }}>{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </StyledSpectrumDialog>
    </div>
  );
}
