import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';
import { Box, Button, Typography, useTheme } from '@mui/material';
import {
  fetchUpcomingPaymentsCounts,
  resetUpcomingPaymentsCount,
} from '../../redux/payment/PaymentSlice';
import { RootState, StoreDispatch } from '../../redux/Store';

interface IProps {
  open: boolean;
  close: () => void;
  selectedPaymentMethodIdToDelete: string | null;
  remove: (paymentMethodId: string) => void;
}

const RemoveStoredPaymentMethod = ({
  open,
  close,
  selectedPaymentMethodIdToDelete,
  remove,
}: IProps) => {
  const dispatch: StoreDispatch = useDispatch();
  const upcomingPaymentsCounts = useSelector(
    (state: RootState) => state.paymentData.upcomingPaymentsCounts,
  );
  const [removeError, setRemoveError] = React.useState('');
  const theme = useTheme();

  const handleRemovePaymentMethod = () => {
    if (selectedPaymentMethodIdToDelete) {
      remove(selectedPaymentMethodIdToDelete);
      dispatch(resetUpcomingPaymentsCount());
      close();
    } else {
      setRemoveError('Please exit and try again.');
    }
  };

  useEffect(() => {
    if (selectedPaymentMethodIdToDelete && open) {
      const stringID = selectedPaymentMethodIdToDelete.toString();
      dispatch(fetchUpcomingPaymentsCounts(stringID));
    }
  }, [selectedPaymentMethodIdToDelete, dispatch, open]);

  const memoizedCountDisplay = React.useMemo(() => {
    let countDisplay = '';
    if (upcomingPaymentsCounts !== 0) {
      countDisplay = `${upcomingPaymentsCounts} ${
        upcomingPaymentsCounts > 1 ? 'payments' : 'payment'
      } using this payment method will be cancelled.`;
    }
    return countDisplay;
  }, [upcomingPaymentsCounts]);

  const handleClose = () => {
    dispatch(resetUpcomingPaymentsCount());
    close();
  };

  return (
    <>
      <SpectrumDialog open={open} onClose={handleClose}>
        <Box>
          <Typography variant='h2'>Are you sure you want to remove this payment method?</Typography>
          <Typography variant='paragraph2' color={theme.palette.grey[500]} mt={5}>
            Removing this payment method will cancel all scheduled payments and auto payments using
            this payment method.
          </Typography>
          {upcomingPaymentsCounts !== 0 && (
            <Typography
              mb={15}
              sx={{ fontWeight: 'bold' }}
              variant='paragraph2'
              color={theme.palette.error.main}
              mt={10}
            >
              {' '}
              {memoizedCountDisplay && memoizedCountDisplay}{' '}
            </Typography>
          )}
          <p>{removeError}</p>
          <Button
            variant='contained'
            size='medium'
            onClick={handleRemovePaymentMethod}
            sx={{ fontWeight: 'normal' }}
          >
            Confirm Removal
          </Button>
          <br />
          <Button
            onClick={handleClose}
            variant='text'
            size='medium'
            sx={{
              color: theme.palette.grey[500],
              mt: 5,
            }}
          >
            Cancel
          </Button>
        </Box>
      </SpectrumDialog>
    </>
  );
};

export default RemoveStoredPaymentMethod;
