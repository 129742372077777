import { styled } from '@mui/material/styles';

export const LoaderContainer = styled('div')(({ theme }) => {
  return {
    '&.pageLoader': {
      height: '60vh' /* to make it responsive */,
      width: '100vw' /* to make it responsive */,
      overflow: 'hidden' /* to remove scrollbars */,
      zIndex: 99999 /* to make it appear on topmost part of the page */,
    },
    '.pageLoaderImg': {
      position: 'relative',
      top: `calc(50% - ${theme.spacing(30)})`,
      left: `calc(50% - ${theme.spacing(30)})`,
    },
    '&.componentLoader': {
      position: 'relative',
      left: 0,
      overflow: 'hidden' /* to remove scrollbars */,
      zIndex: 99999 /* to make it appear on topmost part of the page */,
      minHeight: theme.spacing(3),
      '&.overlay': {
        position: 'absolute',
        top: `calc(50% - ${theme.spacing(30)})`,
        width: '100%',
      },
    },
    '.componentLoaderImg': {
      position: 'relative',
      left: `calc(50% - ${theme.spacing(20)})`,
    },
  };
});
