import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import SpectrumTextField from '../../../components/Spectrum/TextField/SpectrumTextField';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { IInviteUserDetails } from '../../../redux/account/AccountModels';
import { isValidEmail } from '../../../utils/validation';

interface IEmailStepProps {
  userDetails: IInviteUserDetails;
  userErrorDetails: IInviteUserDetails;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  setUserErrorDetails: (errorDetails: IInviteUserDetails) => void;
}

const EmailStep = ({
  userDetails,
  userErrorDetails,
  handleChange,
  handleNext,
  setUserErrorDetails,
}: IEmailStepProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;

  const onNext = () => {
    if (isValidEmail(userDetails?.email ?? '')) {
      handleNext();
    } else {
      setUserErrorDetails({ ...userErrorDetails, email: 'Please enter valid email address' });
    }
  };

  return (
    <div>
      <Typography variant='h2' mb={5}>
        Invite Team Member
      </Typography>
      <Typography variant='body1' mb={10} color={grey[500]}>
        Invite users to view and pay invoices, schedule payments, and more. Permissions for each
        user can be set in the next steps.
      </Typography>
      <SpectrumTextField
        name='email'
        value={userDetails?.email}
        onChange={handleChange}
        label='Email Address'
        size='medium'
        error={userErrorDetails?.email}
        helperText={userErrorDetails?.email}
      ></SpectrumTextField>
      <Box sx={{ mt: 15, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onNext} variant='contained' size='large' disabled={!userDetails?.email}>
          Set User Role
        </Button>
      </Box>
    </div>
  );
};
export default EmailStep;
