import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as SpectrumLogo } from '../../assets/images/spectrum-logo-white.svg';
import DrawerComponent from './Drawer/Drawer';
import UserMenu from '../UserMenu/UserMenu';

// Types
import { ILink } from '../../types';

//Styles
import { StyledAppBar } from './NavStyles';
import { PATHS } from '../../routes/Routes';
import { IUserDetails } from '../../redux/auth/AuthModels';
import NavMenuLink from './NavMenuLink';

//redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';

interface INavProps {
  links: Array<ILink>;
  isAuthenticated: boolean;
  isUserRegistered: boolean;
  productTitle: string;
  user: IUserDetails | null;
}

const Nav = ({
  links,
  isAuthenticated = true,
  isUserRegistered,
  productTitle,
  user,
}: INavProps) => {
  const activeClassName = 'link link-active';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const { userDetails } = useSelector((state: RootState) => state.authData);

  const renderNavLinks = () => {
    if (isMobile || !isUserRegistered) {
      return false;
    }
    if (!userDetails?.tblUser) {
      return false;
    }
    return !(
      userDetails.tblUser.tblUserStatus.name === 'NeedsReview' ||
      userDetails.tblUser.tblUserStatus.name === 'Pending'
    );
  };

  return (
    <StyledAppBar position='static'>
      <div className='logo-container left'>
        <div className='spectrum-logo' role='presentation'>
          <NavLink to='/'>
            <SpectrumLogo />
          </NavLink>
        </div>
        <Typography className='project-logo' variant='productTitle'>
          {productTitle}
        </Typography>
      </div>
      {isAuthenticated && (
        <>
          {renderNavLinks() && (
            <div className='links-container center'>
              {links.map((link, index) => {
                return (
                  <Fragment key={index}>
                    {link?.children ? (
                      <NavMenuLink link={link} />
                    ) : (
                      <NavLink
                        className={({ isActive }) => (isActive ? activeClassName : 'link')}
                        to={link.link}
                      >
                        {link?.label}
                      </NavLink>
                    )}
                  </Fragment>
                );
              })}
            </div>
          )}
          <div className='right'>
            {!isMobile && (
              <div className='links-container'>
                <NavLink
                  className={({ isActive }) => (isActive ? activeClassName : 'link')}
                  to={PATHS.HELP}
                >
                  Get Help
                </NavLink>
              </div>
            )}
            <UserMenu user={user}></UserMenu>
            {isMobile && <DrawerComponent links={isUserRegistered ? links : []} />}
          </div>
        </>
      )}
    </StyledAppBar>
  );
};

export default Nav;
