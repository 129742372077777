import { IContactInfo, IFaq, IHelpState } from './HelpModels';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../services/ApiService';
import { helpApiEndPoints } from '../../constants/apiConstants';
import { formatPhoneNumber } from '../../utils/common';

const initialState: IHelpState = {
  contactInfo: {} as IContactInfo,
  contactInfoStatus: 'idle',
  contactInfoError: null,
  faqData: [],
  faqDataStatus: 'idle',
  faqDataError: null,
  helpText: {} as IFaq,
};
export const fetchFaqs = createAsyncThunk('help/FetchFaqs', async () => {
  const response = await ApiService.getData(helpApiEndPoints.getFaqsURL, {});
  return response.data;
});
export const fetchContactInfo = createAsyncThunk('help/FetchContactInfo', async () => {
  const response = await ApiService.getData(helpApiEndPoints.getContactInfoURL, {});
  return response.data;
});
export const HelpDataSlice = createSlice({
  name: 'helpData',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFaqs.pending, (state) => {
        state.faqDataStatus = 'loading';
        state.faqDataError = '';
      })
      .addCase(fetchFaqs.fulfilled, (state, action) => {
        state.faqDataStatus = 'succeeded';
        const sortedFaqs = (action.payload.data || []).sort(
          (faq1: IFaq, faq2: IFaq) => faq1?.priority - faq2?.priority,
        );
        const helpTextFaqIndex = sortedFaqs.findIndex((faq: IFaq) => !faq.isFaq);
        if (helpTextFaqIndex > -1) {
          state.helpText = sortedFaqs[helpTextFaqIndex];
          sortedFaqs.splice(helpTextFaqIndex, 1);
        }
        state.faqData = sortedFaqs;
      })
      .addCase(fetchFaqs.rejected, (state, action) => {
        state.faqDataStatus = 'failed';
        state.faqDataError = action?.error?.message || 'Failed to fetch FAQs';
      })
      .addCase(fetchContactInfo.pending, (state) => {
        state.contactInfoStatus = 'loading';
        state.contactInfoError = '';
      })
      .addCase(fetchContactInfo.fulfilled, (state, action) => {
        state.contactInfoStatus = 'succeeded';
        const data = action?.payload?.data;
        state.contactInfo = {
          ...data,
          phoneNumber: data?.phoneNumber ? formatPhoneNumber(data?.phoneNumber) : '',
        };
      })
      .addCase(fetchContactInfo.rejected, (state, action) => {
        state.contactInfoStatus = 'failed';
        state.contactInfoError = action?.error?.message || 'Failed to fetch contact info';
      });
  },
});
export default HelpDataSlice.reducer;
