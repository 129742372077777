import React, { useEffect } from 'react';
import { Alert, Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import InvoiceOrOrders from './Invoices/InvoiceOrOrders';
import { formatAmount } from '../../utils/common';
import { PATHS } from '../../routes/Routes';
import { NavLink, useNavigate } from 'react-router-dom';
import { IInvoiceOrOrderFilters, OrderOrInvoiceTypes } from '../../redux/overview/OverviewModels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import {
  fetchAdvertiserInvoices,
  fetchAgencyInvoices,
  updateInvoiceFilters,
  updateSelectedInvoices,
} from '../../redux/overview/OverviewSlice';
import { ClientTypes } from '../../redux/auth/AuthModels';
import { ComponentOverlayLoader } from '../../components/Loaders/Loader';
import { ColorPartial } from '@mui/material/styles/createPalette';
import ManagedInvoices from './ManagedInvoices';
import { transformToSelectedInvoiceItem } from '../InvoicesAndOrders/InvoiceAndOrderUtils';
import InvoiceOrOrderFilters from './InvoiceOrOrderFilters';
import CreditBalanceInfo from './Invoices/CreditBalanceInfo';

const OpenInvoices = () => {
  const dispatch: StoreDispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const {
    invoiceFilters,
    invoices,
    invoicesStatus,
    invoicesError,
    selectedInvoices,
    selectedInvoicesTotal,
    managedInvoices,
    creditBalance,
  } = useSelector((state: RootState) => state.overview);
  const { contactInfo } = useSelector((state: RootState) => state.helpData);
  const { clientType } = useSelector((state: RootState) => state.authData);
  const isAdvertiser = clientType === ClientTypes.Advertiser;
  const isAgency = clientType === ClientTypes.Agency;
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  useEffect(() => {
    if (clientType && clientType === ClientTypes.Agency) {
      dispatch(fetchAgencyInvoices(invoiceFilters));
    }
    if (clientType && clientType === ClientTypes.Advertiser) {
      dispatch(fetchAdvertiserInvoices(invoiceFilters));
    }
  }, [clientType, invoiceFilters]);

  const handlePayAll = () => {
    selectAllInvoices();
    navigate(PATHS.MAKE_INVOICE_PAYMENT_INFO);
  };

  const selectAllInvoices = () => {
    // Filtering out any in progress invoices
    const availableInvoices = invoices
      .map((invoice) => {
        return {
          ...invoice,
          openInvoices: (invoice?.openInvoices || []).filter(
            (openInvoice) => !openInvoice.inProgress,
          ),
        };
      })
      .filter((invoice) => invoice.openInvoices.length);

    let selectedInvoices = transformToSelectedInvoiceItem(availableInvoices, false);
    if (managedInvoices) {
      selectedInvoices = selectedInvoices.concat(
        transformToSelectedInvoiceItem(managedInvoices, true),
      );
    }
    dispatch(updateSelectedInvoices(selectedInvoices));
  };

  const handleUpdateFilters = (filters: IInvoiceOrOrderFilters) => {
    dispatch(updateInvoiceFilters(filters));
  };

  const isInvoicesLoading = invoicesStatus === 'loading';

  return (
    <>
      <Typography variant='eyebrow'>MAKE A PAYMENT</Typography>
      <Typography variant='h4'>Pay By Invoice</Typography>
      <InvoiceOrOrderFilters filters={invoiceFilters} setFilters={handleUpdateFilters} />
      <Box
        sx={{
          position: 'relative',
          minHeight: 100,
        }}
      >
        {invoicesError && (
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {invoicesError}
            </Typography>
          </Alert>
        )}
        {isInvoicesLoading ? (
          <Box sx={{ mt: 30 }}>
            <ComponentOverlayLoader />
          </Box>
        ) : (
          <>
            {creditBalance?.totalCreditBalance !== 0 && (
              <Box mb={10}>
                <CreditBalanceInfo
                  label={isAgency ? 'Available Agency Credit Balance' : 'Available Credit Balance'}
                  data={creditBalance}
                  contactInfo={contactInfo}
                />
              </Box>
            )}
            {invoices?.length > 0 ? (
              <InvoiceOrOrders data={invoices} type={OrderOrInvoiceTypes.Invoice} />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 100,
                }}
              >
                <Typography variant='body2' color={grey[500]}>
                  No Invoices Found
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      {isAdvertiser && <ManagedInvoices />}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 10, mb: 10 }}>
        <Typography variant='h6' mt='12' mb='8'>
          Selected Total:
        </Typography>
        <Typography variant='h6' mt='12' mb='8'>
          {formatAmount(selectedInvoicesTotal)}
        </Typography>
      </Box>
      <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={theme.spacing(4)}>
        <Button
          variant='outlined'
          size='large'
          disabled={!selectedInvoices?.length}
          sx={{
            width: !isMobile ? '200px' : '100%',
          }}
          onClick={() => navigate(PATHS.MAKE_INVOICE_PAYMENT_INFO)}
        >
          Pay Selected
        </Button>
        <Button
          onClick={handlePayAll}
          disabled={!(invoices?.length || managedInvoices?.length)}
          variant='contained'
          size='large'
          sx={{ width: !isMobile ? '200px' : '100%' }}
        >
          Pay All
        </Button>
        <NavLink to={PATHS.SETUP_AUTO_PAY} style={{ textDecoration: 'none', marginLeft: 'auto' }}>
          <Typography
            variant='subtitle2'
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(1),
              color: theme.palette.primary.main,
              fontWeight: 600,
              mt: 12,
            }}
          >
            Set Up Autopay
          </Typography>
        </NavLink>
      </Box>
    </>
  );
};

export default OpenInvoices;
