import React from 'react';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './InvoiceStyles';
import {
  IAdvertiserOrAgency,
  IOpenInvoice,
  IOpenOrder,
  ISelectedInvoiceOrOrderItem,
  OrderOrInvoiceTypes,
} from '../../../redux/overview/OverviewModels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { ClientTypes } from '../../../redux/auth/AuthModels';
import {
  updateSelectedInvoices,
  updateSelectedOrders,
} from '../../../redux/overview/OverviewSlice';
import { formatAmount } from '../../../utils/common';
import Invoice from '../Invoice';
import Order from '../Order';
import CreditBalanceInfo from './CreditBalanceInfo';

interface InvoiceOrOrdersProps {
  data: IAdvertiserOrAgency[];
  type: OrderOrInvoiceTypes;
}

const InvoiceOrOrders = ({ data, type }: InvoiceOrOrdersProps) => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();

  const { contactInfo } = useSelector((state: RootState) => state.helpData);
  const { clientType } = useSelector((state: RootState) => state.authData);
  const { selectedInvoices, selectedOrders } = useSelector((state: RootState) => state.overview);
  const isInvoice =
    type === OrderOrInvoiceTypes.Invoice || type === OrderOrInvoiceTypes.ManagedInvoice;
  const isManagedInvoice = type === OrderOrInvoiceTypes.ManagedInvoice;
  const isManagedOrder = type === OrderOrInvoiceTypes.ManagedOrder;
  const isOrder = type === OrderOrInvoiceTypes.Order || type === OrderOrInvoiceTypes.ManagedOrder;
  const showAdvertiserOrAgencyName =
    clientType === ClientTypes.Agency || isManagedOrder || isManagedInvoice;

  const handleSelection = (advertiserOrAgency: IAdvertiserOrAgency, invoice: IOpenInvoice) => {
    const updatedSelection: ISelectedInvoiceOrOrderItem[] = JSON.parse(
      JSON.stringify(selectedInvoices),
    );
    if (updatedSelection.length) {
      const selectedAdvertiserIndex = updatedSelection.findIndex(
        (selectedInvoice) =>
          selectedInvoice?.advertiserOrAgencyId === advertiserOrAgency?.advertiserOrAgencyId,
      );
      if (selectedAdvertiserIndex > -1) {
        const selectedAdvertiser = updatedSelection[selectedAdvertiserIndex];
        const selectedInvoiceIndex = selectedAdvertiser.items.findIndex(
          (item) => item?.number === invoice?.invoiceNumber,
        );
        if (selectedInvoiceIndex > -1) {
          selectedAdvertiser.items.splice(selectedInvoiceIndex, 1);
          if (!selectedAdvertiser.items.length) {
            updatedSelection.splice(selectedAdvertiserIndex, 1);
          }
        } else {
          selectedAdvertiser.items = [
            ...selectedAdvertiser.items,
            {
              number: invoice?.invoiceNumber,
              amount: invoice?.openAmount,
              dueDate: invoice?.dueDate,
              date: invoice?.invoiceDate,
              customAmount: invoice?.openAmount,
              inProgress: invoice?.inProgress || false,
            },
          ];
        }
      } else {
        updatedSelection.push({
          ...advertiserOrAgency,
          isManaged: isManagedInvoice,
          items: [
            {
              number: invoice?.invoiceNumber,
              amount: invoice?.openAmount,
              dueDate: invoice?.dueDate,
              date: invoice?.invoiceDate,
              customAmount: invoice?.openAmount,
              inProgress: invoice?.inProgress || false,
            },
          ],
        });
      }
    } else {
      updatedSelection.push({
        ...advertiserOrAgency,
        isManaged: isManagedInvoice,
        items: [
          {
            number: invoice?.invoiceNumber,
            amount: invoice?.openAmount,
            dueDate: invoice?.dueDate,
            date: invoice?.invoiceDate,
            customAmount: invoice?.openAmount,
            inProgress: invoice?.inProgress || false,
          },
        ],
      });
    }
    dispatch(updateSelectedInvoices(updatedSelection));
  };

  const handleOrderSelection = (advertiserOrAgency: IAdvertiserOrAgency, order: IOpenOrder) => {
    const updatedSelection: ISelectedInvoiceOrOrderItem[] = JSON.parse(
      JSON.stringify(selectedOrders),
    );
    if (updatedSelection.length) {
      const selectedAdvertiserIndex = updatedSelection.findIndex(
        (selectedOrder) =>
          selectedOrder?.advertiserOrAgencyId === advertiserOrAgency?.advertiserOrAgencyId,
      );
      if (selectedAdvertiserIndex > -1) {
        const selectedAdvertiser = updatedSelection[selectedAdvertiserIndex];
        const selectedInvoiceIndex = selectedAdvertiser.items.findIndex(
          (item) => item?.number + item?.dueDate === order?.orderNumber + order?.dueDate,
        );
        if (selectedInvoiceIndex > -1) {
          selectedAdvertiser.items.splice(selectedInvoiceIndex, 1);
          if (!selectedAdvertiser.items.length) {
            updatedSelection.splice(selectedAdvertiserIndex, 1);
          }
        } else {
          selectedAdvertiser.items = [
            ...selectedAdvertiser.items,
            {
              number: order?.orderNumber,
              amount: order?.orderAmount,
              dueDate: order?.dueDate,
              date: order?.orderDate,
              customAmount: order?.orderAmount,
              inProgress: order?.inProgress || false,
            },
          ];
        }
      } else {
        updatedSelection.push({
          ...advertiserOrAgency,
          isManaged: isManagedOrder,
          items: [
            {
              number: order?.orderNumber,
              amount: order?.orderAmount,
              dueDate: order?.dueDate,
              date: order?.orderDate,
              customAmount: order?.orderAmount,
              inProgress: order?.inProgress || false,
            },
          ],
        });
      }
    } else {
      updatedSelection.push({
        ...advertiserOrAgency,
        isManaged: isManagedOrder,
        items: [
          {
            number: order?.orderNumber,
            amount: order?.orderAmount,
            dueDate: order?.dueDate,
            date: order?.orderDate,
            customAmount: order?.orderAmount,
            inProgress: order?.inProgress || false,
          },
        ],
      });
    }
    dispatch(updateSelectedOrders(updatedSelection));
  };

  return (
    <>
      {(data || []).map((advertiser: IAdvertiserOrAgency, index: number) => {
        const currentDate = new Date();
        const overdueCount =
          type === OrderOrInvoiceTypes.Invoice || type === OrderOrInvoiceTypes.ManagedInvoice
            ? (advertiser.openInvoices || []).reduce((acc: number, invoice: IOpenInvoice) => {
                const dueDate = new Date(invoice.dueDate);
                return currentDate > dueDate ? acc + 1 : acc;
              }, 0)
            : 0;
        const isCreditBalanceExists = advertiser?.totalCreditBalance !== 0;
        return (
          <Box key={index + advertiser.advertiserOrAgencyId}>
            {showAdvertiserOrAgencyName ? (
              <StyledAccordion
                key={advertiser.advertiserOrAgencyId}
                TransitionProps={{ unmountOnExit: true }}
              >
                <StyledAccordionSummary expandIcon={<ExpandCircleDownOutlined color='primary' />}>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Typography variant='h6'>{advertiser.advertiserOrAgencyName}</Typography>
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='end'
                      gap={theme.spacing(2)}
                    >
                      <Typography>
                        {isInvoice
                          ? formatAmount(advertiser.totalOpenInvoiceAmount)
                          : formatAmount(advertiser?.totalOpenOrderAmount)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {isCreditBalanceExists && (
                      <Box>
                        <CreditBalanceInfo data={advertiser} contactInfo={contactInfo} />
                      </Box>
                    )}
                    {overdueCount > 0 && (
                      <Chip
                        variant='filled'
                        color='error'
                        label={`${overdueCount} Invoice Overdue`}
                        size='small'
                        sx={{ marginLeft: 'auto' }}
                      />
                    )}
                  </Box>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <Box>
                    {isInvoice && (
                      <Invoice
                        advertiser={advertiser}
                        selectedInvoices={selectedInvoices}
                        handleSelection={handleSelection}
                      />
                    )}
                    {isOrder && (
                      <Order
                        advertiser={advertiser}
                        selectedItems={selectedOrders}
                        handleSelection={handleOrderSelection}
                      />
                    )}
                  </Box>
                </StyledAccordionDetails>
              </StyledAccordion>
            ) : (
              <Box>
                {isInvoice && (
                  <Invoice
                    advertiser={advertiser}
                    selectedInvoices={selectedInvoices}
                    handleSelection={handleSelection}
                  />
                )}
                {isOrder && (
                  <Order
                    advertiser={advertiser}
                    selectedItems={selectedOrders}
                    handleSelection={handleOrderSelection}
                  />
                )}
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default InvoiceOrOrders;
