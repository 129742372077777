import React from 'react';

import { Alert, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';
import { ColorPartial } from '@mui/material/styles/createPalette';

export interface SimpleDialogProps {
  errorMsg: string;
  helpEmail: string;
  apiStatus: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CancelPaymentDialog = ({
  onClose,
  onConfirm,
  open,
  apiStatus,
  errorMsg,
  helpEmail,
}: SimpleDialogProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;

  const isError = apiStatus === 'failed';
  const isLoading = apiStatus === 'loading';

  const renderButton = () => {
    if (isError) {
      return (
        <Alert severity='error' variant='outlined' sx={{ marginY: theme.spacing(8) }}>
          <Typography variant='paragraph2' component='span' color='error'>
            {errorMsg}
          </Typography>
        </Alert>
      );
    }

    return (
      <Button
        variant='contained'
        size='large'
        sx={{ margin: theme.spacing(12, 0, 16) }}
        onClick={onConfirm}
        disabled={isLoading}
      >
        {isLoading && (
          <CircularProgress
            size={16}
            style={{
              marginRight: theme.spacing(4),
            }}
            color='inherit'
          />
        )}
        Confirm Cancelation
      </Button>
    );
  };

  return (
    <>
      <SpectrumDialog
        open={open}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box display='flex' flexDirection='column' sx={{ pt: theme.spacing(14) }}>
          <Typography variant='h2' sx={{ mb: theme.spacing(4) }}>
            Are you sure you want to cancel this payment?
          </Typography>
          <Typography variant='paragraph2'>
            For questions, please contact us at{' '}
            <Box display='inline' ml={2}>
              <a href={`mailto:${helpEmail}`}>{helpEmail}</a>
            </Box>
          </Typography>
          <Box>
            {renderButton()}
            <Button sx={{ display: 'block', color: grey[500] }} variant='text' onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </SpectrumDialog>
    </>
  );
};

export default CancelPaymentDialog;
