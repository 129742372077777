import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Help, MakePaymentInfo, Overview, SetUpAutoPay } from '../pages';
import Login from '../pages/Auth/Login';
import ProtectedRoute from './ProtectedRoute';
import MakePaymentStepper from '../pages/MakePayment/MakePaymentStepper';
import InvoicesAndOrders from '../pages/InvoicesAndOrders/InvoicesAndOrders';
import PaymentDetails from '../pages/InvoicesAndOrders/PaymentDetails';
import Account from '../pages/Account/Account';
import { PaymentFlowType } from '../redux/overview/OverviewModels';
import InvoiceAndOrderSharing from '../pages/InvoicesAndOrders/InvoiceAndOrderSharing/InvoiceAndOrderSharing';

export const PATHS = {
  LOGIN: '/login',
  OVERVIEW: '/overview',
  INVOICES_AND_ORDERS: '/invoices-and-orders',
  ACCOUNT: '/account',
  MY_TEAM: '/account/my-team',
  INVOICES_AND_ORDER_SHARING: '/account/invoices-and-order-sharing',
  HELP: '/help',
  MAKE_INVOICE_PAYMENT_INFO: '/make-invoice-payment-info',
  MAKE_ORDER_PAYMENT_INFO: '/make-order-payment-info',
  MAKE_INVOICE_PAYMENT: '/make-invoice-payment',
  MAKE_ORDER_PAYMENT: '/make-order-payment',
  SETUP_AUTO_PAY: '/setup-auto-pay',
  PAYMENT_DETAILS: '/payment-details',
  REVIEW_REQUEST: '/review-request/:status',
};

const PageRoutes = () => (
  <Routes>
    <Route path='/' element={<Navigate to={PATHS.OVERVIEW} />} />
    <Route path={PATHS.LOGIN} element={<Login />} />
    <Route
      path={PATHS.OVERVIEW}
      element={
        <ProtectedRoute>
          <Overview />
        </ProtectedRoute>
      }
    />
    <Route path={PATHS.HELP} element={<Help />} />
    <Route
      path={PATHS.MAKE_INVOICE_PAYMENT_INFO}
      element={
        <ProtectedRoute>
          <MakePaymentInfo type={PaymentFlowType.Invoices} />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.MAKE_ORDER_PAYMENT_INFO}
      element={
        <ProtectedRoute>
          <MakePaymentInfo type={PaymentFlowType.ORDERS} />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.MAKE_INVOICE_PAYMENT}
      element={
        <ProtectedRoute>
          <MakePaymentStepper type={PaymentFlowType.Invoices} />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.MAKE_ORDER_PAYMENT}
      element={
        <ProtectedRoute>
          <MakePaymentStepper type={PaymentFlowType.ORDERS} />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.SETUP_AUTO_PAY}
      element={
        <ProtectedRoute>
          <SetUpAutoPay />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.INVOICES_AND_ORDERS}
      element={
        <ProtectedRoute>
          <InvoicesAndOrders />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.MY_TEAM}
      element={
        <ProtectedRoute>
          <Account />
        </ProtectedRoute>
      }
    />
    <Route
      path={`${PATHS.PAYMENT_DETAILS}`}
      element={
        <ProtectedRoute>
          <PaymentDetails />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.INVOICES_AND_ORDER_SHARING}
      element={
        <ProtectedRoute>
          <InvoiceAndOrderSharing />
        </ProtectedRoute>
      }
    />
  </Routes>
);

export default PageRoutes;
