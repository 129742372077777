import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import SpectrumSearch from '../../../components/Spectrum/Search/SpectrumSearch';
import InviteUser from '../InviteUser/InviteUser';
import { Box } from '@mui/material';

interface IToolbarProps {
  onSearch: (value: string) => void;
}

const Toolbar = ({ onSearch }: IToolbarProps) => {
  const [searchValue, setSearchValue] = useState('');
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    setSearchValue(value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13) {
      onSearch(searchValue);
    }
  };
  return (
    <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between' }}>
      <SpectrumSearch
        placeholder='Search...'
        value={searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <InviteUser />
    </Box>
  );
};

export default Toolbar;
