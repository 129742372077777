import React from 'react';
import { Box, Chip, Divider, Typography, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { formatAmount } from '../../../utils/common';
import { ICreditBalance } from '../../../redux/overview/OverviewModels';
import { IContactInfo } from '../../../redux/help/HelpModels';

const CreditBalanceItem = ({ label, value }: { label: string; value: number }) => {
  const formattedAmount = formatAmount(value);
  return (
    <Typography
      variant='body2'
      sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 2 }}
    >
      <Box>{label}</Box>
      <Box>{formattedAmount}</Box>
    </Typography>
  );
};

const CreditBalanceInfo = ({
  label = 'Available Credit Balance',
  data,
  contactInfo,
}: {
  data: ICreditBalance;
  contactInfo: IContactInfo;
  label?: string;
}) => {
  const theme = useTheme();
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };
  const formattedAmount = formatAmount(data?.totalCreditBalance || 0);
  return (
    <>
      <Chip
        variant='filled'
        color='primary'
        label={
          <Box gap={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='h6' sx={{ fontSize: '14px' }}>
              {label}:
            </Typography>
            <Box sx={{ display: 'inline', fontWeight: 'normal' }}>{formattedAmount}</Box>
            <Box
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={handleClick}
            >
              <InfoIcon sx={{ verticalAlign: 'middle' }} fontSize='small' />
            </Box>
          </Box>
        }
        size='medium'
        sx={{ mt: 3, mb: 3, width: '100%', justifyContent: 'left' }}
      />
      <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box
          sx={{
            backgroundColor: primary[800],
            padding: 6,
            color: theme.palette.common.white,
            width: '300px',
          }}
        >
          <Typography variant='h6' mb={3}>
            {label}
          </Typography>
          <Divider sx={{ borderColor: grey[900] }} />
          <CreditBalanceItem label='Adjustments' value={data?.totalCreditAdjustments} />
          <Divider sx={{ borderStyle: 'dashed', borderColor: grey[900] }} />
          <CreditBalanceItem label='Payments' value={data?.totalCreditPayments} />
          <Divider sx={{ borderStyle: 'dashed', borderColor: grey[900] }} />
          <CreditBalanceItem label='Prepayments' value={data?.totalCreditPrepayments} />
          <Divider sx={{ borderStyle: 'dashed', borderColor: grey[900] }} />
          <CreditBalanceItem label='Invoices' value={data?.totalCreditInvoices} />
          <Divider sx={{ borderColor: grey[900] }} />
          <CreditBalanceItem label='Total Available' value={data?.totalCreditBalance} />
          <Box mt={6}>
            <Typography variant='subtitle2' fontStyle='italic' fontSize='12px'>
              To apply a credit balance toward a payment, please call {contactInfo?.phoneNumber} or
              email {contactInfo?.email}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default CreditBalanceInfo;
