import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { autoPayApiEndPoints, paymentApiEndPoints } from '../../constants/apiConstants';
import ApiService from '../../services/ApiService';
import { IFilterPayload, IPaymentHistoryResponse, IPaymentState } from './PaymentModel';
import { PaymentFlowType } from '../overview/OverviewModels';

const initialFiltersValue: IFilterPayload = {
  start: 0,
  length: 10,
  sortColumn: 'TransactionId',
  sortDirection: 'desc',
  searchValue: '',
  startDate: null,
  endDate: null,
};

const initialState: IPaymentState = {
  upcomingPaymentStatus: 'idle',
  upcomingPaymentsError: null,
  upcomingPayments: [],
  upcomingPaymentsCounts: 0,
  cancelledScheduledPaymentStatus: 'idle',
  cancelledScheduledPaymentError: null,
  cancelledRecurringPaymentStatus: 'idle',
  selectedPaymentFlowType: PaymentFlowType.Invoices,
  paymentHistoryStatus: 'idle',
  paymentHistoryError: null,
  paymentHistory: {} as IPaymentHistoryResponse,
  paymentHistoryDetailsStatus: 'idle',
  paymentHistoryDetailsError: '',
  paymentHistoryDetails: null,
  filters: initialFiltersValue,
};

export const fetchPaymentHistory = createAsyncThunk(
  'payment/GetPaymentHistory',
  async (payload: IFilterPayload) => {
    const response = await ApiService.postData(paymentApiEndPoints.paymentHistory, {}, payload);
    return response.data;
  },
);

export const fetchPaymentHistoryDetails = createAsyncThunk(
  'payment/GetPaymentHistoryDetails',
  async (params: { transactionId: string }) => {
    const response = await ApiService.getData(paymentApiEndPoints.paymentHistoryDetailsURL, params);
    return response.data;
  },
);

export const fetchUpcomingPayments = createAsyncThunk('payment/getUpcomingPayments', async () => {
  const response = await ApiService.getData(paymentApiEndPoints.upcomingPayments);
  return response.data;
});

export const fetchUpcomingPaymentsCounts = createAsyncThunk(
  'payment/getUpcomingPaymentCount',
  async (paymentID: string) => {
    const paymentTokenId = paymentID;
    const response = await ApiService.getData(
      `${paymentApiEndPoints.upcomingPaymentsCount}?paymentTokenId=${paymentTokenId}`,
    );
    return response.data;
  },
);

export const cancelScheduledPayment = createAsyncThunk(
  'payment/CancelScheduledPayment',
  async (transactionId: number) => {
    const response = await ApiService.getData(paymentApiEndPoints.cancelScheduledPayment, {
      TransactionId: transactionId,
    });
    return response.data;
  },
);

export const cancelRecurringPayment = createAsyncThunk(
  'payment/CancelRecurringPayment',
  async (paymentId: number) => {
    const response = await ApiService.postData(autoPayApiEndPoints.cancelRecurringPayment, {
      RecurringPaymentId: paymentId,
    });
    return response.data;
  },
);

export const PaymentSlice = createSlice({
  name: 'paymentData',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchUpcomingPayments.pending, (state) => {
        state.upcomingPaymentStatus = 'loading';
      })
      .addCase(fetchUpcomingPayments.fulfilled, (state, action) => {
        state.upcomingPaymentStatus = 'succeeded';
        state.upcomingPayments = action.payload.data;
        state.upcomingPaymentsError = '';
      })
      .addCase(fetchUpcomingPayments.rejected, (state, action) => {
        state.upcomingPaymentStatus = 'failed';
        state.upcomingPaymentsError =
          action?.error?.message || 'Failed to get upcoming scheduled payments';
      })
      .addCase(fetchUpcomingPaymentsCounts.pending, (state) => {
        state.upcomingPaymentStatus = 'loading';
      })
      .addCase(fetchUpcomingPaymentsCounts.fulfilled, (state, action) => {
        state.upcomingPaymentStatus = 'succeeded';
        state.upcomingPaymentsCounts = action.payload.data;
        state.upcomingPaymentsError = '';
      })
      .addCase(fetchUpcomingPaymentsCounts.rejected, (state, action) => {
        state.upcomingPaymentStatus = 'failed';
        state.upcomingPaymentsError =
          action?.error?.message || 'Failed to get upcoming scheduled payments';
      })
      .addCase(cancelScheduledPayment.pending, (state) => {
        state.cancelledScheduledPaymentStatus = 'loading';
      })
      .addCase(cancelScheduledPayment.fulfilled, (state) => {
        state.cancelledScheduledPaymentStatus = 'succeeded';
      })
      .addCase(cancelScheduledPayment.rejected, (state, action) => {
        state.cancelledScheduledPaymentStatus = 'failed';
        state.cancelledScheduledPaymentError =
          action?.error?.message || 'Failed to cancel scheduled payment';
      })
      .addCase(cancelRecurringPayment.pending, (state) => {
        state.cancelledRecurringPaymentStatus = 'loading';
      })
      .addCase(cancelRecurringPayment.fulfilled, (state) => {
        state.cancelledRecurringPaymentStatus = 'succeeded';
      })
      .addCase(cancelRecurringPayment.rejected, (state) => {
        state.cancelledRecurringPaymentStatus = 'failed';
      })
      .addCase(fetchPaymentHistory.pending, (state) => {
        state.paymentHistoryStatus = 'loading';
      })
      .addCase(fetchPaymentHistory.fulfilled, (state, action) => {
        state.paymentHistoryStatus = 'succeeded';
        state.paymentHistory = action.payload.data;
        state.paymentHistoryError = '';
      })
      .addCase(fetchPaymentHistory.rejected, (state, action) => {
        state.paymentHistoryStatus = 'failed';
        state.paymentHistoryError =
          action?.error?.message || 'Failed to get upcoming scheduled payments';
      })
      .addCase(fetchPaymentHistoryDetails.pending, (state) => {
        state.paymentHistoryDetailsStatus = 'loading';
      })
      .addCase(fetchPaymentHistoryDetails.fulfilled, (state, action) => {
        state.paymentHistoryDetailsStatus = 'succeeded';
        state.paymentHistoryDetails = action.payload.data;
        state.paymentHistoryDetailsError = '';
      })
      .addCase(fetchPaymentHistoryDetails.rejected, (state, action) => {
        state.paymentHistoryDetailsStatus = 'failed';
        state.paymentHistoryDetails = null;
        state.paymentHistoryDetailsError =
          action?.error?.message || 'Failed to get upcoming scheduled payments';
      });
  },
  reducers: {
    resetAutoPayStore: (state) => {
      state.upcomingPaymentsError = null;
      state.upcomingPaymentStatus = 'idle';
    },
    resetCancelledPaymentStatus: (state) => {
      state.cancelledRecurringPaymentStatus = 'idle';
      state.cancelledScheduledPaymentStatus = 'idle';
    },
    setSelectedPaymentType: (state, action) => {
      state.selectedPaymentFlowType = action.payload;
    },
    updateFilters: (state, action: { payload: IFilterPayload; type: string }) => {
      state.filters = action?.payload;
    },
    resetFilters: (state) => {
      state.paymentHistoryStatus = 'idle';
      state.filters = initialFiltersValue;
    },
    resetUpcomingPaymentsCount: (state) => {
      state.upcomingPaymentsCounts = 0;
    },
  },
});

export const {
  resetCancelledPaymentStatus,
  setSelectedPaymentType,
  updateFilters,
  resetFilters,
  resetUpcomingPaymentsCount,
} = PaymentSlice.actions;
export default PaymentSlice.reducer;
