export interface IUserDetails {
  tblUser: ITblUser;
  isWaitingForActivation: boolean | null;
}

export interface ITblUser {
  id: number;
  cognitoUserId: string;
  acceptedTermsAndConditions: boolean;
  acceptedTermsAndConditionsDateTime: string;
  firstName: string;
  lastName: string;
  userRoleId: number;
  tblUserRole: ITblUserRole;
  userStatusId: number;
  tblUserStatus: ITblUserStatus;
  agencyId: number;
  tblAgency: ITblAgency;
  advertiserId: any;
  tblAdvertiser: ITblAdvertiser;
  userNotes: any;
}

export interface ITblUserRole {
  id: number;
  name: string;
}

export interface ITblUserStatus {
  id: number;
  name: string;
}

export interface ITblAgency {
  id: number;
  agencyId: string;
  agencyName: string;
}
export interface ITblAdvertiser {
  id: number;
  advertiserId: string;
  advertiserName: string;
}

export interface TblUser {
  id: number;
  userId: string;
  acceptedTermsAndConditions: boolean;
  acceptedTermsAndConditionsDateTime: string;
  firstName: string;
  lastName: string;
}

export interface IFetchTokenUsingCodePayload {
  grant_type: string;
  code: string;
  redirect_uri: string;
  client_id: string;
  scope: string;
}
export interface IFetchAccessTokenUsingRefreshToken {
  grant_type: string;
  client_id: string;
  refresh_token: string;
}

export interface INewUserRegistrationDetails {
  firstName: string;
  lastName: string;
  customerId: string;
  companyName: string;
  companyType: string;
  accountExecutive: string;
  recentInvoiceNumber: string;
  worksWithOtherAgencies?: boolean;
  isTermsAndConditionsAccepted: boolean;
}

export interface IPendingUserRegistrationDetails {
  firstName: string;
  lastName: string;
  isTermsAndConditionsAccepted: boolean;
}

export interface ITokenResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

export enum ClientTypes {
  Agency = 'Agency',
  Advertiser = 'Advertiser',
}
export enum UserStatusTypes {
  Inactive = 'Inactive',
  Active = 'Active',
}

export interface IAuthState {
  userDetails: IUserDetails | null;
  clientType: ClientTypes | null;
  clientId: string;
  clientName: string;
  userDetailsStatus: string;
  userDetailsError: any;
  accessTokenStatus: string;
  error: any;
  signupPendingUserError: any;
  signupPendingUserStatus: string;
  signupNewUserStatus: string;
  signupNewUserError: any;
}
