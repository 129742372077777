import React from 'react';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Box, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

interface IAgencyInfoProps {
  name?: string;
  id?: number;
}

const AgencyInfo = ({ name, id }: IAgencyInfoProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <div>
        <CorporateFareIcon style={{ color: grey[500], marginRight: 8, fontSize: '50px' }} />
      </div>
      <div>
        <Typography variant='h5' mb={0} lineHeight='1'>
          {name}
        </Typography>
        <Typography variant='caption' color={grey[500]}>
          {id}
        </Typography>
      </div>
    </Box>
  );
};
export default AgencyInfo;
