import React, { useEffect } from 'react';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import { Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../routes/Routes';
import { nth } from '../../utils/common';
import { IPaymentMethod } from '../../redux/autoPay/AutoPayModel';
import CustomerSupportFooter from '../MakePayment/CustomerSupportFooter';

const AutoPayConfirmation = ({
  payOn,
  selectedPaymentMethodDetail,
}: {
  payOn: number;
  selectedPaymentMethodDetail?: IPaymentMethod;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate(PATHS.OVERVIEW);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageContentContainer>
      <Typography variant='eyebrow' mt={20} mb={5}>
        Success
      </Typography>
      <Typography variant='h1' mb={8}>
        Your payment has been scheduled
      </Typography>
      <Card
        sx={{
          mb: 10,
          boxShadow: 4,
        }}
      >
        <CardContent>
          <Typography variant='eyebrow' mb={5}>
            AutoPay Details
          </Typography>
          <Typography variant='paragraph2' mb={5} color={theme.palette.common.black}>
            You will be charged on the{' '}
            <Typography component='span' sx={{ fontWeight: 500, ml: 4, mr: 4 }}>
              {payOn}
              <sup>{nth(payOn)}</sup> of each month
            </Typography>
            to method
            <Typography component='span' sx={{ fontWeight: 500, ml: 4, mr: 4 }}>
              {selectedPaymentMethodDetail?.type} .. {selectedPaymentMethodDetail?.lastFourNumbers}.
            </Typography>
          </Typography>
        </CardContent>
      </Card>
      <CustomerSupportFooter msg='Have any questions? Contact us at' />
      <Button
        variant='text'
        onClick={handleGoHome}
        sx={{
          width: 'fit-content',
          minWidth: 'auto',
          mt: 12,
          mb: 12,
        }}
      >
        Back to Home
      </Button>
    </PageContentContainer>
  );
};
export default AutoPayConfirmation;
