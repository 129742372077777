import React from 'react';

import { Box, Card, CardContent, Typography, useMediaQuery, useTheme } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ColorPartial } from '@mui/material/styles/createPalette';

import PaymentInfo from './PaymentInfo';
import { IPayment } from '../../redux/payment/PaymentModel';
import PaymentDetail from './PaymentDetail';
import CustomerSupportFooter from '../MakePayment/CustomerSupportFooter';
import ScrollBox from '../../components/ScrollBox/ScrollBox';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  payments: IPayment[];
}

function AutoPaymentsDialog({ onClose, open, payments }: SimpleDialogProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const [showDetailedView, setShowDetailedView] = React.useState(false);
  const [selectedPaymentDetail, setSelectedPaymentDetail] = React.useState<IPayment | null>(null);

  const handleClose = () => {
    onClose();
  };

  const openDetailedView = (payment: IPayment) => {
    setSelectedPaymentDetail(payment);
    setShowDetailedView(true);
  };

  return (
    <>
      <SpectrumDialog
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h2' mb={theme.spacing(12)}>
              AutoPayments
            </Typography>
          </Box>
          <ScrollBox
            sx={{
              maxHeight: isMobile ? 'calc(100vh - 250px)' : 372,
              paddingRight: theme.spacing(8),
            }}
          >
            {payments.map((payment) => (
              <Card
                key={payment.paymentId}
                style={{
                  border: `${theme.spacing(0.5)} solid ${grey[400]}`,
                  borderRadius: 0,
                  marginBottom: theme.spacing(4),
                }}
                onClick={() => openDetailedView(payment)}
              >
                <CardContent
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: theme.spacing(4, 6, 6),
                  }}
                >
                  <PaymentInfo payment={payment} />
                  <Box>
                    <NavigateNextIcon />
                  </Box>
                </CardContent>
              </Card>
            ))}
          </ScrollBox>
          <CustomerSupportFooter />
        </Box>
      </SpectrumDialog>
      <PaymentDetail
        open={showDetailedView}
        onClose={() => setShowDetailedView(false)}
        selectedPaymentDetail={selectedPaymentDetail}
      />
    </>
  );
}

export default AutoPaymentsDialog;
