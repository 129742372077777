import React, { useState } from 'react';
import { Alert, Box, Button, Link, Typography, useTheme } from '@mui/material';
import { ComponentOverlayLoader } from '../../../components/Loaders/Loader';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { removeUserFromAccount } from '../../../redux/account/AccountSlice';
import { Actions } from '../Members/MemberRoleMappings';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { IUserAccountDetail } from '../../../redux/account/AccountModels';

interface IRemoveUserProps {
  onClose: ({ type }: any) => void;
  member: IUserAccountDetail;
}

const RemoveUser = ({ member, onClose }: IRemoveUserProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;

  const [removeUserDetails, setRemoveUserDetails] = useState({ status: '', error: '' });
  const [stage, setStage] = useState(1);

  const handleRemove = () => {
    setRemoveUserDetails({ ...removeUserDetails, status: 'loading', error: '' });
    removeUserFromAccount({ userSSOId: member.userSSOId })
      .then(() => {
        setRemoveUserDetails({ ...removeUserDetails, status: 'succeeded' });
        setStage(2);
      })
      .catch((error) => {
        setRemoveUserDetails({
          ...removeUserDetails,
          status: 'failed',
          error: error?.error || 'Failed to remove user',
        });
      });
  };

  const handleClose = () => {
    onClose({ type: Actions.REMOVE_USER });
  };

  const isLoading = removeUserDetails?.status === 'loading';

  return (
    <div>
      {stage === 1 && (
        <>
          <Typography variant='h2' mb={5}>
            Remove User
          </Typography>
          {isLoading && <ComponentOverlayLoader />}
          <Typography variant='body1' mb={10} color={grey[500]}>
            Users who have their access removed will no longer be able to access this account
          </Typography>
          <Box>
            <Typography variant='h6'>User to be removed:</Typography>
            <Box
              sx={{
                display: 'flex',
                border: `1px solid ${grey[400]}`,
                backgroundColor: grey[50],
                mt: 4,
                p: 6,
              }}
            >
              <PersonOutlineOutlinedIcon />
              <Box sx={{ ml: 4 }}>
                <Typography variant='h6'>{member?.userName}</Typography>
                <Typography
                  component={Link}
                  variant='paragraph2'
                  color='primary'
                  sx={{ textDecorationColor: 'inherit' }}
                >
                  {member?.userEmail}
                </Typography>
              </Box>
            </Box>
          </Box>
          {removeUserDetails?.error && (
            <Alert severity='error' variant='outlined' sx={{ mt: 10, mb: 10 }}>
              <Typography variant='paragraph2' component='span' color='error'>
                {removeUserDetails?.error}
              </Typography>
            </Alert>
          )}
          <Box sx={{ mt: 15, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleRemove} variant='contained' size='large'>
              Remove Access
            </Button>
          </Box>
        </>
      )}
      {stage === 2 && (
        <>
          <Typography variant='h2' mb={5}>
            User Removed
          </Typography>
          <Typography variant='body1' mb={10} color={grey[500]}>
            {member?.userName} has successfully been removed from your account
          </Typography>
          <Box sx={{ mt: 15, display: 'flex', justifyContent: 'flex-start' }}>
            <Button onClick={handleClose} variant='contained' size='large'>
              Ok
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};
export default RemoveUser;
