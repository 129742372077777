// Packages
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { ExpandCircleDownOutlined } from '@mui/icons-material';

import { StyledAccordionSummary, StyledStep, StyledStepConnector } from './SetUpAutoPayStyles';
import PaymentAmount from './Steps/PaymentAmount';
import PaymentInstructions from './Steps/PaymentInstructions';
import ReviewDetails from './Steps/ReviewDetails';
import SelectPaymentMethod from './Steps/SelectPaymentMethod';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import AutoPayConfirmation from './AutoPayConfirmation';
import AutoPayConfirmationFailed from './AutoPayConfirmationFailed';

import { nth, scrollToTop } from '../../utils/common';
import { resetAutoPayStore } from '../../redux/autoPay/AutoPaySlice';
import { RootState, StoreDispatch } from '../../redux/Store';
import { PATHS } from '../../routes/Routes';
import CustomerSupportFooter from '../MakePayment/CustomerSupportFooter';

export const AUTOPAY_STEPS: Record<number, string> = {
  1: 'Payment Amount',
  2: 'Payment Instructions',
  3: 'Select Payment Method',
  4: 'Review Details',
};

const SetUpAutoPay = () => {
  const navigate = useNavigate();
  const dispatch: StoreDispatch = useDispatch();
  const { paymentMethods, autoPayConfirmationStatus, autoPayError } = useSelector(
    (state: RootState) => state.autoPay,
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;

  const [activeStep, setActiveStep] = useState<number>(1);
  const [payOn, setPayOn] = useState<number>(1);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>('');

  const selectedPaymentMethodDetail = useMemo(
    () => paymentMethods?.find((paymentMethod) => paymentMethod.spmId === selectedPaymentMethodId),
    [paymentMethods, selectedPaymentMethodId],
  );

  useEffect(() => {
    return () => {
      dispatch(resetAutoPayStore());
    };
  }, []);

  useEffect(() => {
    if (paymentMethods?.length && !selectedPaymentMethodId) {
      const defaultPaymentMethod = paymentMethods[0];
      defaultPaymentMethod && setSelectedPaymentMethodId(defaultPaymentMethod.spmId);
    }
  }, [paymentMethods]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleCancel = () => {
    navigate(PATHS.OVERVIEW);
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 1:
        return <PaymentAmount handleNext={handleNext} />;
      case 2:
        return (
          <PaymentInstructions
            payOn={payOn}
            setPayOn={setPayOn}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <SelectPaymentMethod
            selectedPaymentMethodId={selectedPaymentMethodId}
            setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        );
      case 4:
        return (
          <ReviewDetails
            payOn={payOn}
            selectedPaymentMethodDetail={selectedPaymentMethodDetail}
            setActiveStep={setActiveStep}
          />
        );
      default:
        return null;
    }
  };

  const renderPaymentSummary = () =>
    Object.entries(AUTOPAY_STEPS).map(
      ([key, value]) =>
        Number(key) < activeStep && (
          <Fragment key={key}>
            {activeStep === 3 && Number(key) === 2 && (
              <Divider
                sx={{
                  marginY: theme.spacing(8),
                }}
              />
            )}
            <Typography
              color={primary[500]}
              variant='eyebrow'
              sx={{
                fontSize: '14px',
                paddingBottom: theme.spacing(4),
              }}
            >
              {value}
            </Typography>
            {renderSelectedValue(Number(key))}
          </Fragment>
        ),
    );

  const renderSelectedValue = (step: number) => {
    switch (step) {
      case 1:
        return (
          <Typography variant='paragraph2' color={theme.palette.common.black}>
            Full Balance
          </Typography>
        );
      case 2:
        return (
          <>
            <Typography variant='h6' color={theme.palette.common.black}>
              Pay on the:
            </Typography>
            <Typography>
              {payOn}
              {nth(payOn)} every month
            </Typography>
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [activeStep]);

  if (autoPayConfirmationStatus === 'succeeded') {
    return (
      <AutoPayConfirmation
        payOn={payOn}
        selectedPaymentMethodDetail={selectedPaymentMethodDetail}
      />
    );
  }

  if (autoPayConfirmationStatus === 'failed') {
    return <AutoPayConfirmationFailed error={autoPayError} />;
  }

  return (
    <PageContentContainer>
      <Stack spacing={theme.spacing(8)}>
        {isMobile && activeStep !== 1 && activeStep !== 4 && (
          <Accordion>
            <StyledAccordionSummary
              sx={{
                backgroundColor: grey[50],
                padding: theme.spacing(0, 8),
              }}
              expandIcon={<ExpandCircleDownOutlined />}
            >
              <Typography variant='h6'>Summary</Typography>
            </StyledAccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: grey[50],
                border: `${theme.spacing(0.5)} solid ${theme.palette.grey[400]}`,
                padding: theme.spacing(8, 8, 12),
              }}
            >
              {renderPaymentSummary()}
            </AccordionDetails>
          </Accordion>
        )}
        <Card
          sx={{
            border: `1px solid ${grey[200]}`,
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              padding: 0,
            }}
          >
            <Box
              padding={theme.spacing(isMobile ? 12 : 16)}
              sx={{
                width: activeStep !== 1 && !isMobile && activeStep !== 4 ? '65%' : '100%',
              }}
            >
              <Box mb={theme.spacing(20)}>
                <Box sx={{ width: 290 }}>
                  <Stepper activeStep={activeStep} connector={<StyledStepConnector />}>
                    {[1, 2, 3, 4, 5].map((step) => (
                      <StyledStep key={step} />
                    ))}
                  </Stepper>
                  <Typography variant='caption'>
                    <Typography component='span' color={primary[500]} variant='caption'>
                      {activeStep}
                    </Typography>{' '}
                    / 4
                  </Typography>
                </Box>
                <Stack spacing={theme.spacing(8)}>
                  <Box pt={theme.spacing(12)}>
                    <Typography variant='eyebrow'>Set Up AutoPay</Typography>
                    <Typography variant='h2' color={primary[800]}>
                      {AUTOPAY_STEPS[activeStep]}
                    </Typography>
                  </Box>
                  {renderSteps()}
                </Stack>
              </Box>
              <Box sx={{ mt: 10 }}>
                <CustomerSupportFooter />
              </Box>
            </Box>
            {!isMobile && activeStep !== 1 && activeStep !== 4 && (
              <Box
                sx={{
                  width: '35%',
                }}
                padding={theme.spacing(12)}
                bgcolor={grey[50]}
              >
                {renderPaymentSummary()}
              </Box>
            )}
          </CardContent>
        </Card>
        <Button
          variant='text'
          sx={{
            width: 'fit-content',
            minWidth: 'auto',
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Stack>
    </PageContentContainer>
  );
};

export default SetUpAutoPay;
