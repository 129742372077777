import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  '.MuiDialog-paper': {
    padding: 0,
  },
}));
export const StyledIframe = styled('iframe')(({ theme }) => ({
  height: '99%',
  width: '100%',
  border: 'none',
}));

export const StyledNewPaymentMethodItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(8),
  border: `${theme.spacing(0.5)} solid ${theme.palette.grey[400]}`,
  marginTop: theme.spacing(10),
  '.payment-type': {
    display: 'flex',
    alignItems: 'center',
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
  },
}));
