import React, { ReactNode } from 'react';
import { Card, Typography, useTheme } from '@mui/material';
import PageContentContainer from '../PageContentContainer/PageContentContainer';

interface IErrorPage {
  title: ReactNode;
  message: ReactNode;
}
const ErrorPage = ({ title, message }: IErrorPage) => {
  const theme = useTheme();
  return (
    <PageContentContainer>
      <Card
        sx={{ textAlign: 'center', mt: 10, p: 10, boxShadow: theme.shadows[2] }}
        variant='outlined'
      >
        <Typography color='error' variant='h2'>
          {title}
        </Typography>
        <Typography color='info' mt={15} mb={20} variant='body2'>
          {message}
        </Typography>
      </Card>
    </PageContentContainer>
  );
};
export default ErrorPage;
