import React, { useEffect } from 'react';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import InvoiceOrOrders from './Invoices/InvoiceOrOrders';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { OrderOrInvoiceTypes } from '../../redux/overview/OverviewModels';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { fetchManagedInvoices } from '../../redux/overview/OverviewSlice';
import { ComponentOverlayLoader } from '../../components/Loaders/Loader';
import { formatDate } from '../../utils/common';

const ManagedInvoices = () => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const { managedInvoices, managedInvoicesStatus, managedInvoicesError, invoiceFilters } =
    useSelector((state: RootState) => state.overview);

  useEffect(() => {
    dispatch(
      fetchManagedInvoices({
        ...invoiceFilters,
        ...(invoiceFilters?.startDate && {
          startDate: formatDate(invoiceFilters?.startDate || '', 'yyyy-MM-dd'),
        }),
        ...(invoiceFilters?.endDate && {
          endDate: formatDate(invoiceFilters?.endDate || '', 'yyyy-MM-dd'),
        }),
      }),
    );
  }, [invoiceFilters]);

  const isInvoicesLoading = managedInvoicesStatus === 'loading';

  return (
    <>
      <Box>
        <Typography variant='h4' fontSize='1rem' mt={theme.spacing(8)}>
          Managed Invoices
        </Typography>
        <Typography
          color={grey[500]}
          fontSize='0.875rem'
          mb={theme.spacing(4)}
          sx={{
            fontStyle: 'italic',
          }}
        >
          Payments made by a third-party agency may take up to 24 hours to appear.
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
          minHeight: 100,
        }}
      >
        {managedInvoicesError && (
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {managedInvoicesError}
            </Typography>
          </Alert>
        )}
        {isInvoicesLoading ? (
          <Box sx={{ mt: 30 }}>
            <ComponentOverlayLoader />
          </Box>
        ) : (
          <>
            {managedInvoices?.length > 0 ? (
              <InvoiceOrOrders data={managedInvoices} type={OrderOrInvoiceTypes.ManagedInvoice} />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 100,
                }}
              >
                <Typography variant='body2' color={grey[500]}>
                  No Managed Invoices Found
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ManagedInvoices;
