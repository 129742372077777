import { styled } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import Box from '@mui/material/Box';

export const StyledBoxContainer = styled(Box)(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  return {
    '&.header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(8),
      '&.align-left': {
        justifyContent: 'flex-start',
      },
      '.selected': {
        backgroundColor: primary[500],
        color: theme.palette.common.white,
        borderRadius: theme.spacing(2),
        fontWeight: 600,
        padding: theme.spacing(0, 3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  };
});
