import React, { MouseEvent, useRef, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import TermsAndCondition from '../Help/TermsAndCondition';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';
import {
  INewUserRegistrationDetails,
  IPendingUserRegistrationDetails,
} from '../../redux/auth/AuthModels';

interface ITermsAndConditions {
  formData: INewUserRegistrationDetails | IPendingUserRegistrationDetails;
  handleChange: (x: React.ChangeEvent<HTMLInputElement>) => void;
}

const TermsAndConditions = ({ formData, handleChange }: ITermsAndConditions) => {
  const theme = useTheme();
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [dialogStatus, setDialogStatus] = useState(false);

  const handleClose = () => {
    setDialogStatus(false);
  };
  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDialogStatus(true);
  };

  const handleAcceptTermsAndConditions = () => {
    if (!checkboxRef?.current?.checked) {
      checkboxRef?.current?.click();
    }
    handleClose();
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        gap={theme.spacing(12)}
        marginBottom={theme.spacing(12)}
      >
        <SpectrumDialog open={dialogStatus} onClose={() => handleClose()}>
          <Box mt={5}>
            <Typography variant='eyebrow' marginBottom={theme.spacing(2)}>
              COMPLETE VALIDATION
            </Typography>
            <Typography variant='h4'>Terms and Conditions</Typography>
            <Box mt={10}>
              <TermsAndCondition />
            </Box>
            <Box display='flex' alignItems='center'>
              <Button
                onClick={handleAcceptTermsAndConditions}
                sx={{ mt: 10 }}
                variant='contained'
                size='medium'
              >
                Accept and Continue
              </Button>
              <Button sx={{ ml: 10, mt: 10 }} size='medium' variant='text' onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </SpectrumDialog>
        <FormControlLabel
          control={
            <Checkbox
              name='isTermsAndConditionsAccepted'
              checked={formData.isTermsAndConditionsAccepted}
              onChange={handleChange}
              inputRef={checkboxRef}
            />
          }
          label={
            <Box>
              By checking this box, you acknowledge that you have read and agree to the{' '}
              <Typography
                variant='body1'
                onClick={handleOpen}
                sx={{ textDecoration: 'underline', textTransform: 'capitalize' }}
                color='primary'
              >
                terms and conditions
              </Typography>
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default TermsAndConditions;
