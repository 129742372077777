import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';
import LoginBgImage from '../../../src/assets/images/client-portal-login.png';

export const StyledContainer = styled('div')(({ theme }) => {
  return {
    backgroundImage: `url(${LoginBgImage})`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '600px',
    backgroundSize: 'cover',
    [theme.breakpoints.between('mobile', 'tablet')]: {
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('mobile')]: {
      height: '500px',
      backgroundSize: 'contain',
    },
  };
});
export const StyledLoginCard = styled('div')(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  return {
    background: white[500],
    padding: theme.spacing(16),
    maxWidth: theme.spacing(212),
    [theme.breakpoints.down('mobile')]: {
      maxWidth: '100%',
    },
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(1),
    '.login-btn': {
      width: theme.spacing(94),
      margin: theme.spacing(10, 0),
    },
    '.loader': {
      color: white[500],
    },
    '.divider': {
      borderColor: grey[900],
    },
    '.anchor-link': {
      margin: theme.spacing(0, 5),
      color: primary[500],
      fontWeight: 500,
      textDecorationColor: primary[500],
      cursor: 'pointer',
      '&:hover': {
        color: primary[200],
        textDecorationColor: primary[200],
      },
    },
  };
});
export const StyledLoginWrapper = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(40),
    [theme.breakpoints.between('mobile', 'laptop')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('mobile')]: {
      margin: 0,
      flexDirection: 'column',
      height: '100%',
    },
  };
});
export const StyledNoticeContainer = styled('div')(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  return {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(3),
    backgroundColor: '#a6e3ff66',
    color: primary[500],
    borderRadius: theme.spacing(1),
    '.anchor-inline-link': {
      fontSize: 'inherit',
      fontWeight: 600,
      color: 'inherit',
      textDecorationColor: primary[500],
      cursor: 'pointer',
      '&:hover': {
        color: primary[200],
        textDecorationColor: primary[200],
      },
    },
    '.link-yellow': {
      color: '#9B6000',
      marginTop: theme.spacing(4),
      '.anchor-inline-link': {
        textDecorationColor: '#9B6000',
        '&:hover': {
          color: '#bb7c15',
          textDecorationColor: '#9B6000',
        },
      },
    }
  };
});
