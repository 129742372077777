import React, { useEffect } from 'react';
import { Alert, Box, Divider, Typography } from '@mui/material';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import AgencyInfo from './AgencyInfo';
import Toolbar from './Toolbar/Toolbar';
import Members from './Members/Members';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { fetchUserAccountInformation, updateFilters } from '../../redux/account/AccountSlice';
import { ComponentOverlayLoader } from '../../components/Loaders/Loader';
import SpectrumPagination from '../../components/Pagination/SpectrumPagination';
import { IGetUserAccountInfoPayload } from '../../redux/account/AccountModels';

const Account = () => {
  const dispatch: StoreDispatch = useDispatch();

  const { accountInformation, accountInformationStatus, accountInformationError, filters } =
    useSelector((state: RootState) => state.accountData);

  const handleChangeItemsPerPage = (items: number) => {
    dispatch(updateFilters({ ...filters, start: 0, length: items }));
  };
  const handlePageChange = (pageNo: number) => {
    dispatch(updateFilters({ ...filters, start: pageNo * filters.length }));
  };
  const handleSearch = (searchValue: string) => {
    dispatch(updateFilters({ ...filters, start: 0, searchValue: searchValue }));
  };

  useEffect(() => {
    dispatch(fetchUserAccountInformation(filters as IGetUserAccountInfoPayload));
  }, [filters.searchValue, filters.start, filters.length]);

  const isLoading = accountInformationStatus === 'loading';

  return (
    <PageContentContainer>
      {accountInformationError && (
        <Alert severity='error' variant='outlined'>
          <Typography variant='paragraph2' component='span' color='error'>
            {accountInformationError}
          </Typography>
        </Alert>
      )}
      <>
        {isLoading && <ComponentOverlayLoader />}
        {accountInformation && (
          <>
            <Box sx={{ mb: 10 }}>
              <Typography sx={{ mb: 2 }} variant='eyebrow'>
                ACCOUNT
              </Typography>
              <Typography variant='h2'>Manage Your Team</Typography>
            </Box>
            <AgencyInfo name={accountInformation?.accountName} id={accountInformation?.accountId} />
            <Divider sx={{ mt: 5, mb: 10 }} />
            <Toolbar onSearch={handleSearch} />
            <Box sx={{ mt: 10, mb: 5 }}>
              <Members members={accountInformation?.userAccountDetails || []} />
            </Box>
            <table style={{ width: '100%' }}>
              <SpectrumPagination
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleChangeItemsPerPage}
                itemsPerPage={filters.length}
                currentPage={filters.start / filters.length}
                totalItems={accountInformation?.totalRecords || 0}
                labelRowsPerPage='Members Per Page'
              />
            </table>
          </>
        )}
      </>
    </PageContentContainer>
  );
};

export default Account;
