import { AccordionSummary, Step, StepConnector, stepConnectorClasses, styled } from '@mui/material';

export const StyledStep = styled(Step)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  ':first-of-type': {
    display: 'none',
  },
}));

export const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[200],
    borderTopWidth: 4,
    borderRadius: 1,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  border: `${theme.spacing(0.5)} solid ${theme.palette.grey[400]}`,
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(360deg)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    minHeight: 'auto',
  },
}));
