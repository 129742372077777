import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { isUserAuthenticated } from '../../utils/authUtils';
import { logoutUser } from '../../redux/auth/AuthSlice';
import { StoreDispatch } from '../../redux/Store';
import { IDLE_TIMEOUT } from '../../constants/common';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';
import { Button, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

const LogoutUserOnInactivity = () => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const [dialogStatus, setDialogStatus] = useState(false);
  const dispatch: StoreDispatch = useDispatch();

  const onIdle = () => {
    const isUserLoggedIn = isUserAuthenticated();
    if (isUserLoggedIn) {
      dispatch(logoutUser());
      setDialogStatus(true);
    } else {
      activate();
    }
  };

  const { activate } = useIdleTimer({
    onIdle,
    timeout: IDLE_TIMEOUT,
  });

  const handleClose = () => {
    setDialogStatus(false);
  };

  return (
    <SpectrumDialog
      open={dialogStatus}
      onClose={handleClose}
      title={
        <Typography variant='h2' mb={5}>
          Session Time Out
        </Typography>
      }
    >
      <div>
        <Typography variant='body1' mb={10} color={grey[500]}>
          For your security, you have been logged out due to inactivity. Please log in again.
        </Typography>
        <Button fullWidth sx={{ mt: 10 }} onClick={handleClose} variant='contained' size='medium'>
          OK
        </Button>
      </div>
    </SpectrumDialog>
  );
};

export default LogoutUserOnInactivity;
