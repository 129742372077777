import { addSeconds, differenceInMilliseconds } from 'date-fns';
import { ITokenResponse } from '../redux/auth/AuthModels';
import { v4 as uuidv4 } from 'uuid';

export const constants = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  EXPIRY_TIME: 'expiryDateTime',
  SESSION_TOKEN: 'sessionToken',
  ID_TOKEN: 'idToken',
};

export const getAuthItem = (key: string): string => window.localStorage.getItem(key) || '';
export const setAuthItem = (key: string, value: any) => window.localStorage.setItem(key, value);
export const deleteAuthItem = (key: string) => window.localStorage.removeItem(key);

/** Save Access and Refresh Token */
export const saveToken = (data: ITokenResponse) => {
  setAuthItem(constants.ACCESS_TOKEN, data.access_token);
  setAuthItem(constants.ID_TOKEN, data.id_token);
  if (data.refresh_token) {
    setAuthItem(constants.REFRESH_TOKEN, data.refresh_token);
  }
  const expiryTime = addSeconds(new Date(), data.expires_in);
  setAuthItem(constants.EXPIRY_TIME, expiryTime);
};
/** Create session Token */
export const createSessionToken = () => {
  const sessionToken = uuidv4();
  setAuthItem(constants.SESSION_TOKEN, sessionToken);
};

/** Remove Tokens */
export const removeTokens = () => {
  deleteAuthItem(constants.ACCESS_TOKEN);
  deleteAuthItem(constants.REFRESH_TOKEN);
  deleteAuthItem(constants.EXPIRY_TIME);
  deleteAuthItem(constants.SESSION_TOKEN);
  deleteAuthItem(constants.ID_TOKEN);
};

/** check token expired or not * */
export const isTokenExpired = () => {
  const endDate = new Date(getAuthItem(constants.EXPIRY_TIME));
  const currentDate = new Date();
  return differenceInMilliseconds(endDate, currentDate) < 0;
};

/** Is user authenticated */
export const isUserAuthenticated = () => getAuthItem(constants.ACCESS_TOKEN) && !isTokenExpired();
