import { format } from 'date-fns';

export const getLabelForAvatar = (text: string) => {
  if (!text) {
    return '';
  }
  const matches: any = text.match(/\b(\w)/g) || [];
  return matches.join('');
};
export const getFirstChar = (text: string) => {
  if (!text) {
    return '';
  }
  return text.charAt(0)?.toUpperCase();
};

export const nth = (n: number) => ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';

export const formatAmount = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};
export const formatNumber = (value: number) => {
  return new Intl.NumberFormat('en-US').format(value);
};
export const formatPhoneNumber = (phoneNumber: string) => {
  const localNumber = phoneNumber.replace(/^\+[0-9]/, '');
  return localNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};
export const scrollToTop = () => window.scrollTo(0, 0);

export const formatDate = (date: string | Date, formatString = 'MMM dd, yyyy') =>
  format(typeof date === 'string' ? new Date(date) : date, formatString);

export const groupByKey = (array: Array<any>, key: string) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
  }, {});
};
export const sameDay = (d1: Date, d2: Date) => {
  return formatDate(d1) === formatDate(d2);
};
export const openLink = (link: string, target = '_blank') => {
  window.open(link, target);
};
export const formatErrors = (errors: Record<string, string[]>): string => {
  const flattenErrors = (errors: Record<string, any>, prefix = ''): string[] => {
    let errorMessages: string[] = [];
    for (const key in errors) {
      if (typeof errors[key] === 'object') {
        errorMessages = errorMessages.concat(flattenErrors(errors[key], `${prefix}${key}`));
      } else if (Array.isArray(errors[key])) {
        errorMessages.push(`${prefix}: ${errors[key].join(', ')}`);
      } else {
        errorMessages.push(`${prefix}: ${errors[key]}`);
      }
    }
    return errorMessages;
  };
  const errorMessages = flattenErrors(errors);
  return errorMessages.length > 0 ? `Errors:\n${errorMessages.join('\n')}` : 'No errors';
};
export const getUTCDate = () => {
  const now = new Date();
  const utcDay = now.getUTCDate();
  const utcMonthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const utcMonth = utcMonthNames[now.getUTCMonth()];
  const utcYear = now.getUTCFullYear();
  return `${utcMonth} ${utcDay}, ${utcYear}`;
};
export const getLocalDateWithOffset = () => {
  // Create a new Date object representing the current time
  const now = new Date();
  // Get the timezone offset in minutes
  const timezoneOffset = now.getTimezoneOffset();
  // Calculate the UTC time by subtracting the offset
  return new Date(now.getTime() + timezoneOffset * 60000);
};
