import { IPaidInvoicesAndOrderItem } from '../../redux/payment/PaymentModel';
import {
  IAdvertiserOrAgency,
  IOpenInvoice,
  IOpenOrder,
  ISelectedInvoiceOrOrderItem,
} from '../../redux/overview/OverviewModels';
import { ITransactionHistoryItem } from '../../redux/transaction/TransactionModel';

export const transformToInvoice = (
  invoicesAndOrder: IPaidInvoicesAndOrderItem | ITransactionHistoryItem,
): IOpenInvoice => {
  return {
    accountExecutive: invoicesAndOrder.accountExecutive,
    adjustmentAmount: invoicesAndOrder?.invoiceSummaryModel?.adjustedAmount || 0,
    afterAdjustmentAmount: invoicesAndOrder?.invoiceSummaryModel?.afterAdjustedAmount || 0,
    agencyDiscount: invoicesAndOrder?.invoiceSummaryModel?.agencyDiscount || 0,
    dueDate: invoicesAndOrder.dueDate,
    paymentDate: invoicesAndOrder.paymentDate,
    estimateNumber: invoicesAndOrder.estimateNumber,
    invoiceDate: invoicesAndOrder.generatedDate,
    invoiceNumber: invoicesAndOrder.number,
    invoiceOrders: [],
    isAdjusted: false,
    isManagedInvoice: false,
    isOpenInvoice: false,
    netAmount: invoicesAndOrder?.invoiceSummaryModel?.netAmount || 0,
    openAmount: invoicesAndOrder.dueAmount,
    taxAmount: invoicesAndOrder.taxAmount,
    orderNumber: invoicesAndOrder.number, //TODO check key - orderNumber will get key later
    originalAmount: invoicesAndOrder?.invoiceSummaryModel?.originalAmount || 0,
    payments: invoicesAndOrder?.invoiceSummaryModel?.payments || 0,
    promotionalDiscount: invoicesAndOrder?.invoiceSummaryModel?.promotionalDiscount || 0,
    repFirmDiscount: invoicesAndOrder?.invoiceSummaryModel?.repFirmDiscount || 0,
    totalInvoiceAmount: 0,
  };
};
export const transformToOrder = (
  invoiceAndOrder: IPaidInvoicesAndOrderItem | ITransactionHistoryItem,
): IOpenOrder => {
  return {
    accountExecutive: invoiceAndOrder.accountExecutive,
    dueDate: invoiceAndOrder.dueDate,
    paymentDate: invoiceAndOrder.paymentDate,
    estimateNumber: invoiceAndOrder.estimateNumber,
    note: '',
    orderAmount: invoiceAndOrder.dueAmount,
    taxAmount: invoiceAndOrder.taxAmount,
    orderDate: invoiceAndOrder.generatedDate,
    orderNumber: invoiceAndOrder.number,
  };
};
export const getDueInvoicesCount = (data: IAdvertiserOrAgency[]) => {
  return data.reduce((totalDue: number, advertiserOrAgency: IAdvertiserOrAgency) => {
    const currentDate = new Date();
    const advertiserDueCount = (advertiserOrAgency?.openInvoices || []).reduce(
      (dueCount: number, invoice: IOpenInvoice) => {
        const dueDate = new Date(invoice.dueDate);
        return currentDate > dueDate ? dueCount + 1 : dueCount;
      },
      0,
    );
    return advertiserDueCount + totalDue;
  }, 0);
};
export const getDueOrdersCount = (data: IAdvertiserOrAgency[]) => {
  return data.reduce((totalDue: number, advertiserOrAgency: IAdvertiserOrAgency) => {
    const currentDate = new Date();
    const advertiserDueCount = (advertiserOrAgency?.openOrders || []).reduce(
      (dueCount: number, invoice: IOpenOrder) => {
        const dueDate = new Date(invoice.dueDate);
        return currentDate > dueDate ? dueCount + 1 : dueCount;
      },
      0,
    );
    return advertiserDueCount + totalDue;
  }, 0);
};
export const transformToSelectedInvoiceItem = (
  invoices: IAdvertiserOrAgency[],
  isManaged = false,
) => {
  return (invoices || []).map((invoice) => {
    const selectedItem: ISelectedInvoiceOrOrderItem = {
      totalCreditAdjustments: invoice?.totalCreditAdjustments,
      advertiserOrAgencyId: invoice?.advertiserOrAgencyId,
      advertiserOrAgencyName: invoice?.advertiserOrAgencyName,
      totalCreditInvoices: invoice?.totalCreditInvoices,
      isManaged: isManaged,
      items: (invoice?.openInvoices || []).map((openInvoice) => ({
        number: openInvoice.invoiceNumber,
        amount: openInvoice.openAmount,
        dueDate: openInvoice.dueDate,
        date: openInvoice.invoiceDate,
        customAmount: openInvoice.openAmount,
        inProgress: openInvoice?.inProgress || false,
      })),
      totalCreditPayments: invoice?.totalCreditPayments,
      totalCreditPrepayments: invoice?.totalCreditPrepayments,
      totalCreditBalance: invoice?.totalCreditBalance,
    };
    return selectedItem;
  });
};
export const transformToSelectedOrderItem = (orders: IAdvertiserOrAgency[], isManaged = false) => {
  return (orders || []).map((order) => {
    const selectedItem: ISelectedInvoiceOrOrderItem = {
      totalCreditAdjustments: order?.totalCreditAdjustments,
      advertiserOrAgencyId: order?.advertiserOrAgencyId,
      advertiserOrAgencyName: order?.advertiserOrAgencyName,
      totalCreditInvoices: order?.totalCreditInvoices,
      isManaged: isManaged,
      items: (order?.openOrders || []).map((openOrder) => ({
        number: openOrder.orderNumber,
        amount: openOrder.orderAmount,
        dueDate: openOrder.dueDate,
        date: openOrder.orderDate,
        customAmount: openOrder.orderAmount,
        inProgress: openOrder?.inProgress || false,
      })),
      totalCreditPayments: order?.totalCreditPayments,
      totalCreditPrepayments: order?.totalCreditPrepayments,
      totalCreditBalance: order?.totalCreditBalance,
    };
    return selectedItem;
  });
};
