import React, { useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';
import { StyledContainer, StyledIframe } from './AddNewPaymentMethodStyles';
import {
  IframeMessage,
  receiveMessageFromIframe,
  sendMessageToIframe,
} from '../../../utils/iframeUtils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import {
  addNewBankAccountPayment,
  addNewCardPayment,
  fetchStoredPayments,
  resetAddPaymentStore,
} from '../../../redux/autoPay/AutoPaySlice';
import { appConstants } from '../../../constants/apiConstants';

const AddNewPaymentMethod = () => {
  const dispatch: StoreDispatch = useDispatch();
  const [dialogStatus, setDialogStatus] = React.useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const { addPaymentMethodStatus, addPaymentMethodError } = useSelector(
    (state: RootState) => state.autoPay,
  );

  const handleClickOpen = () => {
    setDialogStatus(true);
  };
  const handleClose = () => {
    setDialogStatus(false);
  };

  const handleMessage = (message: IframeMessage) => {
    if (message?.type === 'HEIGHT' && iframeRef.current) {
      iframeRef.current.style.height = (message.payload || 300) + 'px';
    }
    if (message?.type === 'CLOSE') {
      handleClose();
    }
    if (message?.type === 'ADD_PAYMENT_METHOD_CARD') {
      dispatch(addNewCardPayment(message?.payload));
    }
    if (message?.type === 'ADD_PAYMENT_METHOD_BANK_ACCOUNT') {
      dispatch(addNewBankAccountPayment(message?.payload));
    }
  };

  useEffect(() => {
    const unsubscribe = receiveMessageFromIframe(appConstants.paymentUIURL, handleMessage);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const message: IframeMessage = {
      type: 'IS_MOBILE_VIEW',
      payload: isMobile,
    };
    sendMessageToIframe(iframeRef.current, message);
  }, [isMobile]);

  useEffect(() => {
    const message: IframeMessage = {
      type: 'ADD_PAYMENT_METHOD_STATUS',
      payload: { addPaymentMethodStatus, addPaymentMethodError },
    };
    sendMessageToIframe(iframeRef.current, message);
    if (addPaymentMethodStatus === 'succeeded') {
      dispatch(fetchStoredPayments());
    }
  }, [addPaymentMethodStatus, addPaymentMethodError]);

  useEffect(() => {
    return () => {
      dispatch(resetAddPaymentStore());
    };
  }, []);

  return (
    <StyledContainer>
      <SpectrumDialog spacing={'0'} open={dialogStatus} onClose={handleClose}>
        <StyledIframe ref={iframeRef} src={appConstants.paymentUIURL} />
      </SpectrumDialog>
      <Button sx={{ justifyContent: 'flex-start' }} onClick={handleClickOpen}>
        <AddIcon />
        Add New Payment Method
      </Button>
    </StyledContainer>
  );
};
export default AddNewPaymentMethod;
