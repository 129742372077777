import React from 'react';
import { Divider, IconButton, MenuItem, Typography } from '@mui/material';
import Avatar from '../Avatar/Avatar';
import AvatarLink from '../AvatarLink/AvatarLink';
import { StyledContainer, StyledMenuPopover } from './UserMenuStyles';
import { getFirstChar } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { StoreDispatch } from '../../redux/Store';
import { logoutUser } from '../../redux/auth/AuthSlice';
import { PATHS } from '../../routes/Routes';
import { IUserDetails } from '../../redux/auth/AuthModels';

interface IUserMenuProps {
  user: IUserDetails | null;
}

const UserMenu = ({ user }: IUserMenuProps) => {
  const dispatch: StoreDispatch = useDispatch();
  const navigate = useNavigate();
  const userName = user ? getFirstChar(user?.tblUser?.firstName) + '' + getFirstChar(user?.tblUser?.lastName) : '';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(logoutUser());
    navigate(PATHS.LOGIN);
  };
  return (
    <React.Fragment>
      <StyledContainer>
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar size='medium'>{userName}</Avatar>
        </IconButton>
      </StyledContainer>
      <StyledMenuPopover
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <AvatarLink avatarLabel='OS' label='Onespot' />
        </MenuItem>
        <Divider />
        <MenuItem className='logout-link' onClick={handleLogout}>
          <Typography variant='subtitle' fontWeight={500}>
            Log out
          </Typography>
        </MenuItem>
      </StyledMenuPopover>
    </React.Fragment>
  );
};
export default UserMenu;
