import React, { SetStateAction } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { nth } from '../../../utils/common';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const PaymentInstructions = ({
  payOn,
  setPayOn,
  handlePrevious,
  handleNext,
}: {
  payOn: number;
  setPayOn: React.Dispatch<SetStateAction<number>>;
  handleNext: () => void;
  handlePrevious: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  return (
    <>
      <Box>
        <InputLabel htmlFor='month-date-input'>
          <Typography
            variant='h6'
            sx={{
              paddingBottom: theme.spacing(4),
            }}
            color={theme.palette.common.black}
          >
            Make Payments Each Month On The
          </Typography>
        </InputLabel>
        <TextField
          id='month-date-input'
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <EventOutlinedIcon />
              </InputAdornment>
            ),
          }}
          label=''
          type='number'
          value={payOn}
          fullWidth
          sx={{
            height: '48px',
          }}
          onChange={(event: any) => setPayOn(Number(event.target.value))}
        >
          {Array.from({ length: 28 }, (_, i) => i + 1).map((date) => (
            <MenuItem value={date} key={date}>
              {date}
              {nth(date)}
            </MenuItem>
          ))}
        </TextField>
        <FormHelperText
          id='month-date-input'
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '20px',
            mt: theme.spacing(4),
          }}
        >
          <InfoOutlinedIcon
            color='info'
            fontSize='medium'
            sx={{
              paddingRight: theme.spacing(4),
            }}
          />
          <Typography variant='subtitle'>
            Payments will only be charged if there is a balance.
          </Typography>
        </FormHelperText>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Button
          variant='outlined'
          size='large'
          startIcon={<ArrowBackOutlinedIcon />}
          onClick={handlePrevious}
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 12 : 16),
            margin: isMobile ? theme.spacing(0, 0, 10, 0) : theme.spacing(0),
          }}
        >
          Previous
        </Button>
        <Button
          variant='contained'
          size='large'
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 16 : 24),
          }}
          onClick={handleNext}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};

export default PaymentInstructions;
