import React, { ChangeEvent, useState } from 'react';
import CustomDateRangePicker from '../../components/DatePicker/DateRangePicker';
import SpectrumSearch from '../../components/Spectrum/Search/SpectrumSearch';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { IInvoiceOrOrderFilters } from '../../redux/overview/OverviewModels';

const InvoiceOrOrderFilters = ({
  filters,
  setFilters,
}: {
  filters: IInvoiceOrOrderFilters;
  setFilters: (filters: IInvoiceOrOrderFilters) => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const [filterValues, setFilterValues] = useState<IInvoiceOrOrderFilters>(filters);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value: any = event.target.value;
    if (name === 'date') {
      const [startDate, endDate] = value;
      setFilterValues({ ...filterValues, startDate, endDate });
      if ((startDate && endDate) || (!startDate && !endDate)) {
        setFilters({ ...filterValues, startDate, endDate });
      }
      return;
    }
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setFilters(filterValues);
    }
  };

  return (
    <Container
      sx={{
        mt: 16,
        mb: 16,
        ...(isMobile
          ? { display: 'flex', flexDirection: 'column', gap: '8px' }
          : {
              display: 'flex',
              justifyContent: 'space-between',
              gap: '8px',
            }),
      }}
    >
      <CustomDateRangePicker
        label=''
        name='date'
        value={[filterValues.startDate, filterValues.endDate]}
        onChange={handleChange}
      />
      <SpectrumSearch
        value={filterValues.searchValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        name='searchValue'
        placeholder='Search...'
        sx={{ width: '100%' }}
      />
    </Container>
  );
};
export default InvoiceOrOrderFilters;
