import React from 'react';
import { Box, Divider, Drawer, Link, Typography, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ILink } from '../../../types';
import { StyledIconButton, StyledLinksContainer } from './DrawerStyles';
import MenuIcon from '@mui/icons-material/Menu';
import { PATHS } from '../../../routes/Routes';
import { ColorPartial } from '@mui/material/styles/createPalette';

interface IDrawerProps {
  links: Array<ILink>;
}
const DrawerComponent = ({ links }: IDrawerProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const [status, setStatus] = React.useState<boolean>(false);

  const handleClick = (_event: React.MouseEvent<HTMLButtonElement>) => {
    setStatus(!status);
  };
  const handleClose = () => {
    setStatus(false);
  };
  const activeClassName = 'link link-active';
  return (
    <>
      <Drawer
        anchor='top'
        open={status}
        onClose={handleClose}
        PaperProps={{ style: { top: '76px' } }}
      >
        <StyledLinksContainer>
          <div className='links-container center'>
            {links.map((link, index) => {
              return (
                <>
                  {link?.children?.length ? (
                    <>
                      <Link key={index} className='link'>
                        {link?.label?.toLowerCase()}
                      </Link>
                      <Box pl={15} pb={15} display='flex' flexDirection='column'>
                        {(link.children || []).map((childLink, j) => {
                          return (
                            <NavLink
                              key={j}
                              className={({ isActive }) =>
                                isActive ? activeClassName + ' link child-link' : 'link child-link'
                              }
                              to={childLink.link}
                              onClick={handleClose}
                            >
                              {childLink?.label?.toLowerCase()}
                            </NavLink>
                          );
                        })}
                      </Box>
                    </>
                  ) : (
                    <NavLink
                      key={index}
                      className={({ isActive }) => (isActive ? activeClassName : 'link')}
                      to={link.link}
                      onClick={handleClose}
                    >
                      {link?.label?.toLowerCase()}
                    </NavLink>
                  )}
                  <Divider orientation='horizontal'></Divider>
                </>
              );
            })}
            <NavLink
              className={({ isActive }) => (isActive ? activeClassName : 'link')}
              to={PATHS.HELP}
              onClick={handleClose}
            >
              <Typography variant='body2' color={grey[500]}>
                Get Help
              </Typography>
            </NavLink>
          </div>
        </StyledLinksContainer>
      </Drawer>
      <StyledIconButton onClick={handleClick}>
        <MenuIcon />
      </StyledIconButton>
    </>
  );
};
export default DrawerComponent;
