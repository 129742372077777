import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import SpectrumSearch from '../../../components/Spectrum/Search/SpectrumSearch';
import { Box, Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import JumpToFilter from './JumpToFilter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { updateFilters } from '../../../redux/InvoicesAndOrders/InvoicesAndOrderSlice';

const InvoiceAndOrderSharingFilters = () => {
  const dispatch: StoreDispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');

  const { filters } = useSelector((state: RootState) => state.invoiceAndOrdersData);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    setSearchValue(value);
  };

  const handleFilterChange = (value: string) => {
    dispatch(updateFilters({ ...filters, jumpTo: value }));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13) {
      dispatch(updateFilters({ ...filters, searchValue }));
    }
  };

  const handleSort = () => {
    const updatedSortDirection = filters?.sortDirection === 'asc' ? 'desc' : 'asc';
    dispatch(updateFilters({ ...filters, sortDirection: updatedSortDirection }));
  };

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex' }}>
        <SpectrumSearch
          placeholder='Search...'
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          sx={{ flexGrow: 1, mr: 5 }}
        />
        <Button
          variant='outlined'
          size='medium'
          onClick={handleSort}
          sx={{
            minWidth: 0,
            padding: 6,
          }}
        >
          <SortIcon
            sx={{ transform: filters?.sortDirection === 'desc' ? 'rotate(180deg)' : 'rotate(0)' }}
          />
        </Button>
      </Box>
      <Box>
        <JumpToFilter value={filters?.jumpTo} onChange={handleFilterChange} />
      </Box>
    </Box>
  );
};

export default InvoiceAndOrderSharingFilters;
