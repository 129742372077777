import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledContainer = styled('div')(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  return {
    padding: theme.spacing(16),
    background: grey[50],
    '.side-heading': {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.common.black,
    },
    '.support-icon': {
      paddingRight: theme.spacing(3),
    },
    '.info-text': {
      color: grey[500],
      marginTop: theme.spacing(5),
    },
    '.right-item': {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('tablet')]: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    '.divider': {
      margin: theme.spacing(0, 8),
      [theme.breakpoints.down('tablet')]: {
        margin: theme.spacing(6, 0),
        width: '100%',
      },
    },
    '.links-container': {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});
