export interface IframeMessage {
  type: string;
  payload: any;
}
export const sendMessageToIframe = (iframe: HTMLIFrameElement | null, message: IframeMessage) => {
  iframe?.contentWindow?.postMessage(message, '*');
};
export const receiveMessageFromIframe = (
  allowedDomain: string,
  callback: (message: IframeMessage) => void,
) => {
  const handleMessage = (event: MessageEvent) => {
    if (event.origin === allowedDomain) {
      const message: IframeMessage = event.data;
      callback(message);
    } else {
      console.warn('Received message from unauthorized domain:', event.origin);
    }
  };
  window.addEventListener('message', handleMessage);
  return () => {
    window.removeEventListener('message', handleMessage);
  };
};
