import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { StyledButton } from './InvoiceAndOrderSharingStyles';

const characters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

interface IJumpToFilterProps {
  onChange: (value: string) => void;
  value: string;
}

const JumpToFilter = ({ value = '', onChange }: IJumpToFilterProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;

  const handleClick = (newValue: string) => {
    onChange(newValue === value ? '' : newValue);
  };

  return (
    <Box
      sx={{
        mt: 10,
        mb: 10,
        p: 5,
        backgroundColor: grey[50],
      }}
    >
      <Grid container alignItems='center'>
        <Grid item laptop={1}>
          <Typography variant='h6'>Jump To:</Typography>
        </Grid>
        <Grid item laptop={11}>
          <Box display='flex' alignItems='center' flexWrap='wrap'>
            {characters.map((char) => {
              return (
                <StyledButton
                  className={value === char ? 'selected' : ''}
                  key={char}
                  size='small'
                  onClick={() => handleClick(char)}
                >
                  {char}
                </StyledButton>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JumpToFilter;
