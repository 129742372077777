import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AutoPaymentsDialog from './AutoPaymentsDialog';
import PaymentDetail from './PaymentDetail';
import { IPayment } from '../../redux/payment/PaymentModel';
import PaymentInfo from './PaymentInfo';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { fetchPaymentHistory, fetchUpcomingPayments } from '../../redux/payment/PaymentSlice';
import { ClientTypes } from '../../redux/auth/AuthModels';
import {
  fetchAdvertiserInvoices,
  fetchAdvertiserOrders,
  fetchAgencyInvoices,
  fetchAgencyOrders,
  fetchManagedInvoices,
  fetchManagedOrders,
} from '../../redux/overview/OverviewSlice';
import { formatDate } from '../../utils/common';

const UpcomingPayments = ({ payments }: { payments: IPayment[] }) => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const { orderFilters, invoiceFilters } = useSelector((state: RootState) => state.overview);
  const { clientType } = useSelector((state: RootState) => state.authData);
  const isAdvertiser = clientType === ClientTypes.Advertiser;
  const { cancelledScheduledPaymentStatus, cancelledRecurringPaymentStatus } = useSelector(
    (state: RootState) => state.paymentData,
  );
  const { removeStoredPaymentStatus } = useSelector((state: RootState) => state.autoPay);
  const { filters: paymentHistoryFilters } = useSelector((state: RootState) => state.paymentData);
  const [showAll, setShowAll] = React.useState(false);
  const [showDetailedView, setShowDetailedView] = React.useState(false);
  const [selectedPaymentDetail, setSelectedPaymentDetail] = React.useState<IPayment | null>(null);
  const { upcomingPaymentStatus, upcomingPaymentsError, upcomingPayments } = useSelector(
    (state: RootState) => state.paymentData,
  );

  const handleClickOpen = () => {
    setShowAll(true);
  };

  const handleClose = () => {
    setShowAll(false);
  };

  const openDetailedView = (payment: IPayment) => {
    setSelectedPaymentDetail(payment);
    setShowDetailedView(true);
  };

  const closeDetailedView = () => {
    setShowDetailedView(false);
  };

  const renderUpcomingPaymentMethodStatus = () => {
    if (upcomingPaymentStatus === 'loading') {
      return <CircularProgress />;
    }

    if (upcomingPaymentStatus === 'failed') {
      return <ErrorPage title='Something went wrong' message={upcomingPaymentsError} />;
    }

    if (!upcomingPayments?.length) {
      return (
        <Typography color='info' my={theme.spacing(10)} variant='body2'>
          You don&apos;t have any upcoming scheduled payments
        </Typography>
      );
    }

    return null;
  };

  const updateOverview = () => {
    // Upcoming payments
    dispatch(fetchUpcomingPayments());
    //Open Orders
    if (clientType && clientType === ClientTypes.Agency) {
      dispatch(fetchAgencyOrders(orderFilters));
    }
    if (clientType && clientType === ClientTypes.Advertiser) {
      dispatch(fetchAdvertiserOrders(orderFilters));
    }
    //Open Invoices
    const _invoiceFilters = {
      ...invoiceFilters,
      ...(invoiceFilters?.startDate && {
        startDate: formatDate(invoiceFilters?.startDate || '', 'yyyy-MM-dd'),
      }),
      ...(invoiceFilters?.endDate && {
        endDate: formatDate(invoiceFilters?.endDate || '', 'yyyy-MM-dd'),
      }),
    };
    if (clientType && clientType === ClientTypes.Agency) {
      dispatch(fetchAgencyInvoices(_invoiceFilters));
    }
    if (clientType && clientType === ClientTypes.Advertiser) {
      dispatch(fetchAdvertiserInvoices(_invoiceFilters));
    }
    // Managed invoice and orders
    if (isAdvertiser) {
      dispatch(fetchManagedInvoices(_invoiceFilters));
      dispatch(fetchManagedOrders(orderFilters));
    }
    // Payment history
    dispatch(fetchPaymentHistory(paymentHistoryFilters));
  };

  useEffect(() => {
    if (
      cancelledScheduledPaymentStatus === 'succeeded' ||
      cancelledRecurringPaymentStatus === 'succeeded' ||
      removeStoredPaymentStatus === 'succeeded'
    ) {
      updateOverview();
    }
  }, [cancelledScheduledPaymentStatus, removeStoredPaymentStatus, cancelledRecurringPaymentStatus]);

  return (
    <>
      <Container>
        <Typography variant='h5' mb={theme.spacing(8)}>
          Upcoming Payments
        </Typography>
        {payments.slice(0, 3).map((payment: IPayment) => (
          <Card
            key={payment.paymentId}
            sx={{
              marginBottom: theme.spacing(2),
            }}
            onClick={() => openDetailedView(payment)}
          >
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: theme.spacing(4, 6),
                '&:last-child': {
                  paddingBottom: theme.spacing(6),
                },
              }}
            >
              <PaymentInfo payment={payment} />
              <Box style={{ cursor: 'pointer' }}>
                <NavigateNextIcon color='action' fontSize='small' />
              </Box>
            </CardContent>
          </Card>
        ))}
        {payments.length > 3 && (
          <Button
            onClick={handleClickOpen}
            variant='text'
            sx={{
              fontWeight: 500,
              padding: 0,
              textDecorationLine: 'underline',
              marginY: theme.spacing(4, 6),
            }}
          >
            View All
          </Button>
        )}
        {renderUpcomingPaymentMethodStatus() && (
          <Stack
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              minHeight: '120px',
            }}
          >
            {renderUpcomingPaymentMethodStatus()}
          </Stack>
        )}
      </Container>
      <AutoPaymentsDialog open={showAll} onClose={handleClose} payments={payments} />
      <PaymentDetail
        open={showDetailedView}
        onClose={closeDetailedView}
        selectedPaymentDetail={selectedPaymentDetail}
      />
    </>
  );
};

export default UpcomingPayments;
