import React, { useState } from 'react';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ClearIcon from '@mui/icons-material/Clear';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { Actions } from '../Members/MemberRoleMappings';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';
import TransferOwnership from './TransferOwnership';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { fetchUserAccountInformation } from '../../../redux/account/AccountSlice';
import {
  IGetUserAccountInfoPayload,
  IUserAccountDetail,
} from '../../../redux/account/AccountModels';
import { fetchUserDetails } from '../../../redux/auth/AuthSlice';
import RemoveUser from './RemoveUser';
import ChangeUserRole from './ChangeUserRole';
import ManageAccountAccess from './ManageAccountAccess';

interface IMemberActions {
  allowedActions: Actions[];
  member: IUserAccountDetail;
}

const MemberActions = ({ allowedActions, member }: IMemberActions) => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;

  const [dialogType, setDialogType] = useState('');
  const { filters } = useSelector((state: RootState) => state.accountData);

  const actionMapping: any = {
    [Actions.TRANSFER_OWNERSHIP]: {
      label: 'Transfer Ownership',
      icon: CompareArrowsIcon,
    },
    [Actions.MANAGE_ACCOUNT_ACCESS]: {
      label: 'Manage Account Access',
      icon: LockOutlinedIcon,
    },
    [Actions.CHANGE_USER_ROLE]: {
      label: 'Change User Role',
      icon: PersonOutlineOutlinedIcon,
    },
    [Actions.REMOVE_USER]: {
      label: 'Remove User',
      icon: ClearIcon,
    },
  };

  const handleClose = (result?: { type: Actions } | null) => {
    setDialogType('');
    if (!result) {
      return;
    }
    if (result?.type === Actions.TRANSFER_OWNERSHIP) {
      dispatch(fetchUserDetails());
    }
    dispatch(fetchUserAccountInformation(filters as IGetUserAccountInfoPayload));
  };

  return (
    <div>
      <Stack spacing={6}>
        {allowedActions.map((allowedAction, index) => {
          const action = actionMapping[allowedAction];
          const Icon = action.icon;
          return (
            <Button
              sx={{ justifyContent: 'left', textAlign: 'left' }}
              key={index}
              onClick={() => setDialogType(allowedAction)}
            >
              <Icon sx={{ color: grey[500], mr: 10 }} />
              <Typography variant='body2' color={grey[500]}>
                {action?.label}
              </Typography>
            </Button>
          );
        })}
      </Stack>
      <SpectrumDialog open={!!dialogType} onClose={() => handleClose()}>
        {dialogType === Actions.TRANSFER_OWNERSHIP && <TransferOwnership onClose={handleClose} />}
        {dialogType === Actions.REMOVE_USER && <RemoveUser member={member} onClose={handleClose} />}
        {dialogType === Actions.CHANGE_USER_ROLE && (
          <ChangeUserRole member={member} onClose={handleClose} />
        )}
        {dialogType === Actions.MANAGE_ACCOUNT_ACCESS && (
          <ManageAccountAccess member={member} onClose={handleClose} />
        )}
      </SpectrumDialog>
    </div>
  );
};
export default MemberActions;
