import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { Box, Button, CircularProgress, Divider, Typography, useTheme } from '@mui/material';

import { nth } from '../../../utils/common';

import { IPaymentMethod } from '../../../redux/autoPay/AutoPayModel';
import { enableAutoPay } from '../../../redux/autoPay/AutoPaySlice';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { ColorPartial } from '@mui/material/styles/createPalette';
import ReviewPaymentMethod from '../../../components/ReviewPaymentMethod/ReviewPaymentMethod';

const ReviewPaymentDate = ({
  date,
  moveToStepLabel,
  onMoveToStep,
}: {
  date: any;
  moveToStepLabel: string;
  onMoveToStep: (step: number) => void;
}) => {
  return (
    <>
      <Typography variant='h4'>Payment Instructions</Typography>
      <Typography variant='h6' mt={8}>
        Make Payments on the:
      </Typography>
      <Typography variant='body1' mt={3}>
        {date}
        {nth(date)} of each month
      </Typography>
      <Button
        variant='text'
        sx={{
          mt: 6,
          textDecoration: 'underline',
        }}
        onClick={() => onMoveToStep(2)}
      >
        {moveToStepLabel}
      </Button>
    </>
  );
};

const ReviewDetails = ({
  payOn,
  selectedPaymentMethodDetail,
  setActiveStep,
}: {
  payOn: any;
  selectedPaymentMethodDetail?: IPaymentMethod;
  setActiveStep: Dispatch<SetStateAction<number>>;
}) => {
  const theme = useTheme();
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const dispatch: StoreDispatch = useDispatch();
  const { autoPayConfirmationStatus } = useSelector((state: RootState) => state.autoPay);

  const schedulePayment = () => {
    dispatch(
      enableAutoPay({
        dayOfMonth: payOn,
        spmId: selectedPaymentMethodDetail?.spmId,
      }),
    );
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 8 }}>
        <Typography variant='h4'>Your Payment:</Typography>
        <Typography variant='body1' mt={8}>
          Full Account Balance
        </Typography>
      </Box>
      <Divider />
      <Box>
        <ReviewPaymentDate
          date={payOn}
          onMoveToStep={setActiveStep}
          moveToStepLabel='Change Payment Instructions'
        />
      </Box>
      <Divider />
      <Box>
        <ReviewPaymentMethod
          selectedPaymentMethodDetail={selectedPaymentMethodDetail}
          moveToStepLabel='Change Payment Method'
          onMoveToStep={setActiveStep}
        />
      </Box>
      <Button
        variant='contained'
        size='large'
        sx={{
          width: 'fit-content',
          padding: theme.spacing(9, 16),
        }}
        onClick={schedulePayment}
      >
        {autoPayConfirmationStatus === 'loading' ? (
          <>
            <CircularProgress
              sx={{
                color: white[500],
                width: '30px !important',
                height: '30px !important',
                mr: 10,
              }}
            />{' '}
            Schedule Payments
          </>
        ) : (
          'Schedule Payments'
        )}
      </Button>
    </Fragment>
  );
};

export default ReviewDetails;
