import React, { useEffect } from 'react';
import {
  StyledContainer,
  StyledLoginCard,
  StyledLoginWrapper,
  StyledNoticeContainer,
} from './LoginStyles';
import { Box, Button, CircularProgress, Divider, Link, Typography } from '@mui/material';
import { authApiEndPoints } from '../../constants/apiConstants';
import { RootState, StoreDispatch } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTokenUsingAuthCode } from '../../redux/auth/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../routes/Routes';
import { isUserAuthenticated } from '../../utils/authUtils';

const Login = () => {
  const dispatch: StoreDispatch = useDispatch();
  const navigate = useNavigate();
  const { accessTokenStatus, error } = useSelector((state: RootState) => state.authData);

  const handleLogin = () => {
    window.location.href = authApiEndPoints.loginURL;
  };
  const handleCreateAccount = () => {
    window.location.href = authApiEndPoints.createAccountURL;
  };
  const handleGCILogin = () => {
    window.location.href = authApiEndPoints.gciLoginURL;
  };
  const handleResetPassword = () => {
    window.location.href = authApiEndPoints.forgotPasswordURL;
  };
  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get('code');

  useEffect(() => {
    if (isUserAuthenticated()) {
      navigate(PATHS.OVERVIEW);
    }
  }, []);

  useEffect(() => {
    if (authCode) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchTokenUsingAuthCode(authCode));
    }
  }, [authCode]);

  useEffect(() => {
    if (accessTokenStatus && accessTokenStatus === 'succeeded') {
      navigate(PATHS.OVERVIEW);
    }
  }, [accessTokenStatus]);

  const isLoading = accessTokenStatus === 'loading';
  return (
    <StyledContainer>
      <StyledLoginWrapper>
        <StyledLoginCard>
          <Typography variant='eyebrow'>Login</Typography>
          <Typography variant='h1' mt={3} mb={3}>
            Client Portal
          </Typography>
          <StyledNoticeContainer>
            <Typography variant='body2' fontWeight='600'>
              Login Notice*
            </Typography>
            <Typography variant='body2' lineHeight={1.6}>
              The Client Portal has been updated. If you have an already existing account, please{' '}
              <Typography
                className='anchor-inline-link'
                onClick={handleResetPassword}
                component={Link}
                variant='paragraph2'
              >
                reset your password
              </Typography>{' '}
              to log in.
            </Typography>
          </StyledNoticeContainer>
          <Typography variant='paragraph2'>
            Manage your invoices and orders, payments, account, and more.
          </Typography>
          <Button size='large' variant='contained' className='login-btn' onClick={handleLogin}>
            {isLoading ? <CircularProgress className='loader' /> : 'Sign In'}
          </Button>
          {error && (
            <Typography mt={2} mb={10} variant='paragraph2' color='error'>
              {error}
            </Typography>
          )}
          <Box mt={5} mb={2}>
            <Divider className='divider' />
          </Box>
          <Typography variant='paragraph2'>
            or
            <Typography
              className='anchor-link'
              component={Link}
              onClick={handleCreateAccount}
              variant='paragraph2'
            >
              Create a New Account
            </Typography>
          </Typography>
        </StyledLoginCard>
      </StyledLoginWrapper>
    </StyledContainer>
  );
};
export default Login;
